import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { Icon } from '@/components/Sprite/Icon';
import { cn } from '@/utils';

import { QuestionMarkTooltip } from '../QuestionMarkTooltip';

interface IDepositProductCardItemProps {
  heading: string;
  value: string;
  isAdvantage?: boolean;
  isDisadvantage?: boolean;
  isMarked?: boolean;
  tooltip?: ReactNode;
  isUppercase?: boolean;
  tooltipStyles?: string;
  textStyles?: string;
  valueStyles?: string;
}

export const DepositProductCardItem: FC<IDepositProductCardItemProps> = ({
  isAdvantage = false,
  isDisadvantage = false,
  isMarked = false,
  tooltip = '',
  isUppercase = false,
  heading,
  value,
  tooltipStyles,
  textStyles,
  valueStyles,
}) => {
  const { t } = useTranslation();

  const MarkIcon = ({
    icon,
  }: {
    icon: 'Advantage' | 'Disadvantage' | 'DisadvantageGray';
  }) => {
    switch (icon) {
      case 'Advantage':
        return (
          <Icon
            name="greenTik"
            className="block h-4 w-4 text-transparent md:mr-2 md:h-5 md:w-5"
          />
        );
      case 'Disadvantage':
        return (
          <Icon
            name="close"
            className="block h-4 w-4 text-transparent md:mr-2 md:h-5 md:w-5"
          />
        );
      case 'DisadvantageGray':
        return (
          <Icon
            name="closeIcon"
            className="block h-4 w-4 text-transparent md:mr-2 md:h-5 md:w-5"
          />
        );
      default:
        return null;
    }
  };

  return (
    <li className="flex flex-col gap-[4px] py-3 md:flex-row md:content-center md:items-center md:gap-4 lg:py-4">
      <div className="flex items-center tracking-[0.1px] md:min-w-[326px] xl:min-w-[384px]">
        <p
          className={cn(
            'body3 md:body1 text-grey-300 md:text-grey-800',
            textStyles,
          )}
        >
          {t(heading)}
        </p>
        {tooltip && (
          <QuestionMarkTooltip content={tooltip} className={tooltipStyles} />
        )}
      </div>

      <div
        className={cn(
          'flex items-center gap-2 md:gap-1',
          !isMarked && 'md:ml-[30px]',
        )}
      >
        {isMarked && (
          <span>
            {(isAdvantage && <MarkIcon icon="Advantage" />) ||
              (isDisadvantage && <MarkIcon icon="DisadvantageGray" />)}
          </span>
        )}

        <span
          className={cn(
            'text-[17px] font-medium font-inter leading-[20px] w-full tracking-[0.1px] text-blue-900',
            isUppercase ? 'uppercase' : 'first-letter:capitalize',
            textStyles,
            valueStyles,
          )}
        >
          {value}
        </span>
      </div>
    </li>
  );
};
