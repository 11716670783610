/* eslint-disable react/no-unused-prop-types */
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { type FC } from 'react';

import { useSearchStore } from '@/store';
import { COUNTRY_FLAGS } from '@/store/storeSearch.consts';
import { getTerm } from '@/store/storeSearch.utils';
import { cn } from '@/utils';

import { MobileModals } from '../MobileModal';
import { Icon } from '../Sprite';
import { DescriptionOfModal, SearchButton } from './parts';

interface IModalSearchProps {
  className?: string;
  onSubmit: () => void;
}

export const SearchMobile: FC<IModalSearchProps> = ({
  className,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const { country, currency, terms } = useSearchStore((state) => state);
  const activeTerms = terms.filter((term) => term.selected);

  return (
    <MobileModals
      title={`${t('search.search')}`}
      trigger={
        <div className="w-full bg-white px-5 sm:px-[34px]">
          <button
            type="button"
            className={cn(
              'w-full rounded-full border-[1px] hover:bg-gray-100 cursor-pointer flex items-center justify-between gap-1 pr-[10px]',
              className,
            )}
          >
            <div className="search-input min-w-0 grow-[1] gap-1  border-0 pl-[26px] pr-[12px] pt-[10px]">
              <div className="body2-bold flex w-full items-center justify-start gap-2 rounded-md font-bold text-black">
                <Image
                  src={COUNTRY_FLAGS[country]!}
                  alt={country}
                  className="h-auto w-5 rounded-sm"
                  width="20"
                  height="15"
                  unoptimized
                />
                <p className="truncate text-[15px]">
                  {t(`database.countries.${country}`)}
                </p>
              </div>
              <p className="caption max-w-[100%] truncate text-left leading-[15px] tracking-[0.2px]">
                {currency.toUpperCase()},&nbsp;
                {activeTerms.map((term, index) => (
                  <span className="capitalize" key={term.key}>
                    {getTerm(term.key, t)}
                    {index < activeTerms.length - 1 ? ', ' : ''}
                  </span>
                ))}
                {activeTerms.length > 0 && ', '}
                {activeTerms.length === 0 && (
                  <span className="capitalize">
                    {t(`database.deposit type.all`)}
                  </span>
                )}
                {/* {is_partner && ( */}
                {/*  <span className="capitalize"> */}
                {/*    {t(`database.deposit type.${IS_PARTNER_KEY}`)},&nbsp; */}
                {/*  </span> */}
                {/* )} */}
                {/* {activeTypes.map((operation, index) => ( */}
                {/*  <span className="capitalize" key={operation.key}> */}
                {/*    {t(`database.deposit type.${operation.key?.toLowerCase()}`)} */}
                {/*    {index < activeTypes.length - 1 ? ', ' : ''} */}
                {/*  </span> */}
                {/* ))} */}
                {/* {activeTypes.length === 0 && ( */}
                {/*  <span className="capitalize"> */}
                {/*    {t(`database.deposit type.all`)} */}
                {/*  </span> */}
                {/* )} */}
              </p>
            </div>
            <div className="flex size-[42px] shrink-0 items-center justify-center rounded-full bg-blue-550">
              <Icon
                className="stroke-2 text-transparent"
                name="SearchWhiteIcon"
              />
            </div>
          </button>
        </div>
      }
      isButtonSearch={
        <div className="inset-x-0 bottom-0 mx-6 mb-[4px] mt-auto">
          <SearchButton
            onClick={onSubmit}
            className="z-50 self-center md:hidden"
          />
        </div>
      }
    >
      <DescriptionOfModal
        otherStyleFormDescription
        buttonChange={false}
        onSubmit={() => {}}
      />
    </MobileModals>
  );
};
