import type { ChangeEvent } from 'react';
import React from 'react';

import { cn } from '@/utils';

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative">
      <input
        type="radio"
        id={value}
        value={value}
        checked={checked}
        onChange={handleOnChange}
        className="hidden"
      />
      <label htmlFor={value} className="relative flex cursor-pointer text-left">
        <span
          className={cn(
            'shrink-0 left-0 h-5 relative w-5 rounded-full border-2',
            checked
              ? 'bg-blue-500 border-blue-500 after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:h-2 after:w-2 after:rounded-full after:bg-white'
              : 'bg-white border-grey-200',
          )}
        />
        <span className="body2 ml-2 tracking-[0.2px] text-grey-800">
          {label}
        </span>
      </label>
    </div>
  );
};

export default RadioButton;
