import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

import { useHeader } from '@/hooks/useHeader';
import useUserInformation from '@/hooks/useUserInformation';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';
import { isMobileApp } from '@/utils/isMobileApp';
import { MOBILE_BOTTOM_BAR_ROUTES } from '@/utils/mobileBottomBarRoutes';

import { ChangeLanguage } from '../ChangeLanguage';
import { HamburgerMenu } from '../Menu';
import { Icon } from '../Sprite';

type IHeaderProps = {
  isScrolledDown: boolean;
  isScrolled: boolean;
  isMinified?: boolean;
  shouldHeaderBeBlue?: boolean;
  isHeaderSticky?: boolean;
  mobileTitle?: string;
};

export const Header: FC<IHeaderProps> = ({
  isScrolledDown,
  isScrolled,
  isMinified,
  shouldHeaderBeBlue,
  isHeaderSticky = true,
  mobileTitle,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const { links } = useHeader();

  const { openLoginModal, openSignupModal, setIsDefaultText } =
    useAuthModalsControl((state) => state);
  const { token, setToken, isTestUser, reset } = useAuthStore((state) => state);
  const { showedUsername, isVerified } = useUserInformation();

  useEffect(() => {
    setLoading(true);
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    setLoading(false);
  }, [token]);

  const handleLogout = () => {
    reset();
    setToken('');
    setIsAuth(false);
    if (router.pathname.includes('/profile')) {
      router.replace('/');
    }
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickLogout,
      label: 'clickLogout',
    });
  };

  const handleLogIn = () => {
    openLoginModal();
    setIsDefaultText(true);

    analyticServices.sendEvent({
      action: AnalyticsEvent.clickLogin,
      label: 'clickLogin',
    });
  };

  const handleSignUp = () => {
    openSignupModal();
    setIsDefaultText(true);

    analyticServices.sendEvent({
      action: AnalyticsEvent.clickSignUp,
      label: 'clickSignUp',
    });
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      if (window.history?.length > 1) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Initial check on mount
    handleRouteChange();

    // Cleanup on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <header
      className={cn(
        'fixed header-safe-area top-0 bg-white z-50 max-h-[60px] transition-all ease-in-out min-h-[60px] w-full lg:max-h-[80px] lg:min-h-[80px] duration-300',
        !shouldHeaderBeBlue || isScrolled ? 'bg-white' : 'bg-blue-100',
        isScrolledDown &&
          '-top-[calc(env(safe-area-inset-top)+60px)] lg:top-[-80px]',
        !isHeaderSticky && 'lg:absolute',
        isMobileApp() && 'min-h-[50px] max-h-[50px]',
      )}
    >
      {shouldHeaderBeBlue}
      <nav
        className={cn(
          'absolute flex size-full content-center items-center justify-between px-5 lg:justify-between lg:px-16',
          !shouldHeaderBeBlue || isScrolled ? 'bg-white' : 'bg-blue-100',
        )}
      >
        {isMobileApp() &&
          showButton &&
          !MOBILE_BOTTOM_BAR_ROUTES.includes(router.pathname) && (
            <button
              type="button"
              onClick={() => {
                router.back();
              }}
              className="absolute top-1/2 text-blue-900"
            >
              <Icon
                name="chevron"
                className="size-8 -translate-y-1/2 -rotate-90 stroke-blue-600"
              />
            </button>
          )}
        <HamburgerMenu
          onLogout={handleLogout}
          onLogin={handleLogIn}
          onSignUp={handleSignUp}
          isAutenficated={isAuth}
          isHamburgerVisible={!isMobileApp()}
        />
        <div className="flex content-center items-center justify-center lg:justify-between lg:gap-x-10">
          {isMobileApp() && mobileTitle ? (
            <p className="body1-bold ml-8 text-blue-900">{mobileTitle}</p>
          ) : (
            <Link
              href={{
                pathname: isMobileApp()
                  ? token
                    ? '/multibanking-platform'
                    : '/mobile-app'
                  : '/',
                query: isTestUser ? { test_user: isTestUser } : undefined,
              }}
              aria-label="Home"
            >
              <Icon
                className="h-[32px] w-[177px] text-[20px] font-bold text-transparent"
                name="LogoHeader"
              />
            </Link>
          )}
          {!isMinified && (
            <div className="ml-0 hidden lg:ml-4 lg:flex lg:gap-x-6">
              {links.map((link) => (
                <Link
                  href={{
                    pathname: link.path,
                    query: isTestUser ? { test_user: isTestUser } : undefined,
                  }}
                  key={link.path}
                  className={cn(
                    'header-title body2 tracking-[0.2px]',
                    router.pathname === link.path && 'font-bold text-blue-900',
                  )}
                >
                  {t(link.title)}
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className="flex size-8 lg:hidden" />
        <div className="relative hidden items-center lg:flex">
          <ChangeLanguage />
          <Popup
            trigger={
              <button type="button" id="profilebtn">
                <Icon name="AvatarLogo" className="size-9 text-transparent" />
              </button>
            }
            position="bottom right"
            arrow={false}
            contentStyle={{
              padding: 0,
              width: '256px',
              borderRadius: '12px',
              right: 100,
              top: 80,
              left: 'unset',
            }}
            on={['hover', 'focus']}
          >
            <div
              className={cn(
                'bg-white shadow-far p-1 text-grey-800 rounded-xl',
                loading ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
            >
              {token && isTestUser && (
                <>
                  <Link href="/profile/portfolio">
                    <div className="flex items-center justify-between px-4 py-3">
                      <div className="flex shrink items-center break-all">
                        <Icon
                          name="AvatarLogo"
                          className="size-10 text-transparent"
                        />
                        <div className="ml-2">
                          <p className="body2-bold tracking-[0.2px]">
                            {showedUsername}
                          </p>
                          {isVerified ? (
                            <p className="body3 tracking-[0.2px] text-grey-800">
                              {t('profile.verified_account')}
                            </p>
                          ) : (
                            <p className="body3 tracking-[0.2px] text-red-500">
                              {t('profile.account_not_verified')}
                            </p>
                          )}
                        </div>
                      </div>
                      <Icon
                        name="chevron"
                        className="size-5 shrink rotate-90 text-grey-600"
                      />
                    </div>
                  </Link>
                  <Link
                    href="/profile/portfolio"
                    className="body2 block px-4 py-3 tracking-[0.2px]"
                  >
                    {t('header.portfolio')}
                  </Link>
                </>
              )}
              <Link
                href="/favourites"
                className="body2 block px-4 py-3 tracking-[0.2px]"
              >
                {t('header.favorites')}
              </Link>
              {token && isTestUser && (
                <>
                  <hr className="my-2" />
                  <Link
                    href="/profile"
                    className="body2 block px-4 py-3 tracking-[0.2px]"
                  >
                    {t('header.settings')}
                  </Link>
                </>
              )}
              <hr className="my-2" />
              {token ? (
                <button
                  type="button"
                  className="body2 block px-4 py-3 tracking-[0.2px] text-red-500"
                  onClick={handleLogout}
                >
                  {t('header.logout')}
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="body2 block px-4 py-3 tracking-[0.2px]"
                    onClick={handleLogIn}
                  >
                    {t('header.login')}
                  </button>
                  <button
                    type="button"
                    className="body2 block px-4 py-3 tracking-[0.2px]"
                    onClick={handleSignUp}
                  >
                    {t('header.register')}
                  </button>
                </>
              )}
            </div>
          </Popup>
        </div>
      </nav>
    </header>
  );
};
