import 'reactjs-popup/dist/index.css';

import Image from 'next/image';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';

import { useApi } from '@/contexts/ApiContext';
import { useCmsContent } from '@/contexts/CmsContentContext';
import Flag_ENG from '@/public/assets/countries/ENG.svg';
import { useAuthStore } from '@/store';
import type { ILocalization } from '@/store/storeI18n';
import { useI18nStore } from '@/store/storeI18n';
import { cn } from '@/utils';

import { Icon } from '../Sprite';

interface ChangeLanguageProps {
  isFooter?: boolean;
  isMinified?: boolean;
}

export function ChangeLanguage({
  isFooter = false,
  isMinified = false,
}: ChangeLanguageProps) {
  const router = useRouter();
  const { token, isTestUser } = useAuthStore((state) => state);
  const { putProfile } = useApi();
  const localizations = useI18nStore((state) => state.localizations);

  const { preparedLocalizations } = useCmsContent();

  const handleSetLanguage = async (locale: string) => {
    if (!token) return;
    try {
      await putProfile({ language: locale.toUpperCase() });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="mr-6 rounded-2xl md:flex">
      <button
        id="dsfsd"
        type="button"
        className="group relative flex content-center items-center gap-2"
      >
        <p className="body2 flex text-grey-800 hover:text-blue-900">
          {router.locale ? localizations[router.locale]!.shortName : 'En'}
        </p>
        <p>
          <Image
            src={
              router.locale ? localizations[router.locale]!.flagImg : Flag_ENG
            }
            alt="language"
            className="max-h-[15px] min-h-[15px] min-w-[20px] max-w-[20px] rounded-sm"
            unoptimized
          />
        </p>
        <Icon
          name="shewronDown"
          className="size-5 stroke-2 text-transparent transition-all group-hover:rotate-180"
        />
        <div
          className={cn(
            'absolute',
            isFooter && !isMinified
              ? 'bottom-0 left-0 xl:top-0 xl:right-0 xl:bottom-[unset] xl:left-[unset]'
              : 'top-0 right-0',
            isMinified &&
              'bottom-0 left-0 right-[unset] top-[unset] md:left-[unset] md:right-0 md:top-[unset]',
          )}
        >
          <div
            className={cn(
              'shadow-far hidden w-[192px] rounded-xl bg-white p-1 group-hover:block mb-[0]',
              isFooter && !isMinified
                ? 'mb-[40px] xl:mt-[40px] xl:mb-0'
                : 'mt-[40px]',
              isMinified && 'mb-[40px]',
            )}
          >
            {Object.entries(localizations).map(
              ([key, value]: [string, ILocalization]) => (
                <Link
                  key={`change_language_${key}`}
                  href={{
                    pathname:
                      value.link !== '/' && !value.notSupported
                        ? value.link
                        : preparedLocalizations[key]?.href || '/',
                    query: isTestUser ? { test_user: isTestUser } : undefined,
                  }}
                  locale={value.locale}
                  shallow={false}
                  suppressHydrationWarning
                  className={cn(
                    'flex w-full items-center gap-4 px-6 py-3 text-blue-900 justify-between',
                    (preparedLocalizations[key]?.href === '/' ||
                      !preparedLocalizations[key]?.href) &&
                      value.notSupported &&
                      'opacity-50 hover:opacity-100',
                    router.locale === key && 'pointer-events-none',
                  )}
                  onClick={() => {
                    handleSetLanguage(value.locale);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <Image
                      src={value.flagImg}
                      alt="language"
                      className="max-h-[15px] min-h-[15px] min-w-[20px] max-w-[20px] rounded-sm"
                      unoptimized
                    />
                    <p
                      className={cn(
                        'body2 hover:text-blue-900',
                        router.locale === key && 'body2-bold text-blue-900',
                      )}
                    >
                      {value.title}
                    </p>
                  </div>
                  {router.locale === key && (
                    <Icon name="Tik" className="size-5 text-transparent" />
                  )}
                </Link>
              ),
            )}
          </div>
        </div>
      </button>
    </div>
  );
}
