import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { Icon } from '@/components/Sprite';
import { cn } from '@/utils';

export interface IResetButtonProps {
  className?: string;
  onReset: () => void;
}

export const ResetButton: FC<IResetButtonProps> = ({ className, onReset }) => {
  const { t } = useTranslation();

  return (
    <button
      className={cn(
        'body3 flex items-center leading-[18px] transition-all',
        className,
      )}
      onClick={onReset}
      type="button"
    >
      <span>{t('deposits.reset_all_filters')}</span>
      <Icon className="h-4 w-4 text-transparent" name="closeIcon" />
    </button>
  );
};
