/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { TFunction } from 'i18next';
import type { ParsedUrlQuery } from 'querystring';

// import { TermKey, termKeys } from '@/pages/fixed-deposits/utils';
import type { IFetchSupabaseDepositsPayload } from '@/services/supabase';
import type { ISearchControlsState } from '@/store/storeSearch.types';
import { capitalizeFirstLetter } from '@/utils';
import { findAnnualFeeKey } from '@/utils/annualFees';
import { findCashbackKey } from '@/utils/cashback';
import { findCountryKey } from '@/utils/countries';
import { parseTermKeysFromUrl } from '@/utils/termKeys';

import {
  ANNUAL_FEE_OPTIONS,
  CASHBACK_OPTIONS,
  DEPOSIT_TERMS,
  DEPOSIT_TYPE_OPTIONS,
} from './storeSearch.consts';

export const prepareSearchStoreToQuery = (
  state: Partial<ISearchControlsState>,
) => {
  const { country, currency, terms, is_partner, types, annualFee, cashback } =
    state;
  const query: ParsedUrlQuery = {};

  if (country) {
    query.country = String(country);
  }

  if (currency) {
    query.currency = String(currency);
  }

  if (annualFee?.value) {
    query['annual-fee'] = String(annualFee.value);
  }

  if (cashback?.value) {
    query.cashback = String(cashback.value);
  }

  if (terms && terms?.length > 0) {
    const filteredTerms = terms
      .filter((op) => op.selected)
      .map((op) => op.value);

    if (filteredTerms.length > 0) {
      query.terms = String(filteredTerms);
    }
  }

  if (is_partner === false) {
    query.is_partner = 'false';
  }

  if (types && types?.length > 0) {
    const filtered = types.filter((op) => op.selected).map((op) => op.key);

    if (filtered.length > 0) {
      query.type = String(filtered);
    }
  }

  return query;
};

export const prepareSearchStoreToPayload = (state: ISearchControlsState) => {
  const { country, currency, terms, is_partner, types } = state;

  const payload: Partial<IFetchSupabaseDepositsPayload> = {};

  if (country) {
    payload.country = country;
  }

  if (currency) {
    payload.currency = currency;
  }

  if (terms && terms?.length > 0) {
    payload.terms = terms.filter((t) => t.selected).map((t) => t.value);
  }

  if (is_partner) {
    payload.is_partner = is_partner;
  }

  if (types && types?.length > 0) {
    payload.type = types.filter((t) => t.selected).map((t) => t.key);
  }

  return payload;
};

export const prepareSearchQueryToPayload = (
  query: ParsedUrlQuery,
  language = 'en',
) => {
  const { country, currency, terms, is_partner, type, cashback } = query;

  const payload: Partial<IFetchSupabaseDepositsPayload> = {
    language: language!,
  };

  if (country && typeof country === 'string') {
    payload.country = country;
  }

  if (currency && typeof currency === 'string') {
    payload.currency = currency;
  }

  if (is_partner && is_partner === 'false') {
    payload.is_partner = false;
  }

  if (cashback && typeof cashback === 'string') {
    payload.cashback = cashback;
  }

  if (query['annual-fee'] && typeof query['annual-fee'] === 'string') {
    payload['annual-fee'] = query['annual-fee'];
  }

  if (terms) {
    const termValues = ((terms as string)?.split(',') || []).map(Number);
    const filtered = DEPOSIT_TERMS.filter((term) =>
      termValues.includes(term.value),
    ).map((term) => String(term.value));

    if (filtered?.length > 0) {
      // TODO: fix type request expect string, and we got number
      payload.terms = filtered as any;
    }
  }

  if (type) {
    const keys = (type as string)?.split(',') || [];
    const filtered = DEPOSIT_TYPE_OPTIONS.filter((operation) =>
      keys.includes(operation.key),
    ).map((operation) => operation.key);

    if (filtered?.length > 0) {
      payload.type = filtered;
    }
  }

  if (!type) {
    payload.type = ['ordinary deposit'];
  }

  return payload;
};

export const prepareSearchQueryToStore = (
  query: ParsedUrlQuery,
  language = 'en',
) => {
  const { country, currency, terms, is_partner, type, cashback } = query;

  const payload: Partial<ISearchControlsState & { language: string }> = {
    language,
  };

  if (country && typeof country === 'string') {
    payload.country = country;
  }

  if (currency && typeof currency === 'string') {
    payload.currency = currency;
  }

  if (is_partner && is_partner === 'false') {
    payload.is_partner = false;
  }

  if (cashback && typeof cashback === 'string') {
    payload.cashback = CASHBACK_OPTIONS.find(
      (option) => option.key === cashback,
    );
  }

  if (query['annual-fee'] && typeof query['annual-fee'] === 'string') {
    payload.annualFee = ANNUAL_FEE_OPTIONS.find(
      (option) => option.key === query['annual-fee'],
    );
  }

  const termValues = ((terms as string)?.split(',') || []).map(Number);
  payload.terms = DEPOSIT_TERMS.map((term) => ({
    ...term,
    selected: termValues.includes(term.value),
  }));

  const keys = (type as string)?.split(',') || [];
  payload.types = DEPOSIT_TYPE_OPTIONS.map((operation) => ({
    ...operation,
    selected:
      keys.length > 0
        ? keys.includes(operation.key)
        : operation.key === 'ordinary deposit',
  }));

  return payload;
};

export const prepareSearchUrlToStore = (
  filtersParams: any,
  language = 'en',
) => {
  const { country, currency, term, annualFee, cashback } = filtersParams;

  const payload: Partial<ISearchControlsState & { language: string }> = {
    language,
  };

  if (country && typeof country === 'string') {
    payload.country = capitalizeFirstLetter(findCountryKey(country));
  }

  if (currency && typeof currency === 'string') {
    payload.currency = currency;
  }

  if (annualFee && typeof annualFee === 'string') {
    payload.annualFee = ANNUAL_FEE_OPTIONS.find(
      (option) => option.key === findAnnualFeeKey(annualFee),
    );
  }

  if (cashback && typeof cashback === 'string') {
    payload.cashback = CASHBACK_OPTIONS.find(
      (option) => option.key === findCashbackKey(cashback),
    );
  }

  if (term && typeof term === 'string') {
    const preparedTerms = parseTermKeysFromUrl(term);
    payload.terms = DEPOSIT_TERMS.map((t) => ({
      ...t,
      selected: preparedTerms.includes(t.value as any),
    }));
  }

  return payload;
};

export function getTerm(
  term: string,
  t: TFunction,
  withPlus = false,
  withDash = false,
): string {
  if (term === 'All') {
    return `${t('database.deposit type.all')}`;
  }

  const number = parseFloat((term || '').split(' ')[0]!);
  const type = term.split(' ')[1];

  if (type?.includes('month')) {
    return number === 1
      ? `1${withDash ? '-' : ' '}${t('deposit_card.month')}`
      : `${number}${withDash ? '-' : ' '}${t('deposit_card.months')}`;
  }

  return number === 1
    ? `1${withDash ? '-' : ' '}${t('deposit_card.year')}`
    : `${withPlus ? `${number}+` : number}${withDash ? '-' : ' '}${t(
        'deposit_card.years',
      )}`;
}
