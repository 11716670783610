import { useTranslation } from 'next-i18next';

import noDepFoundPng2x from '@/public/assets/pics/noDepFound@2x-min.png';
import noDepFoundWebp2x from '@/public/assets/pics/noDepFound@2x-min.webp';
import noDepFoundPng1x from '@/public/assets/pics/noDepFound-min.png';
import noDepFoundWebp1x from '@/public/assets/pics/noDepFound-min.webp';

export const NoDepositFound = () => {
  const { t } = useTranslation();

  return (
    <div className="grid min-h-full w-full place-items-center rounded-3xl bg-blue-50 px-6 py-24 sm:py-32 md:mx-4 md:mt-12 lg:px-8">
      <div className="flex flex-col content-center items-center text-center">
        <picture>
          <source
            srcSet={`${noDepFoundWebp1x.src} 1x, ${noDepFoundWebp2x.src} 2x`}
            type="image/webp"
          />
          <source
            srcSet={`${noDepFoundPng1x.src} 1x, ${noDepFoundPng2x.src} 2x`}
            type="image/png"
          />
          <img
            src={noDepFoundPng1x.src}
            alt="no deep found"
            width={noDepFoundPng1x.height}
            height={noDepFoundPng1x.height}
          />
        </picture>
        <h5 className="mt-4 text-blue-900">{t('no_deposits_found.title')}</h5>
        <p className="body2 mt-2 text-grey-800">
          {t('no_deposits_found.description')}
        </p>
      </div>
    </div>
  );
};
