import type { FC } from 'react';

import { cn } from '@/utils';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

// TODO: Can be removed and used only as classname where is needed, noneed for the component
export const Container: FC<ContainerProps> = ({ children, className }) => {
  return <div className={cn('container', className)}>{children}</div>;
};
