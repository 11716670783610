import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';

import { Icon } from '@/components/Sprite';

interface HeaderTitleProps {
  toggleMenu: () => void;
}

export const HeaderTitle: FC<HeaderTitleProps> = ({ toggleMenu }) => {
  return (
    <div className="flex max-h-[60px] min-h-[60px] content-center items-center justify-between border border-grey-200 px-5">
      <XMarkIcon
        className="h-8 w-8 cursor-pointer stroke-2 text-3xl text-grey-400"
        onClick={toggleMenu}
      />
      <Link href="/">
        <Icon
          name="LogoHeader"
          className="h-[32px] w-[177px] fill-current stroke-current text-[20px] font-bold text-transparent"
        />
      </Link>
      <div className="flex h-8 w-8 lg:hidden" />
    </div>
  );
};
