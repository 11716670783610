import { useRouter } from 'next-translate-routes/router';
import type { ReactNode } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useAuthModalsControl, useAuthStore } from '@/store';
import { isMobileApp } from '@/utils/isMobileApp';

import ApiSingleton from '../../services/ApiSingleton';

interface ApiContextProps {
  apiSingleton: ApiSingleton;
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined);

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const { token, setToken, reset } = useAuthStore((state) => state);
  const { setRedirectUri, openAuthModal } = useAuthModalsControl(
    (state) => state,
  );
  const router = useRouter();
  const isTokenInitialized = useRef(false);

  const apiSingleton = useMemo(() => ApiSingleton.getInstance(), []);
  if (!isTokenInitialized.current) {
    apiSingleton.setToken(
      token || (router?.query?.impersonate_token as string),
    );
    isTokenInitialized.current = true;
  }

  const contextValue = useMemo(() => ({ apiSingleton }), [apiSingleton]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (router.query?.impersonate_token) {
        setToken(router.query.impersonate_token as string);
      }
    }
    apiSingleton.setErrorHandler(async (error) => {
      if (error?.response?.status === 401) {
        reset();
        setToken('');
        await router.push(isMobileApp() ? '/multibanking-platform' : '/');
        openAuthModal('platform');
        setRedirectUri(router.asPath);
      }
      return error;
    });
  }, []);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context.apiSingleton;
};
