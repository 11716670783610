import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { Fragment, useState } from 'react';

import { CustomOverlay } from '@/components/CustomOverlay';
import { Icon } from '@/components/Sprite';
import { COUNTRY_FLAGS, COUNTRY_NAMES } from '@/store/storeSearch.consts';
import { cn } from '@/utils';

interface ISelectCountryProps {
  className?: string;
  isScrolling?: boolean;
  placeholder: string;
  value: string;
  onChange: (newValue: string) => void;
}

export const SelectCountryV2: FC<ISelectCountryProps> = ({
  className,
  isScrolling,
  placeholder,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCountryItemClick = (country: string) => {
    if (value === country) return;
    onChange(country);
  };

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => setOverlayVisible(!overlayVisible)}
      >
        {overlayVisible && <CustomOverlay />}
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            tabIndex={0}
            type="button"
            className={cn(
              'focus-visible:outline-gray-200 border-[1.25px] border-grey-200 flex h-[56px] flex-row justify-between items-center focus-visible:rounded-xl bg-white relative rounded-xl flex-grow-1 w-full hover:bg-gray-100 px-4 search-input',
              overlayVisible && 'bg-white z-20',
            )}
          >
            {value ? (
              <div>
                <p className="caption whitespace-nowrap text-left">
                  {placeholder}
                </p>
                <div className="flex w-full justify-between">
                  <div className="body2-bold flex w-full min-w-0 grow-[1] content-center items-center justify-start gap-2 rounded-md font-bold text-black">
                    <Image
                      src={COUNTRY_FLAGS[value]!}
                      alt={value}
                      className="h-auto w-5 rounded-sm"
                      width="32"
                      height="24"
                      unoptimized
                    />
                    <p className="truncate text-[15px]">
                      {t(`database.countries.${value}`)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-[15px] font-medium text-grey-800">
                {placeholder}
              </p>
            )}
            <Icon
              className="mt-1 min-w-0 shrink-0 stroke-2 text-transparent"
              name="arrowDownIcon"
            />
          </button>
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="left"
            align="center"
            className="shadow-far absolute z-20 mt-2 h-[325px] w-full rounded-[31px] bg-white pt-[33px]"
          >
            <div className="shadow-far absolute z-50 h-[328px] w-[230px] rounded-[31px] bg-white px-3">
              <div className="custom-scroll mt-2 overflow-y-auto">
                <div className="h-[310px]">
                  {COUNTRY_NAMES.map((country: string) => (
                    <Fragment key={country}>
                      <DropdownMenuPrimitive.Item asChild>
                        <button
                          onClick={() => {
                            handleCountryItemClick(country);
                          }}
                          className={cn(
                            value === country && 'bg-grey-100 rounded-full',
                            'focus-visible:outline-gray-100 focus-visible:rounded-full flex w-full content-center items-center hover:rounded-full hover:bg-grey-100 justify-start px-4 pb-3 pt-[10px] gap-2 text-[15px] font-bold',
                          )}
                          type="button"
                        >
                          <Image
                            src={COUNTRY_FLAGS[country]!}
                            alt={country}
                            className="h-[15px] w-5"
                            width={5}
                            height={5}
                            unoptimized
                          />
                          <p className="md:truncate lg:disabled:truncate">
                            {t(`database.countries.${country}`)}
                          </p>
                        </button>
                      </DropdownMenuPrimitive.Item>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
