import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileModals } from '@/components/MobileModal';
import { DepositModalInner } from '@/components/SearchBar/parts/MobileSearchModals/DepositModalInner';
import { Icon } from '@/components/Sprite';
import type { IDepositOperation } from '@/store/storeSearch.types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IModalDepositProps {
  types: IDepositOperation[];
  is_partner: boolean;
  typesSearchModal?: boolean;
  onChange: (types: IDepositOperation[], isPartner: boolean) => void;
}

const copyDepositTypes = (types: IDepositOperation[]) => {
  const copy: IDepositOperation[] = [];

  for (let i = 0; i < types.length; i += 1) {
    copy.push({ ...(types[i] as IDepositOperation) });
  }

  return copy;
};

export const DepositModal: FC<IModalDepositProps> = ({
  types,
  is_partner,
  typesSearchModal,
  onChange,
}) => {
  const { t } = useTranslation();
  const activeTypes = types.filter((o) => o.selected);
  const allTypesIsActive = activeTypes.length === 0;

  const typesRef = useRef<IDepositOperation[]>(copyDepositTypes(types));
  const isPartnerRef = useRef<boolean>(is_partner);
  const [_types, setTypes] = useState<IDepositOperation[]>(
    copyDepositTypes(types),
  );
  const [_isPartner, setIsPartner] = useState<boolean>(is_partner);

  const handleOnChange = (
    __types: IDepositOperation[],
    __isPartner: boolean,
  ) => {
    setTypes(__types);
    setIsPartner(__isPartner);
  };

  const handleOnClose = (isSubmitted?: boolean) => {
    if (isSubmitted) {
      onChange(_types, _isPartner);

      typesRef.current = copyDepositTypes(_types);
      isPartnerRef.current = _isPartner;
      handleOnChange(copyDepositTypes(_types), _isPartner);
    } else {
      handleOnChange(copyDepositTypes(typesRef.current), isPartnerRef.current);
    }
  };

  return (
    <MobileModals
      zMoreIndex
      trigger={
        <div className="flex w-full content-center items-center">
          <button
            onClick={() => {
              analyticServices.sendEvent({
                action: AnalyticsEvent.openType,
                category: AnalyticsCategory.deposit,
                label: 'openType',
              });
            }}
            type="button"
            className={cn(
              'search-input w-full pt-[12px] pb-[14px]',
              typesSearchModal && 'pl-[24px] pr-[27px]',
            )}
          >
            <p className="caption whitespace-nowrap">
              {t('deposits.deposit_type')}
            </p>
            <div className="body2-bold flex w-full min-w-0 grow-[1] items-center justify-between rounded-md font-bold text-blue-900">
              <p className="body2-bold min-w-0 grow-[1] truncate text-left text-[15px] capitalize text-blue-900">
                {/* {is_partner && */}
                {/*  `${t(`database.deposit type.${IS_PARTNER_KEY}`)}, `} */}
                {activeTypes.map((operation, index) => (
                  <span key={operation.key}>
                    {t(`database.deposit type.${operation.key?.toLowerCase()}`)}
                    {index < activeTypes.length - 1 ? ', ' : ''}
                  </span>
                ))}
                {allTypesIsActive && t(`database.deposit type.all`)}
              </p>
              <Icon
                className="min-w-0 shrink-0 stroke-2 text-transparent"
                name="arrowDownIcon"
              />
            </div>
          </button>
        </div>
      }
      title={`${t('deposits.deposit_type')}`}
      isButtonSelect
      buttonTitle={`${t('search.select')}`}
      onClose={handleOnClose}
    >
      <DepositModalInner
        types={_types}
        is_partner={_isPartner}
        onChange={handleOnChange}
      />
    </MobileModals>
  );
};
