import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';

import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import { useAuthStore, useSearchStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import { analyticServices, cn } from '@/utils';

interface IButtonsCardProps {
  bank: IBankDepositCard;
  isPartnerBank?: boolean;
  isBlogBankCard?: boolean;
  className?: string;
  buttonClassName?: string;
}

export const ButtonsCard: FC<IButtonsCardProps> = ({
  isPartnerBank,
  bank,
  isBlogBankCard = false,
  className,
  buttonClassName,
}) => {
  const { t } = useTranslation();

  const { isTestUser } = useAuthStore((state) => state);

  const { country } = useSearchStore((state) => state);

  const router = useRouter();

  const { showQuestionOpenDepositModal } = useShowModal();

  const { redirectToExternalLink, getRedirectUrl } = useExternalRedirect();

  const sendAnalytics = async (typeEvent: AnalyticsEvent) => {
    analyticServices.sendEvent({
      action: typeEvent,
      category: AnalyticsCategory.deposit,
      label: 'Deposit ID',
      payload: {
        bank_index: bank.bankIndex,
        country,
        product_index: bank.productIndex,
      },
    });
  };

  // TODO: uncomment when applications should open by open online button
  const handlePressOpenOnline = (e: any) => {
    e.preventDefault();
    sendAnalytics(AnalyticsEvent.openOnline);
    if (!isTestUser || bank.partnerType !== 'platform') {
      redirectToExternalLink({
        onSuccess: () => {
          showQuestionOpenDepositModal({
            productIndex: bank.productIndex,
            isTestUser,
          });
        },
        bank,
      });
      return;
    }
    const uri = `/deposits/${bank.productIndex}`;
    router.push({
      pathname: uri,
      query: isTestUser ? { test_user: isTestUser } : undefined,
    });
  };

  const renderButton = () => {
    if (bank.productType === 'Fixed term deposit') {
      if (isPartnerBank || bank.isPartnerBank) {
        return (
          <Link
            className={cn(
              '!text-white body3-bold xl:body2-bold  flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-blue-500 px-6 text-center hover:bg-blue-550 xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
              isBlogBankCard &&
                'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:body3-bold xl:px-4 xl:max-h-[44px] text-[13px]',
              buttonClassName,
            )}
            href={`/deposits/${bank.productIndex}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePressOpenOnline(e);
            }}
          >
            {t('deposit_card.button_open_online')}
          </Link>
        );
      }
      return (
        <Link
          className={cn(
            '!text-blue-500 body3-bold xl:body2-bold flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-blue-100 px-6 text-center hover:bg-blue-150 xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
            isBlogBankCard &&
              'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:body3-bold xl:px-4 xl:max-h-[44px] text-[13px]',
            buttonClassName,
          )}
          href={`/deposits/${bank.productIndex}`}
          onClick={(e) => {
            e.stopPropagation();
            sendAnalytics(AnalyticsEvent.moreInfo);
          }}
        >
          {t('deposit_card.button_more_info')}
        </Link>
      );
    }
    if (isPartnerBank || bank.isPartnerBank) {
      return (
        <Link
          className={cn(
            '!text-white body3-bold xl:body2-bold flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-blue-500 px-6 text-center  hover:bg-blue-550 xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
            isBlogBankCard &&
              'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:body3-bold xl:px-4 xl:max-h-[44px] text-[13px]',
            buttonClassName,
          )}
          href={getRedirectUrl(bank)}
          onClick={(e) => {
            e.preventDefault();
            sendAnalytics(AnalyticsEvent.openOnline);
            redirectToExternalLink({
              onSuccess: () => {
                showQuestionOpenDepositModal({
                  productIndex: bank.productIndex,
                  isTestUser,
                });
              },
              bank,
            });
          }}
          target="_blank"
        >
          {t('deposit_card.button_open_online')}
        </Link>
      );
    }
    return (
      <Link
        className={cn(
          '!text-blue-500 body3-bold xl:body2-bold flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-blue-100 px-6 text-center hover:bg-blue-150 xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
          isBlogBankCard &&
            'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:max-h-[44px] text-[13px]',
          buttonClassName,
        )}
        href={getRedirectUrl(bank)}
        onClick={(e) => {
          e.preventDefault();
          sendAnalytics(AnalyticsEvent.moreInfo);
          redirectToExternalLink({
            onSuccess: () => {
              showQuestionOpenDepositModal({
                productIndex: bank.productIndex,
                isTestUser,
              });
            },
            bank,
          });
        }}
        target="_blank"
      >
        {t('deposit_card.button_more_info')}
      </Link>
    );
  };

  return (
    <div
      className={cn(
        'flex content-center justify-center md:content-start md:items-start md:justify-start',
        className,
      )}
    >
      {renderButton()}
    </div>
  );
};
