import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';

export interface IDialogTermsProps {
  className?: string;
}

export const DialogTerms: FC<IDialogTermsProps> = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-4 rounded-xl bg-grey-100 px-4 py-2">
      <p className="caption tracking-[0.2px] text-grey-600">
        {t('login.terms.text_1')}{' '}
        <Link
          href={t('terms-of-use.link')}
          target="_blank"
          className="cursor-pointer text-blue-500 hover:underline"
        >
          {t('login.terms.text_2_bold')}
        </Link>
        {', '}
        <Link
          href={t('privacy_policy.link')}
          target="_blank"
          className="cursor-pointer text-blue-500 hover:underline"
        >
          {t('login.terms.text_3_bold')}{' '}
        </Link>{' '}
        {t('login.terms.text_4')}{' '}
        <Link
          href={t('consent-to-personal-data-processing.link')}
          target="_blank"
          className="cursor-pointer text-blue-500 hover:underline"
        >
          {t('login.terms.text_5_bold')}
        </Link>
      </p>
    </div>
  );
};
