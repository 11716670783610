import type { FC } from 'react';
import React from 'react';

import { BestProductItem } from '@/components/BestProductsList/BestProductItem';
import type { IBankDepositCard } from '@/types';
import { cn } from '@/utils';

interface BestProductsListProps {
  products: IBankDepositCard[];
  className?: string;
  itemsClassName?: string;
  onItemClick?: () => void;
}
export const BestProductsList: FC<BestProductsListProps> = ({
  products,
  className,
  itemsClassName,
  onItemClick,
}) => {
  return (
    <div className={cn('flex flex-col divide-y divide-blue-150', className)}>
      {products.map((deposit) => {
        return (
          <BestProductItem
            product={deposit}
            key={deposit.uuid}
            className={itemsClassName}
            onClick={onItemClick}
          />
        );
      })}
    </div>
  );
};
