import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';

import { Icon } from '@/components/Sprite';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

export const ProtectionSheild = () => {
  const { t } = useTranslation();

  const handleLearnMore = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickLearnMore,
      category: AnalyticsCategory.main,
      label: 'clickLearnMore',
    });
  };

  return (
    <div className="mt-[54px] flex w-full items-start gap-6 md:gap-2 lg:mt-[92px] lg:max-w-[648px]">
      <div className="flex size-[52px] min-w-0 shrink-0 items-center justify-center rounded-full bg-blue-50 md:size-[56px]">
        <Icon className="size-8 object-contain" name="verifiedShield" />
      </div>
      <div className="flex w-full flex-col justify-start text-left md:flex-row md:items-center md:justify-between">
        <div className="">
          <h6 className="tracking-[0.2px] text-blue-900 md:leading-[20px]">
            {t('protected.title')}
          </h6>
          <p className="body3 mt-[2px] tracking-[0.1px] md:mt-0 md:text-[13px] md:leading-[18px]">
            {t('protected.description.text_1')}
            <span className="body3-bold ">
              {` ${t('protected.description.text_2_bold')} `}
            </span>
            {t('protected.description.text_3')}
          </p>
        </div>

        <Link
          href={t('faq_page.question_5.link')}
          onClick={handleLearnMore}
          className={cn(
            'hover:bg-blue-150 mt-3 w-full font-inter rounded-full text-start text-[13px] font-bold leading-[18px] tracking-[0.1px] text-blue-500',
            'md:mt-0 md:bg-blue-100 md:px-6 md:py-[18px] md:text-[15px] md:leading-[20px] md:tracking-[0.2px] md:w-[160px] md:text-center',
          )}
        >
          {t('hero_section.learn_more')}
        </Link>
      </div>
    </div>
  );
};
