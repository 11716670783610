import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomOverlay } from '@/components/CustomOverlay';
import { SelectHeader } from '@/components/SearchBar/parts/SelectHeader';
import { useSearchStore } from '@/store';
import { ANNUAL_FEE_OPTIONS } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface ISelectCountryProps {
  className?: string;
  dropdownClassname?: string;
  isScrolling?: boolean;
}

export const AnnualFeeSelect: FC<ISelectCountryProps> = ({
  className,
  dropdownClassname,
  isScrolling,
}) => {
  const { t } = useTranslation();

  const selectedFee = useSearchStore((state) => state.annualFee);
  const setSelectedCountry = useSearchStore((state) => state.setAnnualFee);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCountryItemClick = (fee: any) => {
    if (selectedFee !== fee) {
      setSelectedCountry(fee);
    }
  };

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => setOverlayVisible(!overlayVisible)}
      >
        {overlayVisible && <CustomOverlay />}
        <DropdownMenuPrimitive.Trigger className="w-full focus-visible:rounded-full focus-visible:outline-gray-200">
          <SelectHeader
            onClick={() => {
              analyticServices.sendEvent({
                action: AnalyticsEvent.openResidency,
                category: AnalyticsCategory.deposit,
                label: 'openResidency',
              });
            }}
            label={t('global.annual_fee')}
            inFocus={overlayVisible}
          >
            {t(
              `savings-accounts.annual-fee.filter-${selectedFee.key.toLowerCase()}`,
            )}
          </SelectHeader>
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white py-2',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[10px] overflow-y-auto">
              <div className="px-2">
                {ANNUAL_FEE_OPTIONS.map((fee) => (
                  <Fragment key={fee.key}>
                    <DropdownMenuPrimitive.Item asChild>
                      <button
                        onClick={() => {
                          handleCountryItemClick(fee);
                        }}
                        className={cn(
                          selectedFee.value === fee.value &&
                            'bg-grey-100 rounded-full',
                          'focus-visible:outline-gray-100 focus-visible:rounded-full flex w-full content-center items-center hover:rounded-full hover:bg-grey-100 justify-start px-4 pb-3 pt-[10px] gap-2 text-[15px] font-bold',
                        )}
                        type="button"
                      >
                        <p className="md:truncate lg:disabled:truncate">
                          {t(
                            `savings-accounts.annual-fee.${fee.key.toLowerCase()}`,
                          )}
                        </p>
                      </button>
                    </DropdownMenuPrimitive.Item>
                  </Fragment>
                ))}
              </div>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
