import type { FC, ReactNode } from 'react';

// TODO: refactor with no-cycle
// eslint-disable-next-line import/no-cycle
import { Button } from '@/components';

interface ListItem {
  title: string | JSX.Element;
  value?: string | JSX.Element;
}

interface Action {
  title: string;
  callback: () => void;
}
interface DialogMyDataListProps {
  title: string;
  items: ListItem[];
  action?: Action;
  isEditing?: boolean;
  isEditable?: boolean;
  children?: ReactNode;
}
export const DialogMyDataList: FC<DialogMyDataListProps> = ({
  title,
  items,
  action,
  isEditing,
  isEditable,
  children,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between border-b border-blue-150 pb-3">
        <h5>{title}</h5>
        {action && isEditable && (
          <Button
            color="secondary"
            variant="outline"
            props={{
              onClick: () => {
                action?.callback();
              },
            }}
            className="text-right"
          >
            {action.title}
          </Button>
        )}
      </div>
      {isEditing ? (
        <div>{children}</div>
      ) : (
        <ul>
          {items.map((item, index) => (
            <li
              className="flex flex-col justify-between gap-2 border-b border-blue-150 py-3 sm:flex-row md:gap-8"
              key={index}
            >
              <span className="body2 block text-grey-800">{item.title}</span>
              <span className="body2 block whitespace-pre-wrap text-blue-900 sm:text-right">
                {item.value || '—'}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
