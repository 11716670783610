import type { FC } from 'react';

import { useSearchStore } from '@/store';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { cn } from '@/utils';

import { CurrencyModal, TermsModal } from '../MobileSearchModals';
import { SearchButton } from '../SearchButton';

interface IDescriptionProps {
  className?: string;
  otherStyleFormDescription?: boolean;
  buttonChange?: boolean;
  onSubmit: () => void;
  productType?: 'savings account' | 'fixed term deposit';
}

export const DescriptionOfModalStatistics: FC<IDescriptionProps> = ({
  className,
  otherStyleFormDescription,
  buttonChange,
  onSubmit,
  productType,
}) => {
  const { terms, setTerms } = useSearchStore((state) => state);

  const handleTermsChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-2',
        buttonChange ? 'px-4 pt-0' : 'px-0 pt-4',
        className,
      )}
    >
      {productType === 'fixed term deposit' && (
        <TermsModal
          terms={terms}
          termsSearchModal={otherStyleFormDescription}
          onChange={handleTermsChange}
        />
      )}
      <div
        className={cn(
          'flex w-full hover:bg-gray-100 items-center justify-between rounded-[32px] border-[1.5px] border-gray-200',
          buttonChange ? 'gap-1' : 'gap-2',
        )}
      >
        <CurrencyModal
          className="w-full px-2.5"
          currencySearchModal={otherStyleFormDescription}
        />
      </div>
      {buttonChange ? (
        <div
          className={cn(
            buttonChange
              ? 'pt-4'
              : 'sticky bottom-0 z-50 mb-[9px] mt-3 flex w-full overflow-y-hidden',
          )}
        >
          <SearchButton
            onClick={onSubmit}
            className="z-50 max-h-[100px] w-full self-center px-6 py-[18px] md:hidden"
            searchType={
              productType === 'fixed term deposit' ? 'deposits' : 'savings'
            }
          />
        </div>
      ) : null}
    </div>
  );
};
