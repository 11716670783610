import type {
  AnalyticsData,
  GetBlogItemsQueryDto,
  GetDepositsQueryDto,
} from '@pickthebank/api-sdk';
import {
  ApiService,
  GetBlogItemRequest,
  GetBlogItemsRequest,
  GetCountriesRequest,
  GetCurrentCountryRequest,
  GetDepositRequest,
  GetDepositsRequest,
  GetDepositsStatisticsRequest,
  GetFileRequest,
  GetFilteredBlogItemsRequest,
  GetFooterPagesRequest,
  GetSimilarDepositsRequest,
  GetSlugLandingPageRequest,
  GetSlugPageRequest,
  PostLoginRequest,
  PostQuestionOpenDepositRequest,
  PostVerifyRequest,
} from '@pickthebank/api-sdk';

import type { IGetFilteredBlogItemsRequestParams } from '@/types/blogInterfaces';
import type { SubscriptionObject } from '@/utils/subscriptions';

import { supabase } from './supabaseClient';

type IFetchSupabaseFeaturedDepositsType = Omit<
  GetDepositsQueryDto,
  'offset' | 'country' | 'currency' | 'bank_name' | 'exclude'
>;
export interface IFetchSupabaseFeaturedDepositsPayload
  extends IFetchSupabaseFeaturedDepositsType {
  country: string;
  currency: string;
}

const TABLE_FAVORITES_DEPOSITS =
  process.env.NEXT_PUBLIC_TABLE_FAVORITES_COMPANIES || 'favorites';

// ? The featured response appears in the "Partner deposits available online" section.
export const fetchSupabaseFeaturedDeposits = async (
  language = 'en',
  country = 'Germany',
  currency = 'eur',
  type: 'all' | string[] = 'all',
  is_partner: boolean | null = true,
  terms: 'all' | number[] = 'all',
  product_type = ['fixed term deposit'],
  is_test: boolean = false,
) => {
  const apiService: ApiService = new ApiService();
  let isTest = false;
  if (is_test) {
    isTest = true;
  }
  if (typeof window !== 'undefined') {
    const auth = localStorage.getItem('auth::storage');
    if (auth) {
      const authData = JSON.parse(auth);
      if (authData?.state?.isTestUser) {
        isTest = true;
      }
    }
  }
  const payload: Partial<GetDepositsQueryDto> = {
    offset: 0,
    language,
    country: [country],
    currency: currency === 'all' ? undefined : [currency],
    terms: terms === 'all' ? undefined : terms,
    type: type === 'all' ? undefined : type,
    product_type,
    is_test: isTest,
  };

  if (is_partner) {
    payload.is_partner = is_partner;
  }

  return apiService.send(new GetDepositsRequest(payload));
};

type IFetchSupabaseDepositsType = Omit<
  GetDepositsQueryDto,
  'country' | 'currency' | 'bank_name' | 'exclude'
>;
export interface IFetchSupabaseDepositsPayload
  extends IFetchSupabaseDepositsType {
  country: string;
  currency: string;
  cashback: string;
  'annual-fee': string;
}

// ? The default response appears in the "Other deposits" section.
export const fetchSupabaseDeposits = async (
  offset: number,
  language = 'en',
  country = 'Germany',
  currency = 'eur',
  type: string[] | 'all' = 'all',
  terms: number[] | 'all' = 'all',
  product_type = ['fixed term deposit'],
  is_partner: boolean = false,
  is_test: boolean = false,
) => {
  const apiService: ApiService = new ApiService();
  let isTest = false;
  if (is_test) {
    isTest = true;
  }
  if (typeof window !== 'undefined') {
    const auth = localStorage.getItem('auth::storage');
    if (auth) {
      const authData = JSON.parse(auth);
      if (authData?.state?.isTestUser) {
        isTest = true;
      }
    }
  }

  const payload: Partial<GetDepositsQueryDto> = {
    language,
    country: [country],
    currency: currency === 'all' ? undefined : [currency],
    terms: terms === 'all' ? undefined : terms,
    type: type === 'all' ? undefined : type,
    product_type,
    is_partner,
    is_test: isTest,
  };

  if (offset !== 0) {
    payload.offset = offset;
  }

  const request = new GetDepositsRequest(payload);

  return apiService.send(request);
};

interface FetchSavingsAccounts {
  offset: number;
  language: string;
  country: string;
  currency: string;
  type: string[] | 'all';
  terms: number[] | 'all';
  product_type: string[];
  is_partner: boolean;
  limit: boolean;
}
export const fetchSavingsAccounts = async ({
  offset = 0,
  language = 'en',
  country = 'Germany',
  currency = 'eur',
  type = 'all',
  terms = 'all',
  product_type = ['fixed term deposit'],
  is_partner = false,
}: FetchSavingsAccounts) => {
  const apiService: ApiService = new ApiService();

  const payload: Partial<GetDepositsQueryDto> = {
    language,
    country: [country],
    currency: currency === 'all' ? undefined : [currency],
    terms: terms === 'all' ? undefined : terms,
    type: type === 'all' ? undefined : type,
    product_type,
    is_partner,
  };

  if (offset !== 0) {
    payload.offset = offset;
  }

  const request = new GetDepositsRequest(payload);

  return apiService.send(request);
};

// interface FetchDeposits {
//   offset: number;
//   language: string;
//   country: string;
//   currency: string;
//   type: string[] | 'all';
//   terms: number[] | 'all';
//   product_type: string[];
//   is_partner: boolean;
//   limit: boolean;
// }
// export const fetchDeposits = async ({
//   offset = 0,
//   language = 'en',
//   country = 'Germany',
//   currency = 'eur',
//   type = 'all',
//   terms = 'all',
//   product_type = ['fixed term deposit'],
//   is_partner = false,
// }: FetchDeposits) => {
//   const apiService: ApiService = new ApiService();
//
//   const payload: Partial<GetDepositsQueryDto> = {
//     language,
//     country: [country],
//     currency: currency === 'all' ? undefined : [currency],
//     terms: terms === 'all' ? undefined : terms,
//     type: type === 'all' ? undefined : type,
//     product_type,
//     is_partner,
//   };
//
//   if (offset !== 0) {
//     payload.offset = offset;
//   }
//
//   const request = new GetDepositsRequest(payload);
//
//   return apiService.send(request);
// };
interface FetchDeposits {
  offset: number;
  language: string;
  country: string;
  currency: string;
  type: string[] | 'all';
  terms: number[] | 'all';
  product_type: string[];
  is_partner: boolean;
  limit: boolean;
  is_test?: boolean;
  bank_name?: string;
}
export const fetchDeposits = async ({
  offset = 0,
  language = 'en',
  country = 'Germany',
  currency = 'eur',
  type = 'all',
  terms = 'all',
  product_type = ['fixed term deposit'],
  is_partner = false,
  is_test,
  bank_name,
}: FetchDeposits) => {
  const apiService: ApiService = new ApiService();

  let isTest = false;
  if (is_test) {
    isTest = true;
  }

  const payload: Partial<GetDepositsQueryDto> = {
    language,
    country: [country],
    currency: currency === 'all' ? undefined : [currency],
    terms: terms === 'all' ? undefined : terms,
    type: type === 'all' ? undefined : type,
    product_type,
    is_partner,
    is_test: isTest,
    bank_name,
  };

  if (offset !== 0) {
    payload.offset = offset;
  }

  const request = new GetDepositsRequest(payload);

  return apiService.send(request);
};

export const fetchCurrentCountry = async () => {
  const apiService: ApiService = new ApiService();

  const request = new GetCurrentCountryRequest();

  return apiService.send(request);
};

export const fetchOneDeposit = async (
  productIndex: number,
  language: string,
) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetDepositRequest(productIndex, language));
};

export const fetchArrayDeposits = async (
  productIndexes: number[],
  language: string,
) => {
  const apiService: ApiService = new ApiService();

  const deposits = [];
  for (const productIndex of productIndexes) {
    // TODO: remove await requests in loop
    // eslint-disable-next-line no-await-in-loop
    const response = await apiService.send(
      new GetDepositRequest(productIndex, language),
    );
    deposits.push(response);
  }

  return deposits;
};

export const fetchSimilarDeposits = async (
  productIndex: number,
  language: string,
  offset = 0,
  is_test = false,
) => {
  const apiService: ApiService = new ApiService();
  let isTest = false;
  if (is_test) {
    isTest = true;
  }
  if (typeof window !== 'undefined') {
    const auth = localStorage.getItem('auth::storage');
    if (auth) {
      const authData = JSON.parse(auth);
      if (authData?.state?.isTestUser) {
        isTest = true;
      }
    }
  }

  const request = new GetSimilarDepositsRequest(
    productIndex,
    language,
    isTest,
    offset,
  );
  return apiService.send(request);
};

export const getFile = (url: string, token: string) => {
  const apiService: ApiService = new ApiService({
    token,
  });
  const request = new GetFileRequest(url);
  // eslint-disable-next-line consistent-return
  return apiService.send(request);
};

export const getCountriesList = () => {
  const apiService: ApiService = new ApiService();
  const request = new GetCountriesRequest();
  return apiService.send(request);
};

export const postQuestionOpenDepositRequest = ({
  answer,
  otherText,
  email,
  productIndex,
  isTestUser,
  locale,
  analytics,
}: {
  answer: string;
  otherText?: string;
  email?: string;
  productIndex?: string;
  isTestUser?: boolean;
  locale: string;
  analytics: any;
}): Promise<void> => {
  const apiService: ApiService = new ApiService();
  const request = new PostQuestionOpenDepositRequest({
    data: {
      answer,
      other_text: otherText,
      email,
      product_index: productIndex,
      is_test_user: isTestUser,
      locale,
      analytics,
    },
  });
  return apiService.send(request);
};

export const fetchFavoritesDeposits = async (
  user_id: string,
): Promise<number[]> => {
  const { data } = await supabase
    .from(TABLE_FAVORITES_DEPOSITS)
    .select('user_id, favorites_deposits')
    .eq('user_id', user_id)
    .single();

  return data?.favorites_deposits;
};

export const fetchBlogItems = async (offset: number) => {
  const apiService: ApiService = new ApiService();
  const params: GetBlogItemsQueryDto = {
    offset,
  };
  return apiService.send(new GetBlogItemsRequest(params));
};

export const fetchFilteredBlogItems = async (
  params: IGetFilteredBlogItemsRequestParams,
) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetFilteredBlogItemsRequest(params));
};

export const fetchBlogItem = async (slug: string, locale: string) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetBlogItemRequest(slug, locale));
};

export const MapDataFromServer = (value: { [p: string]: any }) => ({
  bankName: value.bank_name,
  productIndex: value.product_index,
  directLink: value.direct_link,
  country: value.bank_country || value.country,
  onlineChannel: value.online_channel,
  productType: value.product_type,
  productName: value.product_name,
  currency: value.currency,
  grossInterestRate: value.gross_interest_rate,
  tax: value.tax,
  netInterestRate: value.net_interest_rate,
  termMonths: value.term_months,
  additionalDepositsAvailable: value.additional_deposits_available,
  earlyTermination: value.early_termination,
  earlyPartialWithdrawals: value.early_partial_withdrawals,
  depositType: value.deposit_type,
  interestPaymentFrequency: value.interest_payment_frequency,
  minimumAmount: value.minimum_amount,
  maximumAmount: value.maximum_amount,
  dateUpdated: value?.date_updated || '',
  bankSlug: value?.bank_slug || '',
  bankSlugCountry: value.bank_country || value.country,
  headOffice: value.head_office,
  comment: value.comment,
  bankIndex: value.bank_index,
  isPartnerBank: value.is_partner,
  availableCountries: value.available_countries,
  partnerType: value.partner_type,
  cashback: value?.cashback || null,
  annualFee: value?.annual_fee || null,
  uuid: value?.uuid || '',
});

export const MapDepositsFromServer = (value: { [p: string]: any }) => ({
  bankName: value.bank?.name || '',
  productIndex: value.product_index,
  directLink: value?.direct_link || '',
  country: value.bank.country?.name || '',
  onlineChannel: value?.online_channel || false,
  productType: value.product_type,
  productName: value.product_name,
  currency: value.currency,
  grossInterestRate: value.gross_interest_rate,
  tax: value.tax,
  netInterestRate: value.net_interest_rate,
  termMonths: value.term_months,
  additionalDepositsAvailable: value.additional_deposits_available,
  earlyTermination: value.early_termination,
  earlyPartialWithdrawals: value.early_partial_withdrawals,
  depositType: value.deposit_type,
  interestPaymentFrequency: value.interest_payment_frequency,
  minimumAmount: value.minimum_amount,
  maximumAmount: value.maximum_amount,
  dateUpdated: value?.date_updated || '',
  bankSlug: value.bank?.parent?.slug || value?.bank?.slug || '',
  bankSlugCountry:
    value.bank?.parent?.country?.name || value.bank.country?.name || '',
  headOffice: value.head_office,
  comment: value.comment,
  bankIndex: value.bank.index,
  isPartnerBank: value.is_partner,
  availableCountries: value.available_countries,
  partnerType: value.partner_type,
  cashback: value?.cashback || null,
  annualFee: value?.annual_fee || null,
  uuid: value?.uuid || '',
});

export async function fetchFavoriteDeposits(
  userID: string,
  setFavoriteDeposits: any,
  language: string,
) {
  try {
    await fetchFavoritesDeposits(userID)
      .then((favoriteIndex) => fetchArrayDeposits(favoriteIndex, language))
      .then(
        (deposits) => deposits?.map((deposit) => MapDataFromServer(deposit))!,
      )
      .then((favorite_deposits) => setFavoriteDeposits(favorite_deposits));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error loading favorites\n\n', error);
  }
}

export const fetchSlugPage = async (slug: string, locale: string) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetSlugPageRequest(slug, locale));
};

export const fetchSlugLandingPage = async (slug: string, locale: string) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetSlugLandingPageRequest(slug, locale));
};

export const fetchDepositsStatistics = async (
  terms: number[] | undefined,
  currency: string | undefined,
  type?: string[],
  product_type = ['fixed term deposit'],
) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(
    new GetDepositsStatisticsRequest({
      terms: terms || [],
      currency: currency && currency !== 'all' ? [currency] : undefined,
      type,
      product_type,
    }),
  );
};

export const signUp = async (
  email: string,
  lang: string,
  recaptchaToken: string,
  analyticsData?: AnalyticsData,
  subscriptions: SubscriptionObject[] = [],
  fallbackUri?: string,
) => {
  const apiService: ApiService = new ApiService();
  const params = {
    email,
    language: lang,
    recaptcha_token: recaptchaToken,
    referral: '',
    productRequest: 0,
    analyticsData,
    subscriptions,
    fallback_uri: fallbackUri,
  };
  const response = await apiService.send(new PostLoginRequest(params));
  return response;
};

export const verifyCode = async (
  email: string,
  recaptcha_token: string,
  code: string,
) => {
  const apiService: ApiService = new ApiService();
  const params = {
    email,
    recaptcha_token,
    code,
  };
  const response = await apiService.send(new PostVerifyRequest(params));
  return response;
};

export const fetchFooterPages = async (locale: string) => {
  const apiService: ApiService = new ApiService();
  return apiService.send(new GetFooterPagesRequest(locale));
};
