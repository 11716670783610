import { Icon } from '@/components/Sprite';

export const MarkIcon = ({
  icon,
}: {
  icon: 'WarningGray' | 'Warning' | 'Advantage' | 'Disadvantage';
}) => {
  switch (icon) {
    case 'WarningGray':
      return (
        <Icon
          className="mr-2 inline-block h-5 w-5 text-transparent"
          name="WarningDeposit"
        />
      );
    case 'Warning':
      return (
        <Icon
          className="mr-2 inline-block h-5 w-5 text-transparent"
          name="WarningIcon"
        />
      );
    case 'Advantage':
      return (
        <Icon
          className="mr-2 inline-block h-5 w-5 text-transparent"
          name="greenTik"
        />
      );
    case 'Disadvantage':
      return (
        <Icon
          className="mr-2 inline-block h-5 w-5 text-transparent"
          name="close"
        />
      );
    default:
      return null;
  }
};
