import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { useModalsStore } from '@/store/modalsStore';
import { cn } from '@/utils';

import { Icon } from '../Sprite';
import { HeaderTitle, LanguageMenu, NavbarMobile } from './parts';

interface IHamburgerMenuProps {
  isAutenficated: boolean;
  onLogin: () => void;
  onSignUp: () => void;
  onLogout: () => void;
  isHamburgerVisible?: boolean;
}

export const HamburgerMenu: FC<IHamburgerMenuProps> = ({
  onLogin,
  onSignUp,
  onLogout,
  isAutenficated,
  isHamburgerVisible = true,
}) => {
  const { t } = useTranslation();

  const { isBurgerMenuOpen: isOpen, setIsBurgerMenuOpen: setIsOpen } =
    useModalsStore((state) => state);

  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClose = () => {
    setIsOpen(false);
  };

  const closeAll = () => {
    handleClose();
    setIsLanguageOpen(false);
  };
  const toggleLauguageMenu = () => setIsLanguageOpen(!isLanguageOpen);

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling on the body when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling when modal is closed
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to prevent memory leaks
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]); // Only run effect when 'isOpen' value changes

  return (
    <>
      <div className="flex lg:hidden">
        {isHamburgerVisible && (
          <button type="button" onClick={toggleMenu} className="border-none">
            <Icon
              className="z-10 size-8 cursor-pointer text-3xl text-transparent"
              name="burger"
            />
          </button>
        )}
        {isOpen && (
          <div
            className={cn(
              'fixed flex flex-col left-0 top-0 z-50 h-[100dvh] safe-area w-screen overflow-x-hidden bg-white font-medium transition-all duration-500 ease-in-out',
            )}
          >
            <HeaderTitle toggleMenu={toggleMenu} />
            <div className="flex grow flex-col justify-between">
              <NavbarMobile
                onClose={handleClose}
                toggleLauguageMenu={toggleLauguageMenu}
              />
              <div className="sticky bottom-0 flex w-full content-center gap-3 bg-white px-5 py-3">
                {isAutenficated ? (
                  <button
                    type="button"
                    onClick={onLogout}
                    className="body2 w-full rounded-full py-3 text-left text-red-500 disabled:bg-gray-300"
                  >
                    {t('header.logout')}
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={onSignUp}
                      className="body3-bold w-1/2 rounded-full bg-blue-100 py-4 text-blue-500"
                    >
                      {t('header.register')}
                    </button>
                    <button
                      type="button"
                      onClick={onLogin}
                      className="body3-bold w-1/2 rounded-full bg-blue-500 py-4 text-white"
                    >
                      {t('header.login')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isLanguageOpen && (
        <LanguageMenu
          onCloseAll={closeAll}
          onCloseLauguageMenu={() => setIsLanguageOpen(false)}
        />
      )}
    </>
  );
};
