import { cn } from '@/utils/cn';

export const textMutation = (text: string, className?: string) => {
  return text.split('\n').map((paragraph, index) => (
    <p key={index} className={cn(className)}>
      {paragraph}
    </p>
  ));
};

export const extractDomainFromUrl = (url: string) => {
  try {
    const urlObject = new URL(url);
    let domain = urlObject.hostname;
    domain = domain.replace(/^www\./, '');
    return domain;
  } catch (error) {
    return '';
  }
};
