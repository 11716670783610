import Flag_ENG from '@/public/assets/countries/ENG.svg';
import Flag_FI from '@/public/assets/countries/Finland.svg';
import Flag_FR from '@/public/assets/countries/France.svg';
import Flag_DE from '@/public/assets/countries/Germany.svg';
import Flag_EL from '@/public/assets/countries/Greece.svg';
import Flag_IT from '@/public/assets/countries/Italy.svg';
import Flag_LT from '@/public/assets/countries/Lithuania.svg';
import Flag_NL from '@/public/assets/countries/Netherlands.svg';
import Flag_PT from '@/public/assets/countries/Portugal.svg';
import Flag_ES from '@/public/assets/countries/Spain.svg';
import Flag_SV from '@/public/assets/countries/Sweden.svg';
import type { ILanguageSwitcher } from '@/types';

export type LangSwitcherDataKey =
  | 'en'
  | 'de'
  | 'nl'
  | 'es'
  | 'it'
  | 'fr'
  | 'pt'
  | 'el'
  | 'sv'
  | 'lt'
  | 'fi';

export const LangSwitcherData: {
  [K in LangSwitcherDataKey]: ILanguageSwitcher;
} = {
  en: {
    flagImg: Flag_ENG,
    title: 'English',
    shortName: 'En',
  },
  de: {
    flagImg: Flag_DE,
    title: 'German',
    shortName: 'De',
  },
  nl: {
    flagImg: Flag_NL,
    title: 'Dutch',
    shortName: 'Nl',
  },
  es: {
    flagImg: Flag_ES,
    title: 'Spanish',
    shortName: 'Es',
  },
  it: {
    flagImg: Flag_IT,
    title: 'Italian',
    shortName: 'It',
  },
  fr: {
    flagImg: Flag_FR,
    title: 'French',
    shortName: 'Fr',
  },
  pt: {
    flagImg: Flag_PT,
    title: 'Portuguese',
    shortName: 'Pt',
  },
  el: {
    flagImg: Flag_EL,
    title: 'Greek',
    shortName: 'El',
  },
  sv: {
    flagImg: Flag_SV,
    title: 'Swedish',
    shortName: 'Sv',
  },
  lt: {
    flagImg: Flag_LT,
    title: 'Lithuanian',
    shortName: 'Lt',
  },
  fi: {
    flagImg: Flag_FI,
    title: 'Finnish',
    shortName: 'Fi',
  },
};
