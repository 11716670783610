import type { ReactElement } from 'react';
import React from 'react';

import { cn } from '@/utils';

interface CheckboxProps {
  label?: string | ReactElement;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  className?: string;
  labelClassName?: string;
  checkboxClassName?: string;
  disabled?: boolean;
}

export const CheckboxInput: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  className,
  labelClassName,
  checkboxClassName,
  disabled,
}) => {
  const toggleCheckbox = () => {
    onChange(!checked);
  };

  const inputId = `checkbox-${Math.random().toString(36).substring(7)}`;

  return (
    <div className={cn('flex items-start', className)}>
      <input
        type="checkbox"
        className={cn(
          'shrink-0 h-5 w-5 mt-[2px] text-indigo-600',
          checkboxClassName,
        )}
        checked={checked}
        onChange={toggleCheckbox}
        disabled={disabled}
      />
      {label && (
        <label
          htmlFor={inputId}
          className={cn(
            'ml-3 text-[15px] font-medium text-grey-800',
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
};
