import type { FC, ReactNode } from 'react';

import { cn } from '@/utils';

export interface ITableCellProps {
  className?: string;
  children?: ReactNode;
}

export const TableCell: FC<ITableCellProps> = ({ className, children }) => {
  return (
    <td
      className={cn('px-2 md:py-4 lg:px-3 first:!pl-0 last:!pr-0', className)}
    >
      {children}
    </td>
  );
};
