import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAuthStore {
  setCaptchaToken: (token: string) => void;
  setEmail: (email: string) => void;
  setVerificationCode: (code: string) => void;
  setConfirmEmail: (isConfirm: boolean) => void;
  setToken: (token: string) => void;
  setError: (error: string[]) => void;
  reset: () => void;
  setIsCodeLink: (isCodeLink: boolean) => void;
  setIsTestUser: (isTestUser: boolean) => void;
  setUserFullName: (fullName: string | null) => void;
  setIsVerified: (value: boolean) => void;
  setUserUuid: (uuid: string | null) => void;
}
interface IAuthStoreState {
  email: string;
  token: string;
  captchToken: string;
  verificationCode: string;
  isConfirmedEmail: boolean;
  errors: string[];
  isCodeLink: boolean;
  isTestUser?: boolean;
  fullName: string | null;
  isVerified: boolean;
  userUuid: string | null;
}

const initialState: IAuthStoreState = {
  isConfirmedEmail: false,
  email: '',
  token: '',
  captchToken: '',
  verificationCode: '',
  errors: [],
  isCodeLink: false,
  isTestUser: false,
  fullName: null,
  isVerified: false,
  userUuid: null,
};

const loadStateFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const storedState = localStorage.getItem('auth::storage');
    return storedState ? JSON.parse(storedState)?.state : initialState;
  }
  return initialState;
};

export const useAuthStore = create<IAuthStore & IAuthStoreState>()(
  devtools(
    persist(
      (set) => ({
        ...loadStateFromLocalStorage(),
        setEmail: (email: string) => set(() => ({ email })),

        setVerificationCode: (code: string) =>
          set(() => ({ verificationCode: code })),

        setConfirmEmail: (isConfirm: boolean) =>
          set(() => ({ isConfirmedEmail: isConfirm })),

        setToken: (token: string) => set(() => ({ token })),

        setCaptchaToken: (token) => set(() => ({ captchToken: token })),

        setError: (error) => set(() => ({ errors: error })),

        setIsCodeLink: (isCodeLink) => set(() => ({ isCodeLink })),

        setIsTestUser: (isTestUser) => set(() => ({ isTestUser })),
        setUserFullName: (fullName) => set(() => ({ fullName })),
        setIsVerified: (isVerified) => set(() => ({ isVerified })),
        setUserUuid: (userUuid) => set(() => ({ userUuid })),

        reset: () =>
          set(() => {
            return {
              isConfirmedEmail: false,
              email: '',
              captchToken: '',
              verificationCode: '',
              errors: [],
              isCodeLink: false,
              userUuid: null,
            };
          }),
      }),
      {
        name: 'auth::storage',
      },
    ),
  ),
);
