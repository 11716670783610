import React from 'react';

import RadioButton from './RadioButton';

interface RadioGroupProps {
  options: { label: string; value: string }[];
  selectedOption: string;
  onChange: (value: string) => void;
  className?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  selectedOption,
  onChange,
  className,
}) => {
  const handleOptionChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className={className}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          label={option.label}
          value={option.value}
          checked={selectedOption === option.value}
          onChange={handleOptionChange}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
