import { TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { cn } from '@/utils';

interface IDeletePopupProps {
  onCancel: () => void;
  onDeleteFavorite: () => void;
  className?: string;
}
export const DeletePopup: FC<IDeletePopupProps> = ({
  onCancel,
  onDeleteFavorite,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'absolute top-6 sm:top-0 sm:right-[140px] h-36 w-[280px] rounded-xl bg-white px-5 py-4 shadow-2xl z-50',
        className,
      )}
    >
      <div className="flex justify-between">
        <p className="font-semibold text-darkColor">
          {t('favorites.delete_favourites')}
        </p>
      </div>
      <div className="mt-3 flex justify-between">
        <button
          className="w-max rounded-lg bg-[#EBF0FF] px-3 py-2 font-semibold text-blueColor hover:bg-[#EBF0FF]/80 hover:text-blueColor/60"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCancel();
          }}
          type="button"
        >
          {t('global.cancel')}
        </button>
        <button
          className="btn-blue-states flex w-max content-center items-center justify-center gap-2 rounded-lg bg-blueColor px-3 py-2 font-semibold"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeleteFavorite();
          }}
          type="button"
        >
          <TrashIcon className="h-5 w-5 text-white" />
          <p className="text-white">{t('global.delete')}</p>
        </button>
      </div>
    </div>
  );
};
