import * as Tooltip from '@radix-ui/react-tooltip';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React, { useState } from 'react';

import { Icon } from '@/components';
import { cn } from '@/utils';

interface ShareButtonProps {
  value: string;
  className?: string;
}

export const ShareButton: FC<ShareButtonProps> = ({ value, className }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const router = useRouter();

  const handleShare = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (router.locale === router.defaultLocale) {
        await navigator.clipboard.writeText(
          `${window.location.origin}${value}`,
        );
      } else {
        await navigator.clipboard.writeText(
          `${window.location.origin}/${router.locale}${value}`,
        );
      }
      setIsTooltipVisible(true);
      setTimeout(() => {
        setIsTooltipVisible(false);
      }, 1500);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isTooltipVisible}>
        <Tooltip.Trigger asChild>
          <button
            type="button"
            className={cn('size-8', className)}
            onClick={handleShare}
          >
            <Icon
              name="share"
              className="size-full stroke-transparent text-grey-400"
            />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="left" className="z-50">
            <div className="flex items-center gap-1 rounded bg-blue-900 px-3 py-[7px] text-white">
              <Tooltip.Arrow />
              <Icon
                name="check"
                className="stroke-transparent text-green-500"
              />
              <span className="body3 text-white">Link is copied</span>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
