import { type FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useSearchStore } from '@/store';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { cn } from '@/utils';

import { LazyLoaded } from '../LazyLoaded';
import { Icon } from '../Sprite';
import { CurrencySelect, SearchButton, TermsSelect } from './parts';

interface ISearchBarProps {
  onSubmit: () => void;
  className?: string;
  isScrolling?: boolean;
  isHomePage?: boolean;
  productType?: 'savings account' | 'fixed term deposit';
}

const Divider = () => (
  <div className="relative min-h-0">
    <div className="absolute inset-x-0 -bottom-4 hidden h-8 w-[1px] rounded-[1px] bg-grey-200 md:flex" />
  </div>
);

export const StatisticsSearchBar: FC<ISearchBarProps> = ({
  className,
  isScrolling,
  isHomePage = false,
  onSubmit = () => {},
  productType,
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 728px)',
  });

  const { terms, setTerms } = useSearchStore((state) => state);

  const handleTermsChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  return (
    <div>
      <div
        className={cn(
          'min-h-[64px] whitespace-nowrap hidden flex-col items-center md:gap-1 xl:gap-2 w-full rounded-xl bg-white py-6 md:px-0 md:py-0 font-medium', // h-[420px] py-6
          'md:flex md:flex-row md:rounded-full md:border-[1.5px] md:border-[#000000]/10 md:shadow-none', // md:items-center
          className,
        )}
      >
        <div className="ml-3 hidden w-8 shrink-0 items-center justify-center lg:flex">
          <Icon
            name="Search"
            className="hidden max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px] stroke-2 text-transparent md:flex"
          />
        </div>
        {isDesktop && (
          <LazyLoaded>
            {productType === 'fixed term deposit' && (
              <>
                <TermsSelect
                  terms={terms}
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[45%] lg:w-[35%]"
                  dropdownClassname={isHomePage ? 'w-[236px]' : 'w-[247px]'}
                  onChange={handleTermsChange}
                  buttonClassName="w-full sm:w-full md:w-full lg:w-full xl:w-full"
                />
                <Divider />
              </>
            )}
            <CurrencySelect
              isScrolling={isScrolling}
              className="w-full shrink"
              dropdownClassname={isHomePage ? 'w-[139px]' : 'w-[145px]'}
            >
              <SearchButton
                onClick={onSubmit}
                className="w-auto shrink-0 cursor-pointer self-center focus-visible:rounded-full focus-visible:outline-blue-550"
                searchType={
                  productType === 'savings account' ? 'savings' : 'deposits'
                }
              />
            </CurrencySelect>
          </LazyLoaded>
        )}
      </div>
    </div>
  );
};
