import type { AnalyticsData } from '@pickthebank/api-sdk';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAnalyticsStore {
  setAnalytics: (analytics: any) => void;
}

interface IAnalyticsStoreState {
  analytics?: AnalyticsData;
}

let initialState: IAnalyticsStoreState = {
  analytics: undefined,
};

if (typeof window !== 'undefined') {
  const storedAnalytics = localStorage.getItem('analytics::storage');
  initialState = {
    analytics: storedAnalytics
      ? JSON.parse(storedAnalytics)?.state?.analytics
      : undefined,
  };
}

export const useAnalyticsStore = create<
  IAnalyticsStore & IAnalyticsStoreState
>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setAnalytics: (analytics: any) => set({ analytics }),
      }),
      {
        name: 'analytics::storage',
      },
    ),
  ),
);
