import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Icon } from '@/components';
import { cn } from '@/utils';
import { isMobileApp } from '@/utils/isMobileApp';

export interface Breadcrumb {
  title: string;
  href: string;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 728px)',
  });

  if (isMobileApp()) {
    return null;
  }

  return (
    <nav
      aria-label="Breadcrumb"
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={cn('flex items-center gap-3', className)}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <li
            key={breadcrumb.title}
            className="hidden last:flex md:flex"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Link
              href={
                isMobile
                  ? isMobileApp()
                    ? '/multibanking-platform'
                    : breadcrumbs[index - 1]?.href || '/'
                  : breadcrumb.href
              }
              className="flex items-center gap-3"
              itemProp="item"
            >
              <Icon
                name="chevron"
                className="size-4 -rotate-90 text-grey-400 md:hidden"
              />
              <span className="body3 text-grey-800" itemProp="name">
                {t(breadcrumb.title)}
              </span>
              {index !== breadcrumbs.length - 1 && (
                <Icon
                  name="chevron"
                  className="size-4 rotate-90 text-grey-400"
                />
              )}
            </Link>
            <meta itemProp="position" content={`${index + 1}`} />
          </li>
        );
      })}
    </nav>
  );
};
