import annualFees from '@/constants/annualFees.json';

export const annualFeeKeys = annualFees as any;

export function findAnnualFeeKey(translation: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in annualFeeKeys) {
    if (Object.prototype.hasOwnProperty.call(annualFeeKeys, key)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const lang in annualFeeKeys[key]) {
        if (
          Object.prototype.hasOwnProperty.call(annualFeeKeys[key], lang) &&
          annualFeeKeys[key][lang] === translation
        ) {
          return key;
        }
      }
    }
  }
  return '';
}
