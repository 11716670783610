import * as Dialog from '@radix-ui/react-dialog';
import type { FC } from 'react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileModals } from '@/components/MobileModal';
import { Icon } from '@/components/Sprite';
import { useSearchStore } from '@/store';
import { CASHBACK_OPTIONS } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IModalCountryProps {
  className?: string;
}

export const CashbackModal: FC<IModalCountryProps> = ({ className }) => {
  const { t } = useTranslation();
  const selectedCashback = useSearchStore((state) => state.cashback);
  const setSelectedCashback = useSearchStore((state) => state.setCashback);

  const handleCountryItemClick = (cashback: any) => {
    if (selectedCashback.value === cashback.value) return;
    setSelectedCashback(cashback);
  };

  const sendAnalyticEvent = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.openCashback,
      category: AnalyticsCategory.deposit,
      label: 'openCashback',
    });
  };

  return (
    <MobileModals
      className={className}
      zMoreIndex
      title={`${t('global.cashback')}`}
      trigger={
        <button
          className={cn('search-input w-full')}
          onClick={sendAnalyticEvent}
          type="button"
        >
          <p className="caption whitespace-nowrap text-left">
            {t('global.cashback')}
          </p>
          <div className={cn('flex w-full items-end justify-between')}>
            <div className="body2-bold flex min-w-0 grow-[1] items-center justify-start gap-2 rounded-md font-bold text-blue-900">
              <p className="truncate text-[15px]">
                {t(
                  `savings-accounts.annual-fee.filter-${selectedCashback.key.toLowerCase()}`,
                )}
              </p>
            </div>
            <Icon
              className="min-w-0 shrink-0 stroke-2 text-transparent"
              name="arrowDownIcon"
            />
          </div>
        </button>
      }
    >
      <div className="flex flex-col justify-items-stretch gap-y-2">
        {CASHBACK_OPTIONS.map((c) => (
          <Fragment key={c.key}>
            <Dialog.Close asChild>
              <button
                onClick={() => handleCountryItemClick(c)}
                className={cn(
                  selectedCashback.value === c.value
                    ? 'rounded-full'
                    : 'text-grayColor',
                  'flex w-full content-center items-center justify-start gap-2 py-3 text-[15px] font-bold',
                )}
                type="button"
              >
                <span
                  className={cn(
                    'text-blue-900 tracking-[0.2px]',
                    selectedCashback.value === c.value ? 'body2-bold' : 'body2',
                  )}
                >
                  {t(
                    `savings-accounts.annual-fee.filter-${c.key.toLowerCase()}`,
                  )}
                </span>
              </button>
            </Dialog.Close>
            <div className="h-[1px] w-full bg-gray-200" />
          </Fragment>
        ))}
      </div>
    </MobileModals>
  );
};
