import { Trans, useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import React from 'react';

import { AnalyticsEvent } from '@/types/analytics';
import { analyticServices } from '@/utils';

import { Icon } from '../Sprite';

export const DepositProtection = () => {
  const { t } = useTranslation();

  const handleLearnMore = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickSecurityDeposit,
      label: 'clickSecurityDeposit',
    });
  };
  return (
    <Link
      href={t('faq_page.question_5.link')}
      className="shadow-far mt-6 flex w-full cursor-pointer content-center items-center justify-between rounded-xl bg-white p-4 text-left md:mb-[6px] md:mt-4 md:max-h-[72px] md:min-h-[72px] md:px-6 md:py-4 xl:max-h-[80px] xl:min-h-[80px]"
      onClick={handleLearnMore}
    >
      <div className="flex content-center md:items-center">
        <div className="mr-1 md:mr-2">
          <Icon
            name="cardProtected"
            className="h-6 w-6 stroke-transparent text-blue-900 md:h-12 md:w-12"
          />
        </div>
        <div>
          <h6 className="tracking-[0.2px] text-blue-900">
            {t('protected.title')}
          </h6>
          <p className="body3 tracking-[0.2px] text-grey-800">
            <Trans
              i18nKey="protected.subtitle"
              t={t}
              components={{
                boldText: <span className="body3-bold text-blue-900" />,
              }}
            />
          </p>
        </div>
      </div>
      <div className="hidden py-1 pl-3 pr-1 md:flex">
        <Icon
          name="shevronRight"
          className="max-h-[36px] min-h-[36px] min-w-[28px] max-w-[28px]"
        />
      </div>
    </Link>
  );
};
