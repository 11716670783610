export function transformNumber(num: number): string | number {
  let division: number;
  let result: string | number;
  let postfix: string;

  if (num >= 1000000) {
    division = num / 1000000;
    postfix = 'm';
  } else if (num >= 1000) {
    division = num / 1000;
    postfix = 'k';
  } else {
    return num;
  }

  // Convert to integer if decimal part is zero
  if (division % 1 === 0) {
    result = parseInt(division.toFixed(1), 10) + postfix;
  } else {
    result = division.toFixed(1) + postfix;
  }

  return result;
}
export function transformMonths(months: number): {
  value: number;
  unit: string;
} {
  if (months > 18 && months % 12 === 0) {
    const years = Math.floor(months / 12);
    return { value: years, unit: 'YEARS' };
  }
  if (months === 1) return { value: months, unit: 'MONTH' };
  return { value: months, unit: 'MONTHS' };
}

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};
