import type { FC, KeyboardEvent } from 'react';
import React, { useRef, useState } from 'react';

import { useAuthStore } from '@/store';
import { cn } from '@/utils';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

interface ReactCodeInputProps {
  onChange?: (value: string) => void;
  onComplete: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
}
const FIELDS_COUNT = 6;

export const VerifyInput: FC<ReactCodeInputProps> = ({
  disabled = false,
  required = false,
  onChange,
  onComplete,
}) => {
  const { verificationCode, errors, setError } = useAuthStore((state) => state);
  const code = verificationCode.split('');

  const [values, setValues] = useState<string[]>(
    code.length
      ? code
          .slice(0, FIELDS_COUNT)
          .concat(Array(FIELDS_COUNT - code.length).fill(''))
      : Array(FIELDS_COUNT).fill(''),
  );

  const [autoFocusIndex] = useState<number>(
    code.length >= FIELDS_COUNT ? 0 : code.length,
  );

  const inputRefs: any[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const id = useRef<number>(+new Date());

  const handleKeys = useRef<boolean[]>(Array(FIELDS_COUNT).fill(false));

  const triggerChange = (newValues: string[]) => {
    const val = newValues.join('');
    if (onChange) onChange(val);

    if (onComplete && val.length >= FIELDS_COUNT) {
      onComplete(val);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const index = parseInt((e.target as HTMLInputElement).dataset.id!, 10);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = inputRefs[prevIndex];
    const next = inputRefs[nextIndex];

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const newValues = [...values];

        if (values[index]) {
          newValues[index] = '';
          setValues(newValues);
          triggerChange(newValues);
        } else if (prev) {
          newValues[prevIndex] = '';
          prev.current.focus();
          setValues(newValues);
          triggerChange(newValues);
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        handleKeys.current[index] = true;
        break;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.dataset.id!, 10);

    e.target.value = e.target.value.replace(/[^\d]/gi, '');

    if (e.target.value === '' || !e.target.validity.valid) {
      return;
    }
    if (errors.length > 0) {
      setError([]);
    }
    let next: any;
    const { value } = e.target;
    const newValues = [...values];

    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= FIELDS_COUNT) {
        nextIndex = FIELDS_COUNT - 1;
      }
      next = inputRefs[nextIndex];
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < FIELDS_COUNT) {
          newValues[cursor] = item;
        }
      });
      setValues(newValues);
    } else {
      next = inputRefs[index + 1];
      newValues[index] = value;
      setValues(newValues);
    }

    if (next && next.current) {
      next.current.focus();
      next.current.select();
    }

    triggerChange(newValues);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex justify-center gap-1">
        {values.map((value, index) => (
          <input
            type="tel"
            pattern="[0-9]*"
            key={`${id.current}-${index}`}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={index === autoFocusIndex}
            data-id={index}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={inputRefs[index]}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            required={required}
            className={cn(
              'flex h-[56px] w-10 font-semibold rounded-xl border-[1.25px] bg-white p-2 text-center text-2xl text-gray-800 remove-arrow',
              errors.length > 0 && 'border-red-200',
            )}
          />
        ))}
      </div>
      {errors &&
        errors.length > 0 &&
        errors.map((err) => (
          <span
            key={err}
            className="mt-[2px] text-ellipsis text-center font-inter text-[11px] font-medium leading-[16px] tracking-[0.2px] text-red-500"
          >
            {err}
          </span>
        ))}
    </div>
  );
};
