import { useEffect } from 'react';

// * Fix for the Safari viewport height calculation

// * see: https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97

export const useDocumentHeight = () => {
  useEffect(() => {
    const handleDocumentHeight = () => {
      const doc = document.documentElement;

      doc.style.setProperty('--vh', `${window.innerHeight}px`);
    };

    handleDocumentHeight();

    window.addEventListener('resize', handleDocumentHeight);

    return () => {
      window.removeEventListener('resize', handleDocumentHeight);
    };
  }, []);
};
