import Austria from '@/public/assets/countries/Austria.svg';
import Belgium from '@/public/assets/countries/Belgium.svg';
import Bulgaria from '@/public/assets/countries/Bulgaria.svg';
import Croatia from '@/public/assets/countries/Croatia.svg';
import Cyprus from '@/public/assets/countries/Cyprus.svg';
import CzechRepublic from '@/public/assets/countries/Czech Republic.svg';
import Denmark from '@/public/assets/countries/Denmark.svg';
import ENG from '@/public/assets/countries/ENG.svg';
import Estonia from '@/public/assets/countries/Estonia.svg';
import Finland from '@/public/assets/countries/Finland.svg';
import France from '@/public/assets/countries/France.svg';
import Germany from '@/public/assets/countries/Germany.svg';
import Greece from '@/public/assets/countries/Greece.svg';
import Hungary from '@/public/assets/countries/Hungary.svg';
import Ireland from '@/public/assets/countries/Ireland.svg';
import Italy from '@/public/assets/countries/Italy.svg';
import Latvia from '@/public/assets/countries/Latvia.svg';
import Lithuania from '@/public/assets/countries/Lithuania.svg';
import Luxembourg from '@/public/assets/countries/Luxembourg.svg';
import Malta from '@/public/assets/countries/Malta.svg';
import Netherlands from '@/public/assets/countries/Netherlands.svg';
import Poland from '@/public/assets/countries/Poland.svg';
import Portugal from '@/public/assets/countries/Portugal.svg';
import Romania from '@/public/assets/countries/Romania.svg';
import Slovakia from '@/public/assets/countries/Slovakia.svg';
import Slovenia from '@/public/assets/countries/Slovenia.svg';
import Spain from '@/public/assets/countries/Spain.svg';
import Sweden from '@/public/assets/countries/Sweden.svg';
import Switzerland from '@/public/assets/countries/Switzerland.svg';

export const LIST_COUNTRIES = [
  {
    name: 'Austria',
    flag: Austria,
    iso2: 'AT',
    iso3: 'AUT',
    currency: 'EUR',
  },
  {
    name: 'Belgium',
    flag: Belgium,
    iso2: 'BE',
    iso3: 'BEL',
    currency: 'EUR',
  },
  {
    name: 'Bulgaria',
    flag: Bulgaria,
    iso2: 'BG',
    iso3: 'BGR',
    currency: 'BGN',
  },
  {
    name: 'Croatia',
    flag: Croatia,
    iso2: 'HR',
    iso3: 'HRV',
    currency: 'HRK',
  },
  {
    name: 'Cyprus',
    flag: Cyprus,
    iso2: 'CY',
    iso3: 'CYP',
    currency: 'EUR',
  },
  {
    name: 'Czech Republic',
    flag: CzechRepublic,
    iso2: 'CZ',
    iso3: 'CZE',
    currency: 'CZK',
  },
  {
    name: 'Denmark',
    flag: Denmark,
    iso2: 'DK',
    iso3: 'DNK',
    currency: 'DKK',
  },
  {
    name: 'Estonia',
    flag: Estonia,
    iso2: 'EE',
    iso3: 'EST',
    currency: 'EUR',
  },
  {
    name: 'Finland',
    flag: Finland,
    iso2: 'FI',
    iso3: 'FIN',
    currency: 'EUR',
  },
  {
    name: 'France',
    flag: France,
    iso2: 'FR',
    iso3: 'FRA',
    currency: 'EUR',
  },
  {
    name: 'Germany',
    flag: Germany,
    iso2: 'DE',
    iso3: 'DEU',
    currency: 'EUR',
  },
  {
    name: 'Greece',
    flag: Greece,
    iso2: 'GR',
    iso3: 'GRC',
    currency: 'EUR',
  },
  {
    name: 'Hungary',
    flag: Hungary,
    iso2: 'HU',
    iso3: 'HUN',
    currency: 'HUF',
  },
  {
    name: 'Ireland',
    flag: Ireland,
    iso2: 'IE',
    iso3: 'IRL',
    currency: 'EUR',
  },
  {
    name: 'Italy',
    flag: Italy,
    iso2: 'IT',
    iso3: 'ITA',
    currency: 'EUR',
  },
  {
    name: 'Latvia',
    flag: Latvia,
    iso2: 'LV',
    iso3: 'LVA',
    currency: 'EUR',
  },
  {
    name: 'Lithuania',
    flag: Lithuania,
    iso2: 'LT',
    iso3: 'LTU',
    currency: 'EUR',
  },
  {
    name: 'Luxembourg',
    flag: Luxembourg,
    iso2: 'LU',
    iso3: 'LUX',
    currency: 'EUR',
  },
  {
    name: 'Malta',
    flag: Malta,
    iso2: 'MT',
    iso3: 'MLT',
    currency: 'EUR',
  },
  {
    name: 'Netherlands',
    flag: Netherlands,
    iso2: 'NL',
    iso3: 'NLD',
    currency: 'EUR',
  },
  {
    name: 'Poland',
    flag: Poland,
    iso2: 'PL',
    iso3: 'POL',
    currency: 'PLN',
  },
  {
    name: 'Portugal',
    flag: Portugal,
    iso2: 'PT',
    iso3: 'PRT',
    currency: 'EUR',
  },
  {
    name: 'Romania',
    flag: Romania,
    iso2: 'RO',
    iso3: 'ROU',
    currency: 'RON',
  },
  {
    name: 'Slovakia',
    flag: Slovakia,
    iso2: 'SK',
    iso3: 'SVK',
    currency: 'EUR',
  },
  {
    name: 'Slovenia',
    flag: Slovenia,
    iso2: 'SI',
    iso3: 'SVN',
    currency: 'EUR',
  },
  {
    name: 'Spain',
    flag: Spain,
    iso2: 'ES',
    iso3: 'ESP',
    currency: 'EUR',
  },
  {
    name: 'Sweden',
    flag: Sweden,
    iso2: 'SE',
    iso3: 'SWE',
    currency: 'SEK',
  },
  {
    name: 'Switzerland',
    flag: Switzerland,
    iso2: 'CH',
    iso3: 'CHE',
    currency: 'CHF',
  },
  {
    name: 'United Kingdom',
    flag: ENG,
    iso2: 'GB',
    iso3: 'GBR',
    currency: 'GBP',
  },
  {
    name: 'Norway',
    flag: ENG,
    iso2: 'NO',
    iso3: 'NOR',
    currency: 'NOK',
  },
];

export const getFlag = (code: string) => {
  const flag = LIST_COUNTRIES.find(
    (item) => item.iso2 === code || item.iso3 === code,
  );

  return flag ? flag.flag : '';
};

export const getFlagEmoji = (countryCode: any) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char: any) =>
      String.fromCodePoint(127397 + char.charCodeAt()),
    );
};
