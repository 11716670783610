import type { FC } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useDepositSelect } from '@/components/SearchBar/parts/hooks';
import { SelectCheckbox } from '@/components/SearchBar/parts/SelectCheckbox/SelectCheckbox';
import type { IDepositOperation } from '@/store/storeSearch.types';

interface IModalDepositInnerProps {
  types: IDepositOperation[];
  is_partner: boolean;
  onChange: (types: IDepositOperation[], isPartner: boolean) => void;
}

export const DepositModalInner: FC<IModalDepositInnerProps> = ({
  types: _types,
  is_partner,
  onChange,
}) => {
  const { t } = useTranslation();

  const {
    // isPartner,
    types,
    allTypesIsActive,
    // toggleIsPartner,
    deselectAllTypes,
    toggleOperation,
    handleKeyDown,
    handleClick,
  } = useDepositSelect(_types, is_partner, onChange);

  return (
    <div className="-mx-3 flex flex-col gap-2">
      <SelectCheckbox
        isChecked={allTypesIsActive}
        onClick={(e) => handleClick(e, deselectAllTypes)}
        onKeyDown={(e) => handleKeyDown(e, deselectAllTypes)}
      >
        {t(`database.deposit type.all`)}
      </SelectCheckbox>
      {/* <SelectCheckbox */}
      {/*  isChecked={isPartner} */}
      {/*  onClick={(e) => handleClick(e, toggleIsPartner)} */}
      {/*  onKeyDown={(e) => handleKeyDown(e, toggleIsPartner)} */}
      {/* > */}
      {/*  {t(`database.deposit type.${IS_PARTNER_KEY}`)} */}
      {/* </SelectCheckbox> */}
      <div className="mx-3 h-[1px] self-stretch border-t border-gray-200" />
      {types.map((operation, index) => (
        <Fragment key={operation.key}>
          <SelectCheckbox
            isChecked={operation.selected}
            onClick={(e) => handleClick(e, () => toggleOperation(index))}
            onKeyDown={(e) => handleKeyDown(e, () => toggleOperation(index))}
          >
            {t(`database.deposit type.${operation.key.toLowerCase()}`)}
          </SelectCheckbox>
        </Fragment>
      ))}
    </div>
  );
};
