import { COUNTRIES_PATHS } from '@/utils/countries';

export const getFixedDepositsBreadcrumbs = () => {
  return [
    { title: 'header.home', href: '/' },
    { title: 'header.all_deposits', href: '/fixed-deposits' },
  ];
};

export const getSavingsAccountsBreadcrumbs = () => {
  return [
    { title: 'header.home', href: '/' },
    { title: 'header.savings-accounts', href: '/savings-accounts' },
  ];
};

export const getAboutUsBreadcrumbs = () => {
  return [{ title: 'header.home', href: '/' }];
};

export const getBlogBreadcrumbs = () => {
  return [
    { title: 'header.home', href: '/' },
    { title: 'header.blog', href: '/blog' },
  ];
};

export const getPortfolioArchiveBreadcrumbs = () => {
  return [
    { title: 'portfolio.breadcrumbs.portfolio', href: '/profile/portfolio' },
    {
      title: 'portfolio.breadcrumbs.archive',
      href: '/profile/portfolio/archive',
    },
  ];
};

export const getBanksBreadcrumbs = (
  country: string | undefined,
  locale = 'en',
) => {
  const arr = [
    { title: 'header.home', href: '/' },
    { title: 'header.banks', href: '/banks' },
  ];
  if (country) {
    arr.push({
      title: `database.countries.${country}`,
      href: `/banks/${COUNTRIES_PATHS[country][locale]}`,
    });
  }
  return arr;
};

export const getPlatformBreadcrumbs = () => {
  return [
    { title: 'header.home', href: '/' },
    { title: 'global.platform', href: '/multibanking-platform' },
  ];
};
