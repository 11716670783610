import { useTranslation } from 'next-i18next';
import type { FC, MouseEventHandler } from 'react';

import { Icon } from '@/components/Sprite';
import { useSearchStore } from '@/store';
import { cn } from '@/utils';

export interface ISearchButtonProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  searchType?: 'deposits' | 'savings';
}

export const SearchButton: FC<ISearchButtonProps> = ({
  onClick,
  className,
  searchType = 'deposits',
}) => {
  const { t } = useTranslation();
  const { isEdited, isLoading } = useSearchStore((state) => state);

  return (
    <button
      className={cn(
        'hover:bg-blue-550 flex w-full content-center items-center justify-center rounded-[32px] bg-blue-500 py-[13px] mt-0 max-h-[42px] min-h-[42px] min-w-[42px] lg:max-h-[44px] lg:min-h-[44px] lg:min-w-[136px] lg:max-w-[150px] lg:px-6',

        className,
      )}
      onClick={onClick}
      type="button"
    >
      {isLoading ? (
        // TODO: check me, I removed the "loading-container" class
        <div className="size-[18px]">
          <div className="loading-spinner-small p-1" />
        </div>
      ) : (
        <>
          <p className="body2-bold text-[13px] leading-[18px] text-white sm:flex md:hidden lg:flex">
            {searchType === 'deposits'
              ? t('deposits.find_deposits_button')
              : t('global.show-results')}
          </p>
          <Icon
            name="arrowRightWhite"
            className={cn(
              'hidden h-6 w-6 lg:hidden text-transparent',
              isEdited ? 'md:flex' : 'md:hidden',
            )}
          />
          <Icon
            name="SearchWhiteIcon"
            className={cn(
              'stroke-2 hidden text-transparent max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] lg:hidden',
              isEdited ? 'md:hidden' : 'md:flex',
            )}
          />
        </>
      )}
    </button>
  );
};
