/* eslint-disable react/no-unused-prop-types */
import * as Dialog from '@radix-ui/react-dialog';
import { type FC, type ReactNode, useState } from 'react';

import { cn } from '@/utils';

import { Icon } from '../Sprite';

interface IModalProps {
  className?: string;
  trigger?: ReactNode;
  title: string;
  children?: ReactNode;
  zMoreIndex?: boolean;
  visible?: boolean;
  buttonTitle?: string;
  isButtonSelect?: boolean;
  isButtonSearch?: ReactNode;
  onClose?: (isSubmitted?: boolean) => void;
}

export const MobileModals: FC<IModalProps> = ({
  className,
  trigger,
  children,
  title,
  zMoreIndex,
  buttonTitle,
  isButtonSelect,
  isButtonSearch,
  onClose = () => {},
}) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      <Dialog.Root onOpenChange={setIsOpened}>
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay
            className={cn(
              'fixed inset-0 z-[52] bg-white text-blue-900 md:hidden',
              zMoreIndex && 'z-[54]',
            )}
          />
          <Dialog.Content
            className={cn(
              'fixed inset-0 z-[53] h-[100vh] text-blue-900',
              zMoreIndex && 'z-[55]',
            )}
          >
            <Dialog.Title>
              <div className="flex items-center justify-center py-[18px]">
                <Dialog.Close asChild>
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="absolute left-6"
                  >
                    <Icon
                      name="shewronLeft"
                      className="size-8 text-transparent"
                    />
                  </button>
                </Dialog.Close>
                <p className="body1 text-center text-[17px] leading-6 tracking-[0.1px] text-blue-900">
                  {title}
                </p>
              </div>
            </Dialog.Title>
            <div className="h-[1px] w-full shrink-0 bg-gray-200" />
            <Dialog.Description asChild>
              <div
                className={cn(
                  'h-[calc(100vh-90px)] overflow-y-auto',
                  (isButtonSearch || isButtonSelect) &&
                    'h-[calc(100dvh-130px)] safe-area',
                )}
              >
                <div className="px-5 pb-[13px] pt-2">
                  {isOpened && children}
                </div>
              </div>
            </Dialog.Description>
            {isButtonSelect && (
              <Dialog.Close asChild>
                <div className="sticky bottom-0 z-50 flex w-full overflow-y-hidden bg-white px-5 py-[13px]">
                  <button
                    type="button"
                    className="w-full rounded-[32px] bg-blue-100 px-6 py-[13px]"
                    onClick={() => onClose(true)}
                  >
                    <p className="body3-bold text-[13px] font-bold leading-[18px] tracking-[0.1px] hover:text-blue-550">
                      {buttonTitle}
                    </p>
                  </button>
                </div>
              </Dialog.Close>
            )}
            <Dialog.Close asChild>{isButtonSearch}</Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
