import { forwardRef } from 'react';

import { Icon } from '@/components/Sprite';
import { cn } from '@/utils';

export interface SelectCheckboxProps {
  isChecked: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

export const SelectCheckbox = forwardRef<
  HTMLButtonElement,
  SelectCheckboxProps
>(({ isChecked, children, disabled, className, onClick, onKeyDown }, ref) => {
  return (
    <button
      ref={ref}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onClick={onClick}
      className={cn(
        'group flex w-full items-start justify-start gap-2 px-5 py-3 text-left text-[15px] leading-[20px] focus-visible:outline-gray-100',
        isChecked && 'font-bold',
        className,
      )}
      type="button"
    >
      {isChecked ? (
        <Icon name="CheckedTip" className="h-5 w-5 shrink-0" />
      ) : (
        <div className="h-5 w-5 shrink-0 rounded-[6px] border-2 border-gray-200 bg-white group-hover:border-blue-200" />
      )}

      <span className="min-w-0 grow-[1] whitespace-break-spaces first-letter:uppercase">
        {children}
      </span>
    </button>
  );
});

SelectCheckbox.displayName = 'SelectCheckbox';
