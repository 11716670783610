import type { KeyboardEvent, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import type { IDepositOperation } from '@/store/storeSearch.types';

export const useDepositSelect = (
  _types: IDepositOperation[],
  is_partner: boolean,
  onChange: (types: IDepositOperation[], isPartner: boolean) => void,
) => {
  const [changeTrigger, setChangeTrigger] = useState(0);
  const [types, _setTypes] = useState<IDepositOperation[]>([]);
  const [activeTypes, setActiveTypes] = useState<IDepositOperation[]>([]);
  const [isPartner, setIsPartner] = useState<boolean>(is_partner);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const allTypesIsActive = activeTypes.length === 0;

  function updateChangeTrigger() {
    setChangeTrigger((v) => v + 1);
  }

  function setTypes(__types: IDepositOperation[]) {
    let newTypes = [...__types];
    let newActiveTypes = newTypes.filter((o) => o.selected);

    // 1. if activeTypes exists, then disable the isPartner
    if (isPartner && newActiveTypes.length > 0) {
      setIsPartner(false);
    }

    // 2. if all types are selected, then it's fair to show only the "All" checkbox selected
    if (newTypes.length === newActiveTypes.length) {
      const arr = types.map((op) => ({
        ...op,
        selected: false,
      }));

      newTypes = arr;
      newActiveTypes = [];
    }

    _setTypes(newTypes);
    setActiveTypes(newActiveTypes);
    updateChangeTrigger();
  }

  const deselectAllTypes = () => {
    const arr = types.map((op) => ({
      ...op,
      selected: false,
    }));

    _setTypes(arr);
    setActiveTypes([]);
    updateChangeTrigger();
  };

  const toggleIsPartner = () => {
    setIsPartner((val) => !val);

    deselectAllTypes();
  };

  const toggleOperation = (index: number) => {
    const arr = [...types];
    arr[index]!.selected = !arr[index]!.selected;

    setTypes(arr);
  };

  function handleKeyDown(e: KeyboardEvent<HTMLButtonElement>, cb: () => void) {
    if ([' ', 'Spacebar', 'Enter'].includes(e.key)) {
      e.preventDefault();

      cb();
    }
  }

  function handleClick(e: MouseEvent<HTMLButtonElement>, cb: () => void) {
    e.preventDefault();

    cb();
  }

  // For input changes
  useEffect(() => {
    let newTypes = [..._types];
    let newActiveTypes = newTypes.filter((o) => o.selected);

    // If all types are selected, then it's fair to show only the "All" checkbox selected
    if (newTypes.length === newActiveTypes.length) {
      const arr = types.map((op) => ({
        ...op,
        selected: false,
      }));

      newTypes = arr;
      newActiveTypes = [];
    }

    _setTypes(newTypes);
    setActiveTypes(newActiveTypes);
  }, [_types]);

  // For output changes
  useEffect(() => {
    // Prevents triggering when switch between mob. and desktop modes
    if (changeTrigger === 0) return;

    onChange(types, isPartner);
  }, [changeTrigger]);

  return {
    types,
    isPartner,
    overlayVisible,
    activeTypes,
    allTypesIsActive,
    setOverlayVisible,
    toggleIsPartner,
    deselectAllTypes,
    toggleOperation,
    handleKeyDown,
    handleClick,
  };
};
