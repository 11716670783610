import { useFavoritesStore } from '@/store';
import type { IBankDepositCard } from '@/types';

// TODO: DEPRACETED: This scenario needs to be updated once the authorization scenario is stable

export interface FavouriteDeposit {
  productIndex: number | string;
  productType: 'savings account' | 'fixed term deposit' | 'demand account';
}

function useFavouriteDeposits() {
  const { favourites, setFavorites } = useFavoritesStore((state) => state);

  const checkIsFavorite = (bank: IBankDepositCard) => {
    if (!favourites || favourites.length === 0) {
      return false;
    }
    return !!favourites.find((item) => item.productIndex === bank.productIndex);
  };

  const handleFavourite = (bank: IBankDepositCard) => {
    const isFavourite = checkIsFavorite(bank);

    if (isFavourite) {
      const filteredFavourite = favourites.filter(
        (item) => item.productIndex !== bank.productIndex,
      );

      setFavorites(filteredFavourite);
    } else {
      const favouriteItem = {
        productIndex: bank.productIndex,
        productType: bank.productType.toLowerCase() as
          | 'savings account'
          | 'fixed term deposit'
          | 'demand account',
      };
      setFavorites([...favourites, favouriteItem]);
    }
  };

  return {
    favouriteArray: favourites,
    handleFavourite,
    checkIsFavorite,
  };
}

export default useFavouriteDeposits;
