import * as Dialog from '@radix-ui/react-dialog';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import { type FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileModals } from '@/components/MobileModal';
import { Icon } from '@/components/Sprite';
import { useSearchStore } from '@/store';
import { CURRENCY_IMAGES } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IModalCurrencyProps {
  className?: string;
  currencySearchModal?: boolean;
  shouldShowCurrencyCode?: boolean;
}

export const CurrencyModal: FC<IModalCurrencyProps> = ({
  className,
  currencySearchModal,
  shouldShowCurrencyCode,
}) => {
  const { t } = useTranslation();
  const selectedCurrency = useSearchStore((state) => state.currency);
  const setSelectedCurrency = useSearchStore((state) => state.setCurrency);

  const handleCurrencyItemClick = (currency: string) => {
    if (selectedCurrency === currency) return;
    setSelectedCurrency(currency);
  };

  const sendAnalyticEvent = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.openCurrency,
      category: AnalyticsCategory.deposit,
      label: 'openCurrency',
    });
  };

  return (
    <MobileModals
      className={className}
      zMoreIndex
      title={`${t('deposits.currency')}`}
      trigger={
        <button
          className={cn(
            'search-input w-full border-0 pl-[18px]',
            currencySearchModal && 'pl-[12px] pr-[27px]',
          )}
          type="button"
          onClick={sendAnalyticEvent}
        >
          <p className="caption whitespace-nowrap text-left">
            {t('deposits.currency')}
          </p>
          <div className="flex w-full items-end justify-between">
            <div className="body2-bold flex w-full min-w-0 grow-[1] items-center justify-start gap-2 rounded-md font-bold text-blue-900">
              <Image
                src={CURRENCY_IMAGES[selectedCurrency]!}
                alt="selected currency"
                className="h-auto w-5 rounded-sm"
              />
              {shouldShowCurrencyCode && (
                <span className="uppercase tracking-[0.2px] text-blue-900">
                  {selectedCurrency}
                </span>
              )}
            </div>
            <Icon
              className="min-w-0 shrink-0 stroke-2 text-transparent"
              name="arrowDownIcon"
            />
          </div>
        </button>
      }
    >
      <div className="flex flex-col justify-items-stretch gap-y-2">
        {Object.entries(CURRENCY_IMAGES).map(
          ([currency, currency_image]: [string, StaticImageData], index) => (
            <Fragment key={index}>
              <Dialog.Close asChild>
                <button
                  onClick={() => handleCurrencyItemClick(currency)}
                  className="flex w-full content-center items-center  justify-start gap-2 py-3 text-[15px] font-bold"
                  type="button"
                >
                  {index > 0 && (
                    <Image
                      src={currency_image}
                      alt={currency}
                      className="size-5"
                    />
                  )}
                  {index === 0 && selectedCurrency === currency && (
                    <Image
                      src={currency_image}
                      alt={currency}
                      className="size-5"
                    />
                  )}
                  {index === 0 && selectedCurrency !== currency && (
                    <div className="size-5 shrink-0 rounded-full border-[1px] border-gray-200 bg-white" />
                  )}
                  <span
                    className={cn(
                      'uppercase text-blue-900 tracking-[0.2px]',
                      selectedCurrency === currency ? 'body2-bold' : 'body2',
                    )}
                  >
                    {currency}
                  </span>
                  {index > 0 && selectedCurrency === currency && (
                    <Icon
                      name="Tik"
                      className="ml-auto size-5 text-transparent"
                    />
                  )}
                </button>
              </Dialog.Close>
              <div className="h-[1px] w-full bg-gray-200" />
            </Fragment>
          ),
        )}
      </div>
    </MobileModals>
  );
};
