import * as HoverCard from '@radix-ui/react-hover-card';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { cn } from '@/utils';

import { Icon } from '../Sprite';

export const QuestionMarkTooltip = ({
  content,
  className,
  type = 'primary',
}: {
  content: ReactNode;
  className?: string;
  type?: 'primary' | 'secondary';
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HoverCard.Root open={isOpen} onOpenChange={handleToggle}>
      <HoverCard.Trigger asChild>
        <span
          className="flex cursor-pointer content-center items-center"
          onClick={(e) => {
            e.preventDefault();
            handleToggle();
          }}
        >
          {type === 'primary' ? (
            <Icon
              className="ml-[4px] inline-block size-4 text-transparent"
              name="QuestionMarkIcon"
            />
          ) : (
            <Icon
              className="ml-[4px] inline-block size-4 stroke-transparent text-grey-600"
              name="help"
            />
          )}
        </span>
      </HoverCard.Trigger>

      <HoverCard.Portal>
        <HoverCard.Content
          className={cn(
            'shadow-far z-[60] w-[300px] bg-blue-900 rounded-md p-4 data-[state=open]:transition-all',
            className,
          )}
          sideOffset={5}
          side="bottom"
        >
          <div className="body3 flex flex-col text-blue-50">{content}</div>
          <HoverCard.Arrow className="fill-white" />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
