import cashback from '@/constants/cashback.json';

export const cashbackKeys = cashback as any;

export function findCashbackKey(translation: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in cashbackKeys) {
    if (Object.prototype.hasOwnProperty.call(cashbackKeys, key)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const lang in cashbackKeys[key]) {
        if (
          Object.prototype.hasOwnProperty.call(cashbackKeys[key], lang) &&
          cashbackKeys[key][lang] === translation
        ) {
          return key;
        }
      }
    }
  }
  return '';
}
