import { useTranslation } from 'next-i18next';

import handPng2x from '@/public/assets/pics/clickHand@2x-min.png';
import handWebp2x from '@/public/assets/pics/clickHand@2x-min.webp';
import handPng1x from '@/public/assets/pics/clickHand-min.png';
import handWebp1x from '@/public/assets/pics/clickHand-min.webp';
import comparePng2x from '@/public/assets/pics/compare@2x-min.png';
import compareWebp2x from '@/public/assets/pics/compare@2x-min.webp';
import comparePng1x from '@/public/assets/pics/compare-min.png';
import compareWebp1x from '@/public/assets/pics/compare-min.webp';
import europePng2x from '@/public/assets/pics/europe@2x-min.png';
import europeWebp2x from '@/public/assets/pics/europe@2x-min.webp';
import europePng1x from '@/public/assets/pics/europe-min.png';
import europeWebp1x from '@/public/assets/pics/europe-min.webp';

import { MainTitle } from '../MainTitle';
import { ProtectionSheild } from './parts';

const howItWorksInfo = [
  {
    imgPng: europePng1x,
    imgPng2x: europePng2x,
    imgPngType: 'image/png',
    imgWebp1x: europeWebp1x,
    imgWebp2x: europeWebp2x,
    imgWebpType: 'image/webp',
    header: 'howItWorks.text_1.text_bold',
    text: 'howItWorks.text_1.text',
  },
  {
    imgPng: comparePng1x,
    imgPng2x: comparePng2x,
    imgPngType: 'image/png',
    imgWebp1x: compareWebp1x,
    imgWebp2x: compareWebp2x,
    imgWebpType: 'image/webp',
    header: 'howItWorks.text_2.text_bold',
    text: 'howItWorks.text_2.text',
  },
  {
    imgPng: handPng1x,
    imgPng2x: handPng2x,
    imgPngType: 'image/png',
    imgWebp1x: handWebp1x,
    imgWebp2x: handWebp2x,
    imgWebpType: 'image/webp',
    header: 'howItWorks.text_3.text_bold',
    text: 'howItWorks.text_3.text',
  },
];

export const HowItWorks = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center">
      <MainTitle title="howItWorks.title" />

      <div className="mt-9 flex w-full flex-col gap-[21px] md:mt-12 md:flex-row lg:justify-center lg:gap-[72px]">
        {howItWorksInfo.map(
          ({
            imgPng,
            imgPng2x,
            imgPngType,
            imgWebp1x,
            imgWebp2x,
            imgWebpType,
            header,
            text,
          }) => (
            <div key={text} className="flex flex-col items-center gap-[17px]">
              <picture>
                <source
                  srcSet={`${imgWebp1x.src} 1x, ${imgWebp2x.src} 2x`}
                  type={imgWebpType}
                />
                <source
                  srcSet={`${imgPng.src} 1x, ${imgPng2x.src} 2x`}
                  type={imgPngType}
                />
                <img
                  src={imgPng.src}
                  width={imgPng.width}
                  height={imgPng.height}
                  alt={text}
                  loading="lazy"
                />
              </picture>
              <div className="body1 flex max-w-[218px] flex-wrap justify-center tracking-[0.1px] text-grey-800 xl:leading-[24px]">
                <span className="text-lg font-semibold text-blue-900 ">
                  {t(`${header}`)}
                </span>
                <span className="font-medium text-grey-800 xl:text-[17px]">
                  {t(`${text}`)}
                </span>
              </div>
            </div>
          ),
        )}
      </div>
      <ProtectionSheild />
    </div>
  );
};
