export const PICKTHEBANK_ORGANIZATION = {
  '@type': 'Organization',
  legalName: 'Pick Savings Navigator LTD',
  name: 'PickTheBank',
  email: 'info@pickthebank.eu',
  telephone: '+35799272790',
  address:
    '225, Arch. Makariou III Avenue Oriana Court, 3rd Floor, Office 4 3105 Limassol, Cyprus',
  logo: 'https://pickthebank.eu/assets/logo/logo.svg',
  url: 'https://pickthebank.eu/',
};
