import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileModals } from '@/components/MobileModal';
import { TermsModalInner } from '@/components/SearchBar/parts/MobileSearchModals/TermsModalInner';
import { Icon } from '@/components/Sprite';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { getTerm } from '@/store/storeSearch.utils';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IModalTermsProps {
  terms: IDepositTerm[];
  termsSearchModal?: boolean;
  onChange: (terms: IDepositTerm[]) => void;
  className?: string;
}

const copyTerms = (terms: IDepositTerm[]) => {
  const copy: IDepositTerm[] = [];

  for (let i = 0; i < terms.length; i += 1) {
    copy.push({ ...(terms[i] as IDepositTerm) });
  }

  return copy;
};

export const TermsModal: FC<IModalTermsProps> = ({
  terms: terms_initial,
  termsSearchModal,
  onChange,
  className,
}) => {
  const { t } = useTranslation();
  const activeTerms = terms_initial.filter((o) => o.selected);
  const allTermsIsActive = activeTerms.length === 0;

  const termsRef = useRef<IDepositTerm[]>(copyTerms(terms_initial));
  const [terms, setTerms] = useState<IDepositTerm[]>(copyTerms(terms_initial));

  const handleOnChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  const handleOnClose = (isSubmitted?: boolean) => {
    if (isSubmitted) {
      onChange(terms);

      termsRef.current = copyTerms(terms);
      handleOnChange(copyTerms(terms));
    } else {
      handleOnChange(copyTerms(termsRef.current));
    }
  };

  return (
    <MobileModals
      zMoreIndex
      trigger={
        <button
          onClick={() => {
            analyticServices.sendEvent({
              action: AnalyticsEvent.openTerms,
              category: AnalyticsCategory.deposit,
              label: 'openTerms',
            });
          }}
          type="button"
          className={cn(
            'search-input w-full hover:bg-gray-100 pt-[10px]]',
            termsSearchModal && 'pl-[24px] pr-[27px]',
            className,
          )}
        >
          <p className="caption whitespace-nowrap text-left">
            {t('deposits.terms')}
          </p>
          <div className="body2-bold flex w-full min-w-0 grow-[1] items-center justify-between gap-2 rounded-md font-bold text-blue-900">
            <span className="truncate text-[15px]">
              {activeTerms.map((term, index) => (
                <span className="capitalize" key={term.key}>
                  {getTerm(term.key, t)}
                  {index < activeTerms.length - 1 ? ', ' : ''}
                </span>
              ))}
              {allTermsIsActive && t(`database.deposit type.all`)}
            </span>
            <Icon
              className="ml-auto min-w-0 shrink-0 text-transparent"
              name="arrowDownIcon"
            />
          </div>
        </button>
      }
      title={`${t('search.terms')}`}
      isButtonSelect
      buttonTitle={`${t('search.select')}`}
      onClose={handleOnClose}
    >
      <TermsModalInner terms={terms} onChange={handleOnChange} />
    </MobileModals>
  );
};
