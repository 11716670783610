import type { FC } from 'react';

import { AnnualFeeModal } from '@/components/SearchBar/parts/MobileSearchModals/AnnualFeeModal';
import { CashbackModal } from '@/components/SearchBar/parts/MobileSearchModals/CashbackModal';
import { useSearchStore } from '@/store';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { cn } from '@/utils';

import { CountryModal, CurrencyModal, TermsModal } from '../MobileSearchModals';
import { SearchButton } from '../SearchButton';

interface IDescriptionProps {
  className?: string;
  otherStyleFormDescription?: boolean;
  buttonChange?: boolean;
  onSubmit: () => void;
  type?: 'deposits' | 'savings';
}

export const DescriptionOfModal: FC<IDescriptionProps> = ({
  className,
  otherStyleFormDescription,
  buttonChange,
  onSubmit,
  type = 'deposits',
}) => {
  const { terms, setTerms } = useSearchStore((state) => state);

  const handleTermsChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-2',
        buttonChange ? 'px-4 pt-0' : 'px-0 pt-4',
        className,
      )}
    >
      <div
        className={cn(
          'flex w-full hover:bg-gray-100 items-center justify-between rounded-[32px] border-[1.5px] border-gray-200',
          buttonChange ? 'gap-1' : 'gap-2',
        )}
      >
        <CountryModal
          countryModalSearch={otherStyleFormDescription}
          className="min-w-0 shrink-[unset] grow-[1]"
        />
        <div className="relative min-h-0">
          <div className="absolute inset-x-0 -bottom-5 flex h-8 w-[1px] rounded-[1px] bg-grey-200" />
        </div>
        <CurrencyModal
          className="min-w-0 shrink-0"
          currencySearchModal={otherStyleFormDescription}
        />
      </div>
      {type === 'deposits' && (
        <TermsModal
          terms={terms}
          termsSearchModal={otherStyleFormDescription}
          onChange={handleTermsChange}
        />
      )}
      {type === 'savings' && (
        <>
          <CashbackModal className="min-w-0 shrink-0" />
          <AnnualFeeModal className="min-w-0 shrink-0" />
        </>
      )}
      {buttonChange ? (
        <div
          className={cn(
            buttonChange
              ? 'pt-4'
              : 'sticky bottom-0 z-50 mb-[9px] mt-3 flex w-full overflow-y-hidden',
          )}
        >
          <SearchButton
            onClick={onSubmit}
            className="z-50 max-h-[100px] w-full self-center px-6 py-[18px] md:hidden"
            searchType={type}
          />
        </div>
      ) : null}
    </div>
  );
};
