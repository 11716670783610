import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { IBankDepositCard } from '@/types';

interface BestOffersModalActions {
  setProducts: (products: IBankDepositCard[]) => void;
  setIsModalShown: (isModalShown: boolean) => void;
}
interface BestOffersModalState {
  products: IBankDepositCard[];
  isModalShown?: boolean;
}

const initialState: BestOffersModalState = {
  products: [],
  isModalShown: false,
};

export const useBestOffersModalStore = create<
  BestOffersModalActions & BestOffersModalState
>()(
  devtools(
    (set) => ({
      ...initialState,
      setProducts: (products: IBankDepositCard[]) => set({ products }),
      setIsModalShown: (isModalShown: boolean) => set({ isModalShown }),
    }),
    {
      name: 'best-offers::storage',
    },
  ),
);
