import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';

import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

import { MainTitle } from '../MainTitle';
import { Icon } from '../Sprite';

export function FAQs({
  headingClassName,
  listClassName,
  questions,
  blockClassName,
}: {
  headingClassName?: string;
  listClassName?: string;
  questions: any[];
  blockClassName?: string;
}) {
  const { t } = useTranslation();

  const handleLearnMore = (index: number) => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.toggleFAQLearnMore,
      category: AnalyticsCategory.deposit,
      label: 'toggleFAQLearnMore',
      value: index,
    });
  };

  const handleClickFaqButton = (index: number) => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.toggleFAQ,
      category: AnalyticsCategory.deposit,
      label: 'toggleFAQ',
      value: index,
    });
  };
  return (
    <div className="mx-auto max-w-[946px]">
      <MainTitle title="global.faq_full" className={headingClassName} />
      <dl
        className={cn(
          'mg:mb-[85px] my-8 flex flex-col gap-2 md:mt-12 lg:mb-[64px]',
          listClassName,
        )}
      >
        {questions.map((faq, index) => (
          <Disclosure
            as="div"
            key={`${index}-${faq.answer}`}
            className={cn(
              'shadow-close rounded-2xl bg-white w-full hover:shadow-far',
            )}
          >
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button
                    className={cn(
                      'flex w-full items-center lg:min-h-[96px] justify-between border-grey-200 bg-white px-[15px] py-[15px] text-left text-blue-900 hover:text-blue-500  md:px-10 md:py-8 tracking-[0.17px] md:tracking-[0.1px]',
                      open
                        ? 'rounded-t-2xl border-x border-t'
                        : 'rounded-2xl border',
                      blockClassName,
                    )}
                    onClick={() => handleClickFaqButton(index)}
                  >
                    <span className="text-blue-900 hover:text-blue-900/90">
                      {t(faq.question)}
                    </span>
                    <span className="ml-4 flex h-7 items-center">
                      {open ? (
                        <Icon
                          className="size-6 text-transparent"
                          name="shewronDown"
                        />
                      ) : (
                        <Icon
                          className="size-6 text-transparent"
                          name="shevronRight"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel className="rounded-b-2xl border-x border-b bg-white px-4 py-2 md:px-10 md:py-2">
                  <p
                    className={cn(
                      'body2 leading-6 tracking-[0.2px] text-grey-800',
                      faq?.listAnswers?.length === 0 && 'mb-2',
                      faq.answer !== '' && 'mb-2',
                    )}
                  >
                    {t(faq.answer)}
                  </p>
                  {faq.listAnswers && (
                    <ul className="list-disc space-y-3 pb-8">
                      {faq.listAnswers.map((answer: any) => (
                        <li
                          className="body2 flex items-start justify-start gap-2"
                          key={answer}
                        >
                          <Icon
                            className="max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] text-transparent"
                            name="verified"
                          />
                          <p className="body2 text-grey-800">{t(answer)}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                  {faq.link && (
                    <Link
                      href={t(faq.link)}
                      type="button"
                      onClick={() => handleLearnMore(index)}
                      className="body2-bold mb-[24px] flex max-h-[44px] min-h-[44px] w-max min-w-[126px] content-center items-center justify-center rounded-full bg-blue-100 px-4 text-center text-blue-500 hover:bg-blue-150 xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]"
                    >
                      {t('common:deposit_card.button_more_info')}
                    </Link>
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  );
}
