import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { CURRENCY_SYMBOLS } from '@/store/storeSearch.consts';
import type { IBankDepositCard } from '@/types';
import { calcDepositTerms, checkIsNumber, cn, transformMonths } from '@/utils';

interface IDepositTermsProps {
  className?: string;
  bank: IBankDepositCard;
  isBlogBankCard?: boolean;
}

export const DepositTerms: FC<IDepositTermsProps> = ({
  className,
  bank,
  isBlogBankCard = false,
}) => {
  const { t } = useTranslation();

  const { value, unit } = transformMonths(+bank.termMonths);

  const { displayAmount, prefix, isAnyAmount } = calcDepositTerms(
    bank.minimumAmount,
    bank.maximumAmount,
  );

  return (
    <div
      className={cn(
        'content-start items-start justify-start gap-6 text-center text-grayColor md:flex',
        className,
        isBlogBankCard && 'gap-4',
      )}
    >
      <p
        className={cn(
          'text-right font-inter text-[15px] font-bold leading-[20px] tracking-[0.2px] text-blue-900 md:text-left md:text-[24px] md:font-semibold md:leading-[28px] md:w-[96.6px] xl:w-[130px] xl:text-[36px] xl:leading-[40px]',
          isBlogBankCard &&
            'xl:text-[24px] xl:font-semibold xl:leading-7 xl:tracking-[0.2px]',
        )}
      >
        {Number(bank.grossInterestRate).toFixed(2)}%
      </p>
      {bank.productType.toLowerCase() === 'fixed term deposit' && (
        <>
          <p
            className={cn(
              'flex items-center gap-1 xl:min-w-[130px] md:w-[96.6px] xl:w-[130px] text-grey-600 md:child:text-blue-900 child:body3 child:md:body2',
              isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
            )}
          >
            <span>{value}</span>
            <span className="hidden lowercase md:flex">
              {t(`deposit_card.${unit?.toLowerCase()}_short`)}
            </span>
            <span className="md:hidden">
              {displayAmount ? (
                <>
                  <span className="lowercase">
                    {`${t(`deposit_card.${unit.toLowerCase()}_short`)}, `}
                  </span>
                  <span>{CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}</span>
                </>
              ) : (
                <span className="lowercase">
                  {t(`deposit_card.${unit?.toLowerCase()}_short`)}
                </span>
              )}
            </span>
          </p>

          {displayAmount && !isAnyAmount && (
            <div
              className={cn(
                'hidden gap-1 md:flex md:w-[96.6px] xl:w-[143px]',
                isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px]',
              )}
            >
              <p className="body2 text-left text-blue-900">
                {checkIsNumber(displayAmount) ? (
                  <>
                    <span>
                      {prefix && `${t(prefix)} `}
                      {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
                    </span>
                    <span>{displayAmount}</span>
                  </>
                ) : (
                  <>
                    <span>
                      {prefix && `${t(prefix)} `}
                      {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
                    </span>
                    <span>{t(displayAmount)}</span>
                  </>
                )}
              </p>
            </div>
          )}

          {isAnyAmount && (
            <div className="hidden gap-1 text-left md:flex md:w-[96.6px] xl:w-[143px]">
              <p className="body2 text-left text-blue-900">
                <span>{t(displayAmount)}</span>
              </p>
            </div>
          )}
        </>
      )}
      {['savings account', 'demand account'].includes(
        bank.productType.toLowerCase(),
      ) && (
        <>
          <div
            className={cn(
              'xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
              isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
            )}
          >
            <p className="caption text-grey-500">{t('global.annual_fee')}</p>
            <p className="body2-bold mt-0.5 text-blue-900">
              {bank.annualFee
                ? t(`savings-accounts.annual-fee.${bank.annualFee}`)
                : '-'}
            </p>
          </div>
          <div
            className={cn(
              'xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
              isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
            )}
          >
            <p className="caption text-grey-500">{t('global.cashback')}</p>
            <p className="body2-bold mt-0.5 text-blue-900">
              {bank.cashback
                ? t(`savings-accounts.cashback.${bank.cashback}`)
                : t(`savings-accounts.cashback.no`)}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
