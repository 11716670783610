import type { UserSubscriptionTypesEnum } from '@pickthebank/api-sdk/index';
import { AttributeTypesNames } from '@pickthebank/api-sdk/type/request/user/profile-attributes.dto';
import { useRouter } from 'next-translate-routes/router';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from '@/contexts/ApiContext';
import { prepareUserAttributes } from '@/layouts/Compliance/utils';
import { useAuthStore } from '@/store';
import { decodeBase64 } from '@/utils';
import type { SubscriptionObject } from '@/utils/subscriptions';
import { createSubscriptionsArray } from '@/utils/subscriptions';
import { shouldVerifyVerification } from '@/utils/verifications';

const useUserInformation = () => {
  const {
    token,
    fullName,
    email,
    isVerified,
    setUserFullName,
    setIsVerified,
    setEmail,
    setUserUuid,
    userUuid,
  } = useAuthStore((state) => state);
  const [isLoaded, setIsLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionObject[]>([]);
  const [isBaseAttributesCompleted, setIsBaseAttributesCompleted] =
    useState(false);

  const router = useRouter();
  const {
    getUserProfile,
    getUserProfileAttributes,
    fetchComplianceVerificationStatus,
    putProfile,
  } = useApi();

  const fetchUserFullName = useCallback(async () => {
    try {
      const data = prepareUserAttributes(await getUserProfileAttributes());
      const preparedInitialData = {
        firstName: data[AttributeTypesNames.FirstName],
        lastName: data[AttributeTypesNames.LastName],
      };
      setUserFullName(
        preparedInitialData.firstName && preparedInitialData.lastName
          ? `${preparedInitialData.firstName} ${preparedInitialData.lastName}`
          : null,
      );
    } catch (e) {
      console.error(e);
    }
  }, [token]);

  const fetchVerificationStatus = useCallback(async () => {
    try {
      const data = await fetchComplianceVerificationStatus();
      setIsBaseAttributesCompleted(
        !shouldVerifyVerification('base-attributes', data.verifications),
      );
      setIsVerified(data.is_verified);
    } catch (e) {
      console.error(e);
    }
  }, [token]);

  const toggleSubscription = (type: UserSubscriptionTypesEnum) => {
    setSubscriptions((prevData) => {
      const newData = [...prevData];
      const subIndex = newData.findIndex((sub) => sub.type === type);
      if (subIndex !== -1) {
        newData[subIndex]!.is_active = !newData[subIndex]!.is_active;
      }
      putProfile({ subscriptions: newData });
      return newData;
    });
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const data = await getUserProfile();
      setEmail(data?.email || '');
      setSubscriptions(
        data?.subscriptions?.subscriptions?.length
          ? data.subscriptions.subscriptions
          : createSubscriptionsArray(),
      );
      setUserUuid(data?.uuid || null);
    } catch (e) {
      console.error(e);
    }
  }, [token]);
  const fetchUserInformation = async () => {
    try {
      setIsLoaded(false);
      await fetchUserFullName();
      await fetchVerificationStatus();
      await fetchUserProfile();
      setIsLoaded(true);
      if (router.query.fallback_uri) {
        router.push(decodeBase64(router.query.fallback_uri as string) || '');
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserInformation();
    }
  }, [token]);

  return {
    fetchUserInformation,
    showedUsername: fullName || email,
    isVerified,
    isLoaded,
    subscriptions,
    toggleSubscription,
    fullName,
    userUuid,
    isBaseAttributesCompleted,
  };
};

export default useUserInformation;
