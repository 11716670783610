import type { GetComplianceVerificationDto } from '@pickthebank/api-sdk/type/response/compliance/verification/get-compliance-verification-status-response.dto';

export const ensureAllVerificationsPassed = (
  verificationArray: GetComplianceVerificationDto[],
): boolean => {
  for (const verification of verificationArray) {
    if (!verification.is_verified) {
      return false;
    }
  }
  return true;
};

export const shouldVerifyVerification = (
  type: string,
  verifications: GetComplianceVerificationDto[],
): boolean => {
  const verification = verifications.find((v) => v.type === type);
  if (verification) {
    return !verification.is_verified;
  }
  return false;
};

export const getVerificationByType = (
  type: string,
  verifications: GetComplianceVerificationDto[],
) => {
  return verifications.find((v) => v.type === type);
};
