import { Dialog } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { getFormContextByType } from '@/components/Dialog/parts/SignUpForm/singUpForm.utils';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { cn } from '@/utils';

import { Icon } from '../Sprite';
import { ConfirmMailDialog, SignUpForm } from './parts';

const DELAY = 300; // 5 minutes in seconds

interface DialogAuthorizationProps {}
export const DialogAuthorization: FC<DialogAuthorizationProps> = () => {
  const timerRef = useRef<any>(null);
  const refModal = useRef<HTMLDivElement>(null);

  const [countdown, setCountdown] = useState(0);
  const { type } = useAuthModalsControl((state) => state);
  const { title } = getFormContextByType(type);

  const {
    email,
    reset,
    isConfirmedEmail,
    isCodeLink,
    token: userToken,
  } = useAuthStore((state) => state);
  const { t } = useTranslation();
  const { isOpenModal, setCloseModal } = useAuthModalsControl((state) => state);
  const setIntervalForCounter = () => {
    timerRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newVal = prevCountdown - 1;

        if (newVal <= 0) {
          clearInterval(timerRef.current);
          localStorage.removeItem('timer');
        }
        return newVal;
      });
    }, 1000);
  };
  const captchaRef = useRef<any>(null);

  const executeCaptcha = async (): Promise<string> => {
    const token = await captchaRef.current.executeAsync();
    await captchaRef.current.reset();
    return token;
  };

  const handleSubmit = () => {
    setCountdown(DELAY);
    setIntervalForCounter();
    const expirationTime = new Date();
    expirationTime.setMinutes(expirationTime.getMinutes() + 1);
    localStorage.setItem('timer', expirationTime.toISOString());

    setTimeout(() => {
      localStorage.removeItem('timer');
      reset();
    }, DELAY * 1000);
  };

  useEffect(() => {
    const storedExpirationTime = localStorage.getItem('timer');
    if (storedExpirationTime) {
      const expirationTime = new Date(storedExpirationTime);
      const currentTime = new Date();
      const timeDifference = expirationTime.getTime() - currentTime.getTime();

      if (timeDifference > 0) {
        setCountdown(Math.ceil(timeDifference / 1000));
        setIntervalForCounter();
      } else {
        localStorage.removeItem('timer');
        reset();
      }
    } else if (!isCodeLink) {
      reset();
    }

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (email.length === 0) {
      clearInterval(timerRef.current);
      setCountdown(0);
    }
  }, [email]);

  const handleClickOutside = (event: any) => {
    if (refModal.current && !refModal.current.contains(event.target)) {
      setCloseModal();
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      // Disable scrolling on the body when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling when modal is closed
      document.body.style.overflow = 'unset';
    }
    // Cleanup function to prevent memory leaks
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpenModal]);

  const handeAuthStep = () => {
    if (userToken) {
      return 'email';
    }
    if ((email && !isConfirmedEmail) || !!countdown) {
      return 'confirm';
    }
    if (isCodeLink && email) {
      return 'confirm';
    }
    return 'email';
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refModal]);

  return (
    <Dialog
      as="div"
      className="shadow-far relative z-[1999999999] bg-yellow-100"
      onClose={() => {}}
      open={isOpenModal}
    >
      {/* <Transition.Child */}
      {/*  as={Fragment} */}
      {/*  enter="ease-out duration-300" */}
      {/*  enterFrom="opacity-0" */}
      {/*  enterTo="opacity-100" */}
      {/*  leave="ease-in duration-200" */}
      {/*  leaveFrom="opacity-100" */}
      {/*  leaveTo="opacity-0" */}
      {/* > */}
      {/*  <div className="fixed inset-0 transition-opacity" /> */}
      {/* </Transition.Child> */}

      <div
        className="fixed inset-0 z-10 overflow-y-auto bg-[#000066]/10"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
          <Dialog.Panel
            ref={refModal}
            className={cn(
              'shadow-far relative flex flex-col justify-evenly overflow-hidden rounded-[24px] bg-white px-4 pt-3 pb-4 text-left transition-all',
              'w-full md:px-6 md:pt-4 md:pb-6 md:max-w-[500px]',
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {handeAuthStep() !== 'confirm' ? (
              <div className="-mx-6 flex cursor-pointer items-center justify-between gap-4 border-b px-6 pb-4 text-black">
                <p className="body1-bold text-blue-900">{t(title)}</p>
                <button type="button" onClick={() => setCloseModal()}>
                  <Icon className="size-5" name="closeIcon" />
                </button>
              </div>
            ) : (
              <div className="-mx-6 flex cursor-pointer items-center justify-between gap-4 border-b px-6 pb-4 text-black">
                <button type="button" onClick={reset}>
                  <Icon
                    name="chevron"
                    className="size-5 -rotate-90 text-grey-400"
                  />
                </button>
                <p className="body1-bold text-blue-900">{t(title)}</p>
                <button type="button" onClick={() => setCloseModal()}>
                  <Icon className="size-5" name="closeIcon" />
                </button>
              </div>
            )}
            {handeAuthStep() === 'confirm' ? (
              <ConfirmMailDialog executeCaptcha={executeCaptcha} />
            ) : (
              <SignUpForm
                onSubmit={handleSubmit}
                executeCaptcha={executeCaptcha}
              />
            )}
          </Dialog.Panel>
        </div>
        <ReCAPTCHA
          ref={captchaRef}
          className={cn(!isOpenModal && 'hidden')}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY || ''}
        />
      </div>
    </Dialog>
  );
};
export default DialogAuthorization;
