import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { type FC, useState } from 'react';
import React from 'react';

import { Icon } from '@/components/Sprite';
import { getBankLogoURL } from '@/services/supabase';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface IDepositCardProps {
  className?: string;
  depositInfo: IBankDepositCard;
}

export const DepositCard: FC<IDepositCardProps> = ({
  className,
  depositInfo,
}) => {
  const { t } = useTranslation();
  const {
    bankIndex,
    productIndex,
    bankName,
    country,
    grossInterestRate,
    termMonths,
  } = depositInfo;

  const [src, setSrc] = useState(bankIndex);

  const handleOpenDeposit = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickCheckDetails,
      category: AnalyticsCategory.deposit,
      label: 'clickCheckDetails',
      value: +productIndex,
    });
  };

  return (
    <Link
      href={`/deposits/${productIndex}`}
      onClick={handleOpenDeposit}
      className={cn(
        'flex flex-col justify-between min-h-[266px] md:min-h-[284px]',
        className,
      )}
    >
      <div className="flex flex-col">
        <Image
          src={getBankLogoURL(src)}
          alt="bank index image"
          width={64}
          height={64}
          className="size-12 rounded-full bg-grey-300 object-cover md:size-16"
          onError={(e) => {
            if (e.target) {
              setSrc('mockup');
            }
          }}
          loading="lazy"
        />
        <div className="mt-6 max-h-[68px] min-h-[68px]">
          <p className="body1-bold line-clamp-2 max-h-[48px] text-ellipsis break-words leading-[24px] tracking-[0.1px] text-blue-900">
            {bankName}
          </p>
          <p className="body3 text-grey-800">
            {t(`database.countries.${country}`)}
          </p>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="h2-inter text-blue-900">{grossInterestRate}%</div>
        <p className="body3 mb-6 text-grey-800">
          {`${termMonths.split(' ')[0]} ${t('deposit_card.months')}`}
        </p>
        <button type="button" className="body2-bold flex items-center gap-1">
          <span className="text-[13px] tracking-[0.1px] text-blue-500 hover:text-blue-600 md:text-[15px]">
            {t('global.check_details')}
          </span>
          <Icon
            name="rightArrow"
            className="size-4 stroke-none text-blue-500"
          />
        </button>
      </div>
    </Link>
  );
};
