import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';

import { BestProductsList } from '@/components/BestProductsList';
import { useModal } from '@/contexts/ModalContext';
import type { IBankDepositCard } from '@/types';

export interface BestOffersModalProps {
  products: IBankDepositCard[];
}
export const BestOffersModal: FC<BestOffersModalProps> = ({ products }) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  return (
    <div className="flex w-[460px] max-w-[500px] flex-col">
      <p className="body2-blog mb-1">{t('best-deposits.description')}</p>
      {products && (
        <BestProductsList products={products} onItemClick={closeModal} />
      )}
    </div>
  );
};
