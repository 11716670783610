import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  DEPOSIT_TERMS,
  DEPOSIT_TYPE_OPTIONS,
} from '@/store/storeSearch.consts';
import type {
  IDepositOperation,
  IDepositTerm,
  ISearchControlsState,
} from '@/store/storeSearch.types';

interface ISearchState extends ISearchControlsState {
  isEdited: boolean;
  isLoading: boolean;
  canReset: boolean;
  annualFee: {
    key: string;
    value?: string;
  };
  cashback: {
    key: string;
    value?: string;
  };
}

interface ISearchActions {
  setCountry: (country: string) => void;
  setCurrency: (currency: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  toggleTerm: (index: number) => void;
  setTerms: (terms: IDepositTerm[]) => void;
  setDepositTypesAndPartner: (payload: {
    types: IDepositOperation[];
    is_partner: boolean;
  }) => void;
  setIsPartner: (is_partner: boolean) => void;
  patchSearchValue: (payload: Partial<ISearchControlsState>) => void;
  resetSearchState: () => void;
  setAnnualFee: (fee: { key: string; value?: string }) => void;
  setCashback: (cashback: { key: string; value?: string }) => void;
}

const initialState: ISearchState = {
  country: 'Germany',
  currency: 'eur',
  terms: DEPOSIT_TERMS,
  is_partner: true,
  types: DEPOSIT_TYPE_OPTIONS.map((type) => {
    return { ...type, selected: type.key === 'ordinary deposit' };
  }),
  isLoading: false,
  isEdited: false,
  canReset: false,
  annualFee: {
    key: 'all',
    value: undefined,
  },
  cashback: {
    key: 'all',
    value: undefined,
  },
};

type SearchStore = ISearchState & ISearchActions;

export const useSearchStore = create<SearchStore>()(
  devtools(
    (set) => ({
      ...initialState,
      setCountry: (country: string) =>
        set(() => ({ country }), false, {
          type: 'search/setCountry',
        }),
      setCurrency: (currency: string) =>
        set(() => ({ currency }), false, {
          type: 'search/setCurrency',
        }),
      setDepositTypesAndPartner: ({ types, is_partner }) =>
        set(() => ({ types, is_partner }), false, {
          type: 'search/setDepositTypesAndPartner',
        }),
      setIsPartner: (is_partner: boolean) =>
        set(() => ({ is_partner }), false, {
          type: 'search/setIsPartner',
        }),
      setTerms: (terms) =>
        set(() => ({ terms }), false, {
          type: 'search/setTerms',
        }),
      setAnnualFee: (annualFee) => {
        set(() => ({ annualFee }), false, {
          type: 'search/setAnnualFee',
        });
      },
      setCashback: (cashback) => {
        set(() => ({ cashback }), false, {
          type: 'search/setCashback',
        });
      },
      toggleTerm: (index: number) =>
        set(
          (state) => {
            const terms = [...state.terms];
            terms[index]!.selected = !terms[index]!.selected;

            return { terms };
          },
          false,
          {
            type: 'search/toggleTerm',
          },
        ),

      patchSearchValue: (payload) =>
        set(
          () => {
            const params: Partial<ISearchControlsState> = {};

            if (payload.country) {
              params.country = payload.country;
            }

            if (payload.currency) {
              params.currency = payload.currency;
            }

            if (payload.terms && payload.terms.length) {
              params.terms = payload.terms;
            }

            if (payload.is_partner === false) {
              params.is_partner = payload.is_partner;
            }

            if (payload.types && payload.types.length) {
              params.types = payload.types;
            }

            if (payload.cashback) {
              params.cashback = payload.cashback;
            }

            if (payload.annualFee) {
              params.annualFee = payload.annualFee;
            }

            const activeTypes = payload?.types?.filter((o) => o.selected) || [];
            if (activeTypes.length > 0) {
              params.is_partner = false;
            }

            return params;
          },
          false,
          {
            type: 'search/patchSearchValue',
          },
        ),

      setIsLoading: (isLoading: boolean) =>
        set(() => ({ isLoading }), false, {
          type: 'search/setIsLoading',
        }),
      resetSearchState: () =>
        set(initialState, false, {
          type: 'search/resetSearchState',
        }),
    }),
    {
      anonymousActionType: 'search anonymous',
    },
  ),
);
