import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { useSearchStore } from '@/store';
import { cn } from '@/utils';

import { QuicksearchButton, ResetButton } from './parts';

interface ISearchextendProps {
  handleFetchResetData?: () => void;
  className?: string;
}

export const SearchExtend: FC<ISearchextendProps> = ({
  handleFetchResetData = () => {},
  className,
}) => {
  const { t } = useTranslation();

  const { canReset } = useSearchStore((state) => state);

  const handleReset = () => {
    // TODO: implement reset scenario
    handleFetchResetData();
  };

  return (
    <div className={cn('flex justify-between px-4', className)}>
      <div className="flex flex-col gap-2 md:flex-row md:items-center">
        <p className="font-inter text-[11px] font-medium leading-4 tracking-[0.2px] text-grey-800">
          {t('deposits.quicksearch')}:
        </p>
        <div className="flex items-center gap-2">
          <QuicksearchButton text={t('deposit_card.best_interest_rate')} />
          <QuicksearchButton text={t('deposit_card.badge_available_online')} />
        </div>
      </div>
      {canReset && <ResetButton className="self-start" onReset={handleReset} />}
    </div>
  );
};
