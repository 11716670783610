import React, { createContext, useContext, useRef, useState } from 'react';

import { Modal } from '@/components/Modal';
import { useAuthModalsControl } from '@/store';

interface ModalContextType {
  openModal: (
    content: React.ReactNode,
    title?: string,
    titlePosition?: 'left' | 'center',
    onUserClose?: () => void,
  ) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null,
  );
  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);
  const [modalTitlePosition, setModalTitlePosition] = useState<
    'left' | 'center'
  >('left');
  const modalOnUserClose = useRef<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpenModal: isAuthModalOpen } = useAuthModalsControl(
    (state) => state,
  );

  const openModal = (
    content: React.ReactNode,
    title?: string,
    titlePosition: 'left' | 'center' = 'left',
    onUserClose?: () => void,
  ) => {
    if (isModalOpen) return;
    if (isAuthModalOpen) return;
    setIsModalOpen(true);
    setModalContent(content);
    setModalTitle(title);
    setModalTitlePosition(titlePosition);
    modalOnUserClose.current = onUserClose;
  };

  const closeModal = () => {
    setModalContent(null);
    setModalTitle(undefined);
    setIsModalOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent && (
        <Modal
          content={modalContent}
          title={modalTitle}
          titlePosition={modalTitlePosition}
          onUserClose={modalOnUserClose.current}
          onClose={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};
