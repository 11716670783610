import { type FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { AnnualFeeSelect } from '@/components/SearchBar/parts/DesktopSearchSelects/AnnualFeeSelect';
import { CashbackSelect } from '@/components/SearchBar/parts/DesktopSearchSelects/CashbackSelect';
import { useSearchStore } from '@/store';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { cn } from '@/utils';

import { LazyLoaded } from '../LazyLoaded';
import { Icon } from '../Sprite';
import {
  CountrySelect,
  CurrencySelect,
  SearchButton,
  TermsSelect,
} from './parts';

interface ISearchBarProps {
  onSubmit: () => void;
  className?: string;
  isScrolling?: boolean;
  isHomePage?: boolean;
  searchType?: 'deposits' | 'savings';
}

const Divider = () => (
  <div className="relative min-h-0">
    <div className="absolute inset-x-0 -bottom-4 hidden h-8 w-[1px] rounded-[1px] bg-grey-200 md:flex" />
  </div>
);

export const SearchBar: FC<ISearchBarProps> = ({
  className,
  isScrolling,
  isHomePage = false,
  searchType = 'deposits',
  onSubmit = () => {},
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 728px)',
  });

  const { terms, setTerms } = useSearchStore((state) => state);

  const handleTermsChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  // const handleDepositChange = (
  //   _types: IDepositOperation[],
  //   isPartner: boolean,
  // ) => {
  //   setDepositTypesAndPartner({
  //     types: _types,
  //     is_partner: isPartner,
  //   });
  // };

  return (
    <div>
      <div
        className={cn(
          'min-h-[64px] whitespace-nowrap hidden flex-col items-center md:gap-1 xl:gap-2 w-full rounded-xl bg-white py-6 md:px-0 md:py-0 font-medium', // h-[420px] py-6
          'md:flex md:flex-row md:rounded-full md:border-[1.5px] md:border-[#000000]/10 md:shadow-none', // md:items-center
          className,
        )}
      >
        <div className="ml-3 hidden w-8 shrink-0 items-center justify-center lg:flex">
          <Icon
            name="Search"
            className="hidden max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px] stroke-2 text-transparent md:flex"
          />
        </div>
        {isDesktop && (
          <LazyLoaded>
            {searchType === 'deposits' && (
              <>
                <CountrySelect
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[30%] lg:w-[25.5%] xl:w-[25.39%]"
                  dropdownClassname={isHomePage ? 'w-[234px]' : 'w-[246px]'}
                />
                <Divider />
                <CurrencySelect
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[30%] lg:w-[25.5%] xl:w-[25.39%]"
                  dropdownClassname={isHomePage ? 'w-[139px]' : 'w-[145px]'}
                />
                <Divider />
                <TermsSelect
                  terms={terms}
                  isScrolling={isScrolling}
                  className={cn('w-full overflow-hidden', isHomePage && '')}
                  dropdownClassname={isHomePage ? 'w-[236px]' : 'w-[247px]'}
                  onChange={handleTermsChange}
                >
                  <SearchButton onClick={onSubmit} />
                </TermsSelect>
                {/* <DepositSelect */}
                {/*  is_partner={is_partner} */}
                {/*  types={types} */}
                {/*  isScrolling={isScrolling} */}
                {/*  className="min-w-0 grow-[1] pr-[1px]" */}
                {/*  dropdownClassname={isHomePage ? 'w-[378px]' : 'w-[398px]'} */}
                {/*  onChange={handleDepositChange} */}
                {/*  onSubmit={onSubmit} */}
                {/* /> */}
              </>
            )}
            {searchType === 'savings' && (
              <>
                <CountrySelect
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[25%] lg:w-[22%] xl:w-[22.39%]"
                  dropdownClassname={isHomePage ? 'w-[234px]' : 'w-[246px]'}
                />
                <Divider />
                <CurrencySelect
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[25%] lg:w-[18.2%] xl:w-[16.39%]"
                  dropdownClassname={isHomePage ? 'w-[139px]' : 'w-[145px]'}
                />
                <Divider />
                <AnnualFeeSelect
                  isScrolling={isScrolling}
                  className="w-full shrink-0 md:w-[19%] lg:w-[19%] xl:w-[22.39%]"
                  dropdownClassname={isHomePage ? 'w-[139px]' : 'w-[145px]'}
                />
                <Divider />
                <CashbackSelect
                  isScrolling={isScrolling}
                  className={cn(
                    'md:w-[20%] lg:w-[32.1%] xl:w-[29.6%]',
                    isHomePage && '',
                  )}
                  dropdownClassname={isHomePage ? 'w-[236px]' : 'w-[247px]'}
                >
                  <SearchButton onClick={onSubmit} searchType={searchType} />
                </CashbackSelect>
              </>
            )}
          </LazyLoaded>
        )}
      </div>
    </div>
  );
};
