import type { FC } from 'react';

import { cn } from '@/utils';

export interface SvgProps {
  // eslint-disable-next-line prettier/prettier
  name: 'ArrowLight' | 'AvatarLogo' | 'CheckedCircle' | 'CheckedTip' | 'LogoHeader' | 'PTBLogo' | 'PickTheBankShortLogo' | 'QuestionMarkIcon' | 'Search' | 'SearchWhiteIcon' | 'Tik' | 'WarningDeposit' | 'WarningIcon' | 'adress' | 'advantageIcon' | 'arrowDown' | 'arrowDownIcon' | 'arrowRentagleTopRight' | 'arrowRight' | 'arrowRightWhite' | 'arrowUp' | 'austriaLogo' | 'avatar' | 'bank' | 'belgiumLogo' | 'bookmark' | 'bulgariaLogo' | 'burger' | 'button-fav-is-active' | 'button-to-fav' | 'cardProtected' | 'check' | 'checkmarkCircled' | 'chevron' | 'circleAustria' | 'circleBulgaria' | 'circleCanada' | 'circleChech' | 'circleDenmark' | 'circleHungary' | 'circleJapan' | 'circleNewZeal' | 'circleNorvay' | 'circlePoland' | 'circleRomania' | 'circleSweden' | 'circleSwitzerland' | 'clock' | 'close' | 'closeIcon' | 'controlDot' | 'controlRow' | 'copyIcon' | 'croatiaLogo' | 'cyprusLogo' | 'czechLogo' | 'denmarkLogo' | 'documentIcon' | 'dot' | 'dropDownArrow' | 'dropLeftArrow' | 'dropRightArrow' | 'dropUpArrow' | 'email' | 'emptyBankPic' | 'englishLogo' | 'envelope' | 'estoniaLogo' | 'euro' | 'facebook' | 'favorite' | 'finlandLogo' | 'fire' | 'franceLogo' | 'gbp' | 'germanLogo' | 'google' | 'greeceLogo' | 'greenTik' | 'guarantee' | 'help' | 'horizontal-dots' | 'hungaryLogo' | 'irelandLogo' | 'italyLogo' | 'latviaLogo' | 'letter-white' | 'linkedin' | 'linkedin-custom' | 'lithuaniaLogo' | 'luxembourgLogo' | 'maltaLogo' | 'netherlandsLogo' | 'percent' | 'phone' | 'pickTheBankLogo' | 'piggy-bank' | 'plusIcon' | 'polandLogo' | 'portugalLogo' | 'propertyLock' | 'ptb-glyph' | 'raisinLogo' | 'recent' | 'recentBlack' | 'rightArrow' | 'romaniaLogo' | 'roundedCross' | 'search-icon' | 'share' | 'shevronRight' | 'shewronDown' | 'shewronLeft' | 'shield-check' | 'slovakiaLogo' | 'sloveniaLogo' | 'spainLogo' | 'star' | 'successCheckmark' | 'swedenLogo' | 'switzerlandLogo' | 'threeDots' | 'toolTip' | 'usd' | 'verification' | 'verificationIcon' | 'verified' | 'verifiedShield' | 'warning' | 'web';
  className?: string;
}

export const Icon: FC<SvgProps> = ({ name, className }) => (
  <svg
    className={cn('h-[1em] w-[1em] fill-current stroke-current', className)}
    name={`#icon-${name}`}
  >
    <use xlinkHref={`#icon-${name}`} />
  </svg>
);
