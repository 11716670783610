/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import bluorPng2x from '@/public/assets/partherBanks/bluor@2x-min.png';
import bluorWebp2x from '@/public/assets/partherBanks/bluor@2x-min.webp';
import bluorPng1x from '@/public/assets/partherBanks/bluor-min.png';
import bluorWebp1x from '@/public/assets/partherBanks/bluor-min.webp';
import lidionPng2x from '@/public/assets/partherBanks/lidion@2x-min.png';
import lidionWebp2x from '@/public/assets/partherBanks/lidion@2x-min.webp';
import lidionPng1x from '@/public/assets/partherBanks/lidion-min.png';
import lidionWebp1x from '@/public/assets/partherBanks/lidion-min.webp';
import saldoPng2x from '@/public/assets/partherBanks/saldo@2x-min.png';
import saldoWebp2x from '@/public/assets/partherBanks/saldo@2x-min.webp';
import saldoPng1x from '@/public/assets/partherBanks/saldo-min.png';
import saldoWebp1x from '@/public/assets/partherBanks/saldo-min.webp';

export const BankLogos = () => {
  return (
    <>
      <picture>
        <source
          srcSet={`${saldoWebp1x.src} 1x, ${saldoWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${saldoPng1x.src} 1x, ${saldoPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={saldoPng1x.src}
          className="h-[24px] w-[65px] md:h-[32px] md:w-[87px] lg:h-[38px] lg:w-[103px]"
          width={saldoPng1x.width}
          height={saldoPng1x.height}
        />
      </picture>
      <picture>
        <source
          srcSet={`${bluorWebp1x.src} 1x, ${bluorWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${bluorPng1x.src} 1x, ${bluorPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={bluorPng1x.src}
          className="h-[24px] w-[119px] md:h-[32px] md:w-[136px] lg:h-[38px] lg:w-[162px]"
          width={bluorPng1x.width}
          height={bluorPng1x.height}
        />
      </picture>
      <picture className="md:last:mr-[54px] lg:last:mr-16">
        <source
          srcSet={`${lidionWebp1x.src} 1x, ${lidionWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${lidionPng1x.src} 1x, ${lidionPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={lidionPng1x.src}
          className="h-[24px] w-[66px] md:h-[32px] md:w-[87px] lg:h-[38px] lg:w-[104px]"
          width={lidionPng1x.width}
          height={lidionPng1x.height}
        />
      </picture>
    </>
  );
};
