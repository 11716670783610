import type { FC } from 'react';

import { cn } from '@/utils';

export interface IQuicksearchButtonProps {
  className?: string;
  text: string;
}

export const QuicksearchButton: FC<IQuicksearchButtonProps> = ({
  className,
  text,
}) => {
  const handleSelectOption = () => {};

  return (
    <button
      type="button"
      onClick={handleSelectOption}
      className={cn(
        'px-3 py-1 text-[11px] leading-[16x] text-grey-800 bg-grey-100 rounded-[32px] tracking-[0.2px]',
        className,
      )}
    >
      {text}
    </button>
  );
};
