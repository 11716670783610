import terms from '@/constants/terms.json';

export const termKeys = terms as any;

export function findTermKey(translation: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in termKeys) {
    if (Object.prototype.hasOwnProperty.call(termKeys, key)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const lang in termKeys[key]) {
        if (
          Object.prototype.hasOwnProperty.call(termKeys[key], lang) &&
          termKeys[key][lang] === translation
        ) {
          return key === 'all' ? null : parseInt(key, 10);
        }
      }
    }
  }
  throw new Error(`Bad term key`);
}

export const parseTermKeysFromUrl = (term: string) => {
  const lTerms = term.split('+');
  const preparedTerms = lTerms.map((t: string) => {
    return findTermKey(t);
  });
  if (lTerms.length === preparedTerms.length) {
    return preparedTerms.filter((t: number | null) => t !== null);
  }
  throw new Error('Bad term keys in url');
};

export type TermKey = keyof typeof termKeys;
