import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC, ReactNode } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomOverlay } from '@/components/CustomOverlay';
import { SelectHeader } from '@/components/SearchBar/parts/SelectHeader';
import { useSearchStore } from '@/store';
import { CASHBACK_OPTIONS } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface ISelectCurrencyProps {
  className?: string;
  dropdownClassname?: string;
  isScrolling?: boolean;
  children?: ReactNode;
}

export const CashbackSelect: FC<ISelectCurrencyProps> = ({
  className,
  dropdownClassname,
  isScrolling,
  children,
}) => {
  const { t } = useTranslation();
  const selectedCashback = useSearchStore((state) => state.cashback);
  const setSelectedCashback = useSearchStore((state) => state.setCashback);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCurrencyItemClick = (currency: any) => {
    if (selectedCashback === currency) return;
    setSelectedCashback(currency);
  };

  return (
    <div
      className={cn('relative inline-block flex-shrink-0 text-left', className)}
    >
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => setOverlayVisible(!overlayVisible)}
      >
        {overlayVisible && <CustomOverlay />}
        <div
          className={cn(
            'relative flex grow-[1] min-w-0 gap-1 xl:gap-[10px] w-full items-center justify-between rounded-full hover:bg-gray-100 md:flex md:border-r md:border-transparent md:pr-[9px] xl:pr-2',
            overlayVisible && 'z-20 bg-white',
          )}
        >
          <DropdownMenuPrimitive.Trigger className="w-full focus-visible:rounded-full focus-visible:outline-gray-200">
            <SelectHeader
              onClick={() => {
                analyticServices.sendEvent({
                  action: AnalyticsEvent.openCurrency,
                  category: AnalyticsCategory.deposit,
                  label: 'openCurrency',
                });
              }}
              label="Cashback"
              inFocus={overlayVisible}
            >
              {t(
                `savings-accounts.annual-fee.filter-${selectedCashback.key.toLowerCase()}`,
              )}
            </SelectHeader>
          </DropdownMenuPrimitive.Trigger>
          {children}
        </div>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white py-2',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[11px] overflow-y-auto">
              <div className="px-3">
                {CASHBACK_OPTIONS.map((c, index) => (
                  <Fragment key={index}>
                    <DropdownMenuPrimitive.Item asChild>
                      <button
                        onClick={() => {
                          handleCurrencyItemClick(c);
                        }}
                        className="flex w-full content-center items-center justify-start gap-2 px-4  py-3 text-[15px] font-bold hover:rounded-full hover:bg-grey-100 focus-visible:rounded-full focus-visible:outline-gray-100"
                        type="button"
                      >
                        <p
                          className={cn(
                            'text-blue-900',
                            selectedCashback.value === c.value
                              ? 'body2-bold'
                              : 'body2',
                          )}
                        >
                          {t(
                            `savings-accounts.annual-fee.filter-${c.key.toLowerCase()}`,
                          )}
                        </p>
                      </button>
                    </DropdownMenuPrimitive.Item>
                  </Fragment>
                ))}
              </div>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
