import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { Icon } from '@/components/Sprite/Icon';
import { cn } from '@/utils';

import { QuestionMarkTooltip } from '../QuestionMarkTooltip';

interface IDepositProductCardItemProps {
  heading: string;
  value: string;
  isAdvantage?: boolean;
  isDisadvantage?: boolean;
  isMarked?: boolean;
  tooltip?: ReactNode;
  isUppercase?: boolean;
  tooltipStyles?: string;
  textStyles?: string;
  valueStyles?: string;
  onDownload?: () => void;
}

export const DepositProductCardItemV2: FC<IDepositProductCardItemProps> = ({
  isAdvantage = false,
  isDisadvantage = false,
  isMarked = false,
  tooltip = '',
  isUppercase = false,
  heading,
  value,
  tooltipStyles,
  textStyles,
  valueStyles,
  onDownload,
}) => {
  const { t } = useTranslation();

  const MarkIcon = ({
    icon,
  }: {
    icon: 'Advantage' | 'Disadvantage' | 'DisadvantageGray';
  }) => {
    switch (icon) {
      case 'Advantage':
        return (
          <Icon
            name="greenTik"
            className="block size-4 text-transparent md:mr-2 md:size-5"
          />
        );
      case 'Disadvantage':
        return (
          <Icon
            name="close"
            className="block size-4 text-transparent md:mr-2 md:size-5"
          />
        );
      case 'DisadvantageGray':
        return (
          <Icon
            name="closeIcon"
            className="block size-4 text-transparent md:mr-2 md:size-5"
          />
        );
      default:
        return null;
    }
  };

  return (
    <li className="flex grid-cols-2 flex-col gap-[4px] py-3 md:grid md:gap-6 lg:py-3">
      <div className="flex items-center tracking-[0.1px]">
        <p className={cn('body2 text-grey-300 md:text-grey-600', textStyles)}>
          {t(heading)}
        </p>
        {tooltip && (
          <QuestionMarkTooltip
            content={tooltip}
            className={tooltipStyles}
            type="secondary"
          />
        )}
      </div>

      <div
        className={cn(
          'flex items-center gap-2 md:gap-1',
          (!isMarked || !onDownload) && 'md:ml-[28px]',
        )}
      >
        {isMarked && (
          <span>
            {(isAdvantage && <MarkIcon icon="Advantage" />) ||
              (isDisadvantage && <MarkIcon icon="DisadvantageGray" />)}
          </span>
        )}

        {onDownload ? (
          <button
            type="button"
            className={cn(
              'body2 tracking-[0.1px] text-blue-500 w-max',
              isUppercase ? 'uppercase' : 'first-letter:capitalize',
              textStyles,
              valueStyles,
            )}
            onClick={onDownload}
          >
            <Icon
              name="documentIcon"
              className="mr-2 inline-block size-6 stroke-transparent text-blue-500"
            />
            {value}
          </button>
        ) : (
          <span
            className={cn(
              'body2 w-full tracking-[0.1px] text-blue-900',
              isUppercase ? 'uppercase' : 'first-letter:capitalize',
              textStyles,
              valueStyles,
            )}
          >
            {value}
          </span>
        )}
      </div>
    </li>
  );
};
