import type { IAnalyticEvent, IAnalyticService } from '@/types/analytics';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { AmplitudeAnalyticService } from '@/utils/analytic/amplitude-analytic';
import { FacebookAnalyticService } from '@/utils/analytic/facebook-analytic';

import { GoogleAnalyticService } from './analytic/google-analytic';

// Analytic services factory
class AnalyticServiceFactory {
  constructor(private readonly services: IAnalyticService[]) {
    if (typeof window !== 'undefined') {
      this.services.forEach((service) => service.init());
    }
  }

  public sendEvent(event: IAnalyticEvent): void {
    if (typeof window !== 'undefined') {
      this.services.forEach((service) => service.sendEvent(event));
    }
  }

  public prepareHrefEventToSend(event: MouseEvent): void {
    // Find the closest ancestor anchor element
    const anchorElement = (event.target as HTMLElement).closest('a');

    if (anchorElement instanceof HTMLAnchorElement) {
      const url = new URL(decodeURIComponent(anchorElement.href));
      const queryParams = url.searchParams;
      const eventParam = queryParams.get('event');
      if (eventParam === AnalyticsEvent.openOnline) {
        const bankIndexParam = queryParams.get('bank_index');
        const productIndexParam = queryParams.get('product_index');
        const eventPayload = {
          action: AnalyticsEvent.openOnline,
          category: AnalyticsCategory.deposit,
          label: 'Deposit ID',
          payload: {
            bank_index: bankIndexParam,
            product_index: productIndexParam,
          },
        };
        this.sendEvent(eventPayload);
      }
      if (eventParam === AnalyticsEvent.visitBankSite) {
        const bankIndexParam = queryParams.get('bank_index');
        const productIndexParam = queryParams.get('product_index');
        const eventPayload = {
          action: AnalyticsEvent.visitBankSite,
          category: AnalyticsCategory.deposit,
          label: 'Deposit ID',
          payload: {
            bank_index: bankIndexParam,
            product_index: productIndexParam,
          },
        };
        this.sendEvent(eventPayload);
      }
    }
  }
}

export const analyticServices = new AnalyticServiceFactory([
  new GoogleAnalyticService(),
  new FacebookAnalyticService(),
  new AmplitudeAnalyticService(process.env.NEXT_PUBLIC_AMPLITUDE || ''),
]);
