import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import type { FC, ReactNode } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components';
import { CustomOverlay } from '@/components/CustomOverlay';
import { SelectHeader } from '@/components/SearchBar/parts/SelectHeader';
import { useSearchStore } from '@/store';
import { CURRENCY_IMAGES } from '@/store/storeSearch.consts';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface ISelectCurrencyProps {
  className?: string;
  dropdownClassname?: string;
  isScrolling?: boolean;
  children?: ReactNode;
  inputClassName?: string;
  buttonClassName?: string;
}

export const CurrencySelect: FC<ISelectCurrencyProps> = ({
  className,
  dropdownClassname,
  isScrolling,
  children,
  inputClassName,
  buttonClassName,
}) => {
  const { t } = useTranslation();
  const selectedCurrency = useSearchStore((state) => state.currency);
  const setSelectedCurrency = useSearchStore((state) => state.setCurrency);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCurrencyItemClick = (currency: string) => {
    if (selectedCurrency === currency) return;
    setSelectedCurrency(currency);
  };

  return (
    <div
      className={cn('relative inline-block flex-shrink-0 text-left', className)}
    >
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => setOverlayVisible(!overlayVisible)}
      >
        {overlayVisible && <CustomOverlay />}
        <div
          className={cn(
            'relative flex grow-[1] min-w-0 gap-1 xl:gap-[10px] w-full items-center justify-between rounded-full hover:bg-gray-100 md:flex md:border-r md:border-transparent md:pr-[9px] xl:pr-2',
            overlayVisible && 'z-20 bg-white',
            inputClassName,
          )}
        >
          <DropdownMenuPrimitive.Trigger className="w-full focus-visible:rounded-full focus-visible:outline-gray-200">
            <SelectHeader
              onClick={() => {
                analyticServices.sendEvent({
                  action: AnalyticsEvent.openCurrency,
                  category: AnalyticsCategory.deposit,
                  label: 'openCurrency',
                });
              }}
              label={t('deposits.currency')}
              imgSrc={CURRENCY_IMAGES[selectedCurrency]!.src}
              imgAlt="selected currency"
              inFocus={overlayVisible}
              className={buttonClassName}
            >
              {selectedCurrency.toUpperCase()}
            </SelectHeader>
          </DropdownMenuPrimitive.Trigger>
          {children}
        </div>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white py-2',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[11px] overflow-y-auto">
              <div className="h-[312px] px-3 pb-3">
                {Object.entries(CURRENCY_IMAGES).map(
                  (
                    [currency, currency_image]: [string, StaticImageData],
                    index,
                  ) => (
                    <Fragment key={index}>
                      <DropdownMenuPrimitive.Item asChild>
                        <button
                          onClick={() => {
                            handleCurrencyItemClick(currency);
                          }}
                          className={cn(
                            'flex w-full content-center items-center justify-start gap-2 px-4 py-3 text-[15px] font-bold hover:rounded-full hover:bg-grey-100 focus-visible:rounded-full focus-visible:outline-gray-100',
                          )}
                          type="button"
                        >
                          {index > 0 && (
                            <Image
                              src={currency_image}
                              alt={currency}
                              className="size-5"
                            />
                          )}
                          {index === 0 && selectedCurrency === currency ? (
                            <Image
                              src={currency_image}
                              alt={currency}
                              className="size-5"
                            />
                          ) : (
                            index === 0 && (
                              <div className="size-5 shrink-0 rounded-full border-[1.5px] border-gray-200 bg-white hover:border-blue-200" />
                            )
                          )}
                          <p
                            className={cn(
                              'uppercase text-blue-900',
                              selectedCurrency === currency
                                ? 'body2-bold'
                                : 'body2',
                            )}
                          >
                            {currency}
                          </p>
                          {index > 0 && selectedCurrency === currency && (
                            <Icon
                              name="Tik"
                              className="ml-auto size-5 text-transparent"
                            />
                          )}
                        </button>
                      </DropdownMenuPrimitive.Item>
                    </Fragment>
                  ),
                )}
              </div>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
