export const prepareMarkDisadvantage = (value: string, translation: string) => {
  const loverCaseValue = value.toLowerCase();
  return (
    loverCaseValue.includes(translation.toLowerCase()) ||
    loverCaseValue.includes('no')
  );
};

export const prepareMarkAdvantage = (value: string, translation: string) => {
  const loverCaseValue = value.toLowerCase();

  return (
    loverCaseValue.includes(translation.toLowerCase()) ||
    loverCaseValue.includes('yes')
  );
};

export const translateinterestPaymentFrequency = (value: string) => {
  switch (value.toLowerCase()) {
    case 'annually':
      return 'deposit_detail_card.interest_payment_frequency.annually';
    case 'at maturity':
      return 'deposit_detail_card.interest_payment_frequency.maturity';
    case 'at expiry':
      return 'deposit_detail_card.interest_payment_frequency.expiry';
    case 'at expiry with capitalisation':
      return 'deposit_detail_card.interest_payment_frequency.expiry_with_capitalisation';
    case 'quarterly':
      return 'deposit_detail_card.interest_payment_frequency.quarterly';
    case 'quarterly, semiannually':
      return 'deposit_detail_card.interest_payment_frequency.quarterly_semiannually';
    case 'daily':
      return 'deposit_detail_card.interest_payment_frequency.daily';
    case 'semiannually':
      return 'deposit_detail_card.interest_payment_frequency.semiannually';
    case 'semi-annually':
      return 'deposit_detail_card.interest_payment_frequency.semi-annually';
    case 'monthly or at expiry':
      return 'deposit_detail_card.interest_payment_frequency.monthly_or_at_expiry';
    case 'monthly':
      return 'deposit_detail_card.interest_payment_frequency.monthly';
    case 'accrued every two weeks and paid annually':
      return 'deposit_detail_card.interest_payment_frequency.accrued_every_two_weeks_and_paid_annually';
    case 'monthly, with capitalisation':
      return 'deposit_detail_card.interest_payment_frequency.monthly_with_capitalisation';
    case 'annually, at expiry':
      return 'deposit_detail_card.interest_payment_frequency.annually_at_expiry';
    case 'monthly, bi-monthly, quarterly, semi-annually, annually':
      return 'deposit_detail_card.interest_payment_frequency.monthly_bi-monthly_quarterly_semi-annually_annually';
    default:
      return value;
  }
};
