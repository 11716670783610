import * as amplitude from '@amplitude/analytics-browser';

import type { IAnalyticEvent, IAnalyticService } from '@/types/analytics';

export class AmplitudeAnalyticService implements IAnalyticService {
  public constructor(private readonly id: string) {}

  public init(): void {
    amplitude.init(this.id, {
      serverZone: 'US',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async sendEvent(event: IAnalyticEvent): Promise<void> {
    amplitude.logEvent(event.action, {
      category: event.category,
      label: event.label,
      value: event.value,
      payload: event.payload,
    });
  }
}
