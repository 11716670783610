import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC } from 'react';
import React, { Fragment, useMemo, useState } from 'react';

import { CustomOverlay } from '@/components/CustomOverlay';
import { Flag } from '@/components/Flag';
import { Icon } from '@/components/Sprite';
import { cn, getFlagEmoji } from '@/utils';

export interface SelectInputOption {
  title: string | number;
  value: string | number | boolean;
  prefix?: string;
  conflict?: boolean;
}
interface ISelectInputProps {
  className?: string;
  isScrolling?: boolean;
  placeholder: string;
  value: SelectInputOption | undefined;
  options: SelectInputOption[];
  onChange: (newValue: SelectInputOption) => void;
  disabled?: boolean;
  type?: 'text' | 'country';
  errorText?: string;
}

export const SelectInput: FC<ISelectInputProps> = ({
  className,
  isScrolling,
  placeholder,
  value,
  options,
  onChange,
  disabled,
  type = 'text',
  errorText,
}) => {
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleCountryItemClick = (newValue: SelectInputOption) => {
    if (value?.value === newValue.value) return;
    onChange(newValue);
  };

  const containerStyles = useMemo(() => {
    if (disabled) {
      return 'cursor-not-allowed bg-grey-200 text-grey-500';
    }
    if (errorText) {
      return 'bg-white border-red-500';
    }
    return 'bg-white text-grey-800';
  }, [disabled]);

  const textStyles = useMemo(() => {
    if (disabled) {
      return 'bg-grey-200 text-grey-500';
    }
    return 'bg-white';
  }, [disabled]);

  const isMobile = window.innerWidth <= 768; // Change the width according to your mobile requirements

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      {isMobile ? (
        <div className="w-full appearance-none overflow-hidden rounded-xl border-[1.25px] border-grey-200 bg-white">
          {value?.value !== undefined && (
            <span className="caption absolute left-[17px] top-2 block text-grey-800">
              {placeholder}
            </span>
          )}
          <select
            value={value?.value.toString() || ''}
            disabled={disabled}
            className={cn(
              'appearance-none w-full h-full overflow-hidden',
              containerStyles,
              value?.value.toString() ? 'pt-6 pb-2 px-4' : 'p-4',
            )}
            onChange={(e) => {
              let localValue: string | boolean | undefined = e.target.value;
              if (localValue === 'true') localValue = true;
              if (localValue === 'false') localValue = false;
              const selectedOption = options.find(
                (option) => option.value === localValue,
              );
              if (selectedOption) {
                onChange(selectedOption);
              }
            }}
          >
            <option value="" disabled>
              {value?.value || placeholder}
            </option>
            {options.map((option, index) => (
              <option
                className="pt-14"
                key={index}
                value={option.value as string}
              >
                {option.prefix && (
                  <span className="mr-2">
                    {type === 'country'
                      ? getFlagEmoji(option.prefix)
                      : option.prefix}
                  </span>
                )}{' '}
                {option.title}
              </option>
            ))}
          </select>
          <Icon
            className="absolute right-4 top-1/2 z-10 -translate-y-1/2 stroke-2 text-transparent"
            name="arrowDownIcon"
          />
        </div>
      ) : (
        <DropdownMenuPrimitive.Root
          modal={isScrolling}
          onOpenChange={() => setOverlayVisible(!overlayVisible)}
        >
          {overlayVisible && <CustomOverlay />}
          <DropdownMenuPrimitive.Trigger disabled={disabled} asChild>
            <button
              tabIndex={0}
              type="button"
              className={cn(
                'focus-visible:outline-gray-200 border-[1.25px] border-grey-200 flex h-[56px] flex-row justify-between items-center focus-visible:rounded-xl bg-white relative rounded-xl flex-grow-1 w-full px-4',
                overlayVisible && 'bg-white z-20',
                containerStyles,
              )}
              disabled={disabled}
            >
              {value?.value !== undefined ? (
                <div>
                  <p
                    className={`caption whitespace-nowrap text-left ${textStyles}`}
                  >
                    {placeholder}
                  </p>
                  <div className="flex w-full justify-between">
                    <div className="body2-bold flex w-full min-w-0 grow-[1] content-center items-center justify-start gap-2 rounded-md font-bold text-black">
                      {value.prefix && (
                        <span>
                          {type === 'country' ? (
                            <Flag countryCode={value.prefix} />
                          ) : (
                            <span>{value.prefix}</span>
                          )}
                        </span>
                      )}
                      <p className={`truncate text-[15px] ${textStyles}`}>
                        {value.title}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-[15px] font-medium text-grey-800">
                  {placeholder}
                </p>
              )}
              <Icon
                className="mt-1 min-w-0 shrink-0 stroke-2 text-transparent"
                name="arrowDownIcon"
              />
            </button>
          </DropdownMenuPrimitive.Trigger>
          <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
              side="bottom"
              align="start"
              className="shadow-far z-20 my-2 max-h-[325px] w-full rounded-[31px] bg-white"
            >
              <div className="shadow-far z-50 max-h-[328px] w-[230px] rounded-[31px] bg-white">
                <div className="custom-scroll overflow-y-auto">
                  <div className="max-h-[310px] overflow-visible px-3">
                    {options.map((option, index) => (
                      <Fragment key={index}>
                        <DropdownMenuPrimitive.Item
                          textValue={option.title.toString()}
                          asChild
                        >
                          <button
                            onClick={() => {
                              handleCountryItemClick(option);
                            }}
                            className={cn(
                              value?.value === option.value &&
                                'bg-grey-100 rounded-full',
                              'focus-visible:outline-gray-100 focus-visible:rounded-full flex w-full content-center items-center justify-start px-4 pb-3 pt-[10px] gap-2 text-[15px] font-bold',
                            )}
                            type="button"
                          >
                            {option.prefix && (
                              <span>
                                {type === 'country' ? (
                                  <Flag countryCode={option.prefix} />
                                ) : (
                                  <span>{option.prefix}</span>
                                )}
                              </span>
                            )}
                            <p className="truncate">{option.title}</p>
                          </button>
                        </DropdownMenuPrimitive.Item>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </DropdownMenuPrimitive.Content>
          </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
      )}
      {errorText && (
        <p className="caption ml-[18px] text-red-500">{errorText}</p>
      )}
    </div>
  );
};

export default SelectInput;
