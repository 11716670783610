import type { KeyboardEvent, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import type { IDepositTerm } from '@/store/storeSearch.types';

export const useTermsSelect = (
  onChange: (terms: IDepositTerm[]) => void,
  _terms: IDepositTerm[] = [],
) => {
  const [changeTrigger, setChangeTrigger] = useState(0);
  const [terms, _setTerms] = useState<IDepositTerm[]>([]);
  const [activeTerms, setActiveTerms] = useState<IDepositTerm[]>([]);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const allTermsIsActive = activeTerms.length === 0;

  function updateChangeTrigger() {
    setChangeTrigger((v) => v + 1);
  }

  function setTerms(__terms: IDepositTerm[]) {
    let newTerms = [...__terms];
    let newActiveTerms = __terms.filter((o) => o.selected);

    // If all types are selected, then it's fair to show only the "All" checkbox selected
    if (newActiveTerms.length === __terms.length) {
      const arr = terms.map((op) => ({
        ...op,
        selected: false,
      }));

      newTerms = arr;
      newActiveTerms = [];
    }

    _setTerms(newTerms);
    setActiveTerms(newActiveTerms);
    updateChangeTrigger();
  }

  const deselectAllTerms = () => {
    const arr = terms.map((op) => ({
      ...op,
      selected: false,
    }));

    _setTerms(arr);
    setActiveTerms([]);
    updateChangeTrigger();
  };

  const toggleTerm = (index: number) => {
    const arr = [...terms];
    arr[index]!.selected = !arr[index]!.selected;

    setTerms(arr);
  };

  function handleKeyDown(e: KeyboardEvent<HTMLButtonElement>, cb: () => void) {
    if ([' ', 'Spacebar', 'Enter'].includes(e.key)) {
      e.preventDefault();

      cb();
    }
  }

  function handleClick(e: MouseEvent<HTMLButtonElement>, cb: () => void) {
    e.preventDefault();

    cb();
  }

  // For input changes
  useEffect(() => {
    let newTerms = [..._terms];
    let newActiveTerms = _terms.filter((o) => o.selected);

    // If all types are selected, then it's fair to show only the "All" checkbox selected
    if (newActiveTerms.length === _terms.length) {
      const arr = terms.map((op) => ({
        ...op,
        selected: false,
      }));

      newTerms = arr;
      newActiveTerms = [];
    }

    _setTerms(newTerms);
    setActiveTerms(newActiveTerms);
  }, [_terms]);

  // For output changes
  useEffect(() => {
    // Prevents triggering when switch between mob. and desktop modes
    if (changeTrigger === 0) return;

    onChange(terms);
  }, [changeTrigger]);

  return {
    terms,
    setTerms,
    overlayVisible,
    setOverlayVisible,
    activeTerms,
    allTermsIsActive,
    deselectAllTerms,
    toggleTerm,
    handleKeyDown,
    handleClick,
  };
};
