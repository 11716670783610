import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@/components';
import { VerifyInput } from '@/components/Dialog/parts/VerifyInput';
import { useApi } from '@/contexts/ApiContext';
import { useModal } from '@/contexts/ModalContext';
import confirmEmailPng2x from '@/public/assets/pics/confirmEmail@2x-min.png';
import confirmEmailWebp2x from '@/public/assets/pics/confirmEmail@2x-min.webp';
import confirmEmailPng1x from '@/public/assets/pics/confirmEmail-min.png';
import confirmEmailWebp1x from '@/public/assets/pics/confirmEmail-min.webp';
import { useSMSConfirmationStore } from '@/store/smsConfirmationStore';

export interface SMSConfirmationProps {
  onSuccess: () => void;
  verificationUuid: string;
  onSubmit: (code: string) => Promise<boolean>;
}
export const SMSConfirmation: FC<SMSConfirmationProps> = ({
  onSuccess,
  verificationUuid,
  onSubmit,
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const { t } = useTranslation();
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { lastTriggerDate, setLastTriggerDate } = useSMSConfirmationStore(
    (state) => state,
  );

  const { closeModal } = useModal();
  const { postComplianceVerificationAction } = useApi();

  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
    }, 1000);
    return () => clearInterval(interval);
  }, [lastTriggerDate]);

  const handleSubmit = async (code: string) => {
    try {
      setIsLoading(true);
      const result = await onSubmit(code);
      if (!result) {
        setIsCodeWrong(true);
        return;
      }
      setIsCodeWrong(false);
      onSuccess();
      closeModal();
    } catch (e) {
      setIsCodeWrong(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteVerificationInput = (code: string) => {
    setVerificationCode(code);
    handleSubmit(code);
  };

  const sendSMSVerification = useCallback(async () => {
    try {
      if (lastTriggerDate) {
        const diff = Math.floor(
          (new Date().getTime() - lastTriggerDate.getTime()) / 1000,
        );
        if (diff < 60) {
          setTimer(60 - diff);
        } else {
          await postComplianceVerificationAction(verificationUuid);
          setTimer(60);
          setLastTriggerDate(new Date());
        }
      } else {
        await postComplianceVerificationAction(verificationUuid);
        setLastTriggerDate(new Date());
        setTimer(60);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    sendSMSVerification();
  }, []);

  return (
    <div className="flex w-[452px] flex-col items-center text-center">
      <picture>
        <source
          srcSet={`${confirmEmailWebp1x.src} 1x, ${confirmEmailWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${confirmEmailPng1x.src} 1x, ${confirmEmailPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={confirmEmailPng1x.src}
          alt="confirmEmail"
          width={confirmEmailPng1x.width}
          height={confirmEmailPng1x.height}
        />
      </picture>
      <p className="body1-bold mt-4 text-blue-900">
        {t('compliance.sms_verification.title')}
      </p>
      <div className="w-[260px]">
        <div className="mt-4">
          <VerifyInput onComplete={handleCompleteVerificationInput} />
        </div>
      </div>
      <p className="body2 mt-4 text-grey-800">
        {t('compliance.sms_verification.subtitle')}
      </p>
      {isCodeWrong && (
        <p className="body2 mt-2 text-red-500">
          {t('compliance.sms_verification.invalid_code')}
        </p>
      )}
      <Button
        color="blue"
        className="mt-6 w-full py-[18px]"
        props={{
          disabled: !verificationCode,
          onClick: () => handleSubmit(verificationCode),
        }}
        loading={isLoading}
      >
        {t('compliance.verification_status.action')}
      </Button>
      <button
        type="button"
        className="body3 mx-auto mt-4 block text-blue-500 hover:text-blue-900 disabled:text-grey-600"
        onClick={sendSMSVerification}
        disabled={timer > 0}
      >
        {timer > 0
          ? t('compliance.sms_verification.resend_sms', { seconds: timer })
          : t('compliance.sms_verification.didnt_receive_code')}
      </button>
    </div>
  );
};
