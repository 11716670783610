import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';

import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import PortfolioDepositStat from '@/layouts/PortfolioLayout/parts/PortfolioDepositStat';
import { getBankLogoURL } from '@/services/supabase';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import { analyticServices, cn, transformMonths } from '@/utils';

interface BestProductItemProps {
  product: IBankDepositCard;
  className?: string;
  onClick?: () => void;
}
export const BestProductItem: FC<BestProductItemProps> = ({
  product,
  className,
  onClick,
}) => {
  const { t } = useTranslation();
  const { value: termValue, unit: termUnit } = transformMonths(
    +product.termMonths,
  );
  const { getRedirectUrl, redirectToExternalLink } = useExternalRedirect();
  const { showQuestionOpenDepositModal } = useShowModal();

  const sendAnalytics = async (typeEvent: AnalyticsEvent) => {
    analyticServices.sendEvent({
      action: typeEvent,
      category: AnalyticsCategory.deposit,
      label: 'Best offers modal',
      payload: {
        bank_index: product.bankIndex,
        product_index: product.productIndex,
      },
    });
  };

  return (
    <div
      className={cn(
        'relative cursor-pointer before:content-[""] z-10 before:-left-4 before:-right-4 before:absolute before:top-0 hover:before:bg-blue-500 before:z-0 before:opacity-5 before:rounded-lg  before:h-full',
        className,
      )}
    >
      <Link
        href={getRedirectUrl(product)}
        onClick={(e) => {
          e.preventDefault();
          if (onClick) {
            onClick();
          }
          sendAnalytics(AnalyticsEvent.openOnline);
          redirectToExternalLink({
            onSuccess: () => {
              showQuestionOpenDepositModal({
                productIndex: product.productIndex,
              });
            },
            bank: product,
          });
        }}
        target="_blank"
        className={cn('flex gap-6 relative items-start justify-between py-6')}
      >
        <div>
          <div className="flex gap-1.5 break-words">
            <Image
              src={getBankLogoURL(product.bankIndex.toString())}
              alt="bankImage"
              width={38}
              height={38}
              unoptimized
              priority
              className="size-[38px] rounded-full border bg-blue-900/10"
            />
            <div className="flex flex-col gap-0.5">
              <p className="body2 line-clamp-2 text-blue-900">
                {product.bankName}
              </p>
              {product.country && (
                <p className="body3 text-grey-600">
                  {t(`database.countries.${product.country}`)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="shrink-0">
          <PortfolioDepositStat
            title={`${product.grossInterestRate}%`}
            description={`${termValue} ${t(
              `deposit_card.${termUnit.toLowerCase()}`,
            )}`}
          />
        </div>
      </Link>
    </div>
  );
};
