import type { FC } from 'react';
import React from 'react';

import { cn } from '@/utils';

interface TagLabelProps {
  text: string;
  color?: 'blue' | 'green';
  className?: string;
}
export const TagLabel: FC<TagLabelProps> = ({
  text,
  className,
  color = 'blue',
}) => {
  const STYLES = {
    blue: 'bg-blue-500',
    green: 'bg-blue-500',
  };

  return (
    <span
      className={cn(
        'body3-bold text-white px-3 pt-1 pb-1.5 rounded-full block w-max',
        STYLES[color],
        className,
      )}
    >
      {text}
    </span>
  );
};
