import type { StaticImageData } from 'next/image';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import Flag_ENG from '@/public/assets/countries/ENG.svg';
import Flag_FI from '@/public/assets/countries/Finland.svg';
import Flag_FR from '@/public/assets/countries/France.svg';
import Flag_DE from '@/public/assets/countries/Germany.svg';
import Flag_EL from '@/public/assets/countries/Greece.svg';
import Flag_IT from '@/public/assets/countries/Italy.svg';
import Flag_LT from '@/public/assets/countries/Lithuania.svg';
import Flag_NL from '@/public/assets/countries/Netherlands.svg';
import Flag_PT from '@/public/assets/countries/Portugal.svg';
import Flag_ES from '@/public/assets/countries/Spain.svg';
import Flag_SV from '@/public/assets/countries/Sweden.svg';

export type LanguageKeys =
  | 'en'
  | 'de'
  | 'nl'
  | 'es'
  | 'it'
  | 'fr'
  | 'pt'
  | 'el'
  | 'sv'
  | 'lt'
  | 'fi';

export interface ILocalization {
  flagImg: StaticImageData;
  title: string;
  shortName: string;
  locale: LanguageKeys;
  link: string;
  notSupported: boolean;
}

export type LocalizationsType = { [key: string]: ILocalization };

interface I18nState {
  localizations: LocalizationsType;
}

interface I18nActions {
  setLocalizations: (payload: LocalizationsType) => void;
  resetLocalizations: () => void;
}

export const LOCALIZATIONS: LocalizationsType = {
  en: {
    flagImg: Flag_ENG,
    title: 'English',
    shortName: 'En',
    locale: 'en',
    link: '/',
    notSupported: false,
  },
  de: {
    flagImg: Flag_DE,
    title: 'German',
    shortName: 'De',
    locale: 'de',
    link: '/',
    notSupported: false,
  },
  nl: {
    flagImg: Flag_NL,
    title: 'Dutch',
    shortName: 'Nl',
    locale: 'nl',
    link: '/',
    notSupported: false,
  },
  es: {
    flagImg: Flag_ES,
    title: 'Spanish',
    shortName: 'Es',
    locale: 'es',
    link: '/',
    notSupported: false,
  },
  it: {
    flagImg: Flag_IT,
    title: 'Italian',
    shortName: 'It',
    locale: 'it',
    link: '/',
    notSupported: false,
  },
  fr: {
    flagImg: Flag_FR,
    title: 'French',
    shortName: 'Fr',
    locale: 'fr',
    link: '/',
    notSupported: false,
  },
  pt: {
    flagImg: Flag_PT,
    title: 'Portuguese',
    shortName: 'Pt',
    locale: 'pt',
    link: '/',
    notSupported: false,
  },
  el: {
    flagImg: Flag_EL,
    title: 'Greek',
    shortName: 'El',
    locale: 'el',
    link: '/',
    notSupported: false,
  },
  sv: {
    flagImg: Flag_SV,
    title: 'Swedish',
    shortName: 'Sv',
    locale: 'sv',
    link: '/',
    notSupported: false,
  },
  lt: {
    flagImg: Flag_LT,
    title: 'Lithuanian',
    shortName: 'Lt',
    locale: 'lt',
    link: '/',
    notSupported: false,
  },
  fi: {
    flagImg: Flag_FI,
    title: 'Finnish',
    shortName: 'Fi',
    locale: 'fi',
    link: '/',
    notSupported: false,
  },
};

const initialState: I18nState = {
  localizations: LOCALIZATIONS,
};

export const useI18nStore = create<I18nState & I18nActions>()(
  devtools(
    (set) => ({
      ...initialState,
      setLocalizations: (payload) =>
        set(() => ({
          localizations: payload,
        })),
      resetLocalizations: () =>
        set(() => ({
          localizations: LOCALIZATIONS,
        })),
    }),
    {
      enabled: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prod',
    },
  ),
);

// This function is used to prepare Strapi localizations for the storeI18n
export const prepareToLocalizations = (
  data: {
    id: number;
    attributes: { [key: string]: string; locale: string };
  }[],
  key: 'slug' | 'link',
  prefix = '',
  activeLink = '/',
  activeLocale = 'en',
): LocalizationsType => {
  const localizations: LocalizationsType = {
    ...LOCALIZATIONS,
  };

  Object.keys(LOCALIZATIONS).forEach((k) => {
    const option = localizations[k];
    if (option !== undefined) {
      option.notSupported = true;
      option.link = '/';
    }
  });

  const defaultOption = localizations[activeLocale];
  if (defaultOption !== undefined) {
    localizations[activeLocale]!.notSupported = false;
    localizations[activeLocale]!.link = `${prefix}${
      Array.isArray(activeLink) ? activeLink.join('/') : activeLink
    }`;
  }

  data.forEach((item) => {
    const { attributes } = item;
    const { locale } = attributes;
    const link = attributes[key];

    if (!link) {
      throw new Error("Can't not find a link via proposed key");
    }

    const option = localizations[locale] as ILocalization;
    if (option) {
      option.link = `${prefix}${Array.isArray(link) ? link.join('/') : link}`;
      option.notSupported = false;
    }
  });

  return localizations;
};
