import { Disclosure, Transition } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';

import { DepositProductCard } from '@/components/DepositProductCard';
import { Icon } from '@/components/Sprite';
import { useAuthStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { cn } from '@/utils';

export interface ICardDetailsProps {
  bank: IBankDepositCard;
  isDetailsOpen?: boolean;
  toggleDetails?: () => void;
  isBlogBankCard?: boolean;
}

export const CardDetails: FC<ICardDetailsProps> = ({
  bank,
  isDetailsOpen,
  toggleDetails,
  isBlogBankCard = false,
}) => {
  const { t } = useTranslation();
  const { isTestUser } = useAuthStore((state) => state);

  return (
    <div className="hidden w-full rounded-b-xl bg-white lg:flex">
      <Disclosure as="div" className="w-full">
        <Disclosure.Button
          onClick={toggleDetails}
          className="hidden w-full cursor-pointer gap-1 rounded-b-xl px-6 pb-4 lg:flex lg:h-[34px] lg:items-center lg:justify-start"
        >
          <p className="body3 text-blue-500 hover:text-blue-600">
            {isDetailsOpen
              ? t('global.less_details')
              : t('global.more_details')}
          </p>
          {isDetailsOpen ? (
            <Icon
              className="h-4 w-4 rotate-180 text-blue-500"
              name="shewronDown"
            />
          ) : (
            <Icon className="h-4 w-4 text-blue-500" name="shewronDown" />
          )}
        </Disclosure.Button>
        <Transition show={isDetailsOpen}>
          <Disclosure.Panel
            static
            className="w-full border-t pt-4 text-base text-[#474747]"
          >
            <DepositProductCard
              bank={bank}
              className={cn(
                'mx-auto divide-y divide-grey-200 last-child:pb-8 lg:w-full lg:px-6 xl:w-[940px] xl:px-0',
                isBlogBankCard && 'xl:w-full xl:px-16',
              )}
            />

            <div className="hidden gap-2 border-t px-2 lg:flex lg:max-h-[108px] lg:min-h-[108px] lg:content-center lg:items-center lg:justify-end lg:px-6">
              <Link
                href={t('faq_page.question_1.link')}
                className="body2-bold mb-8 mt-5 flex h-[56px] w-full items-center justify-center text-blue-500 hover:text-blue-600 md:w-[160px]"
              >
                {t('deposit_card.button_how_to_choose')}
              </Link>

              <Link
                href={{
                  pathname: `/deposits/${bank.productIndex}`,
                  query: isTestUser ? { test_user: isTestUser } : undefined,
                }}
                className="mb-8 mt-5 flex min-h-[56px] min-w-[160px] content-center items-center justify-center rounded-full bg-blue-100 px-6 font-bold text-blueColor hover:bg-blue-150"
              >
                {t('deposit_card.button_more_info')}
              </Link>
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  );
};
