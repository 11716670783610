import { transformNumber } from './functions';

export const calcDepositTerms = (
  minimumAmount: string | null,
  maximumAmount: string | null,
) => {
  const terms = {
    prefix: '',
    displayAmount: '',
    isAnyAmount: false,
  };

  const minAmount = Number(minimumAmount) || 0;
  const maxAmount = Number(maximumAmount) || 0;

  if (minAmount > 0 && maxAmount > 0) {
    return {
      prefix: '',
      displayAmount: `${transformNumber(minAmount)}–${transformNumber(
        maxAmount,
      )}`,
      isAnyAmount: false,
    };
  }

  if (minAmount > 0) {
    return {
      prefix: 'deposit_card.from',
      displayAmount: transformNumber(minAmount).toString(),
      isAnyAmount: false,
    };
  }

  if (maxAmount > 0) {
    return {
      prefix: 'deposit_card.up_to',
      displayAmount: transformNumber(maxAmount).toString(),
      isAnyAmount: false,
    };
  }

  if (maxAmount === 0 && minAmount === 0) {
    return {
      prefix: '',
      displayAmount: 'deposit_card.any_amount',
      isAnyAmount: true,
    };
  }

  return terms;
};

export const checkIsNumber = (value: string) => {
  return /^\d+$/.test(value);
};
