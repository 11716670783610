import type { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import { forwardRef } from 'react';

import { Icon } from '@/components/Sprite';
import { cn } from '@/utils';

export interface SelectHeaderProps {
  label: React.ReactNode;
  children: React.ReactNode;
  imgSrc?: string | StaticImport;
  imgAlt?: string;
  inFocus?: boolean;
  tabIndex?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const SelectHeader = forwardRef<HTMLDivElement, SelectHeaderProps>(
  (props, ref) => {
    const {
      label,
      children,
      imgSrc,
      imgAlt,
      inFocus,
      tabIndex,
      className,
      onClick = () => {},
      onKeyDown = () => {},
    } = props;

    return (
      <div
        ref={ref}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        className={cn(
          'w-full relative search-input flex-grow-1 hover:bg-gray-100',
          inFocus && 'bg-white z-20',
          className,
        )}
      >
        <p className="caption whitespace-nowrap text-left">{label}</p>
        <div className="flex w-full justify-between gap-2">
          <div className="body2-bold flex w-full min-w-0 grow-[1] content-center items-center justify-start gap-2 rounded-md font-bold text-black">
            {imgSrc && (
              <Image
                src={imgSrc}
                alt={imgAlt!}
                className="h-auto w-5 rounded-sm"
                width="32"
                height="24"
                unoptimized
              />
            )}
            <span className={cn('text-[15px] truncate')}>{children}</span>
          </div>
          <Icon
            className="mt-1 min-w-0 shrink-0 stroke-2 text-transparent"
            name="arrowDownIcon"
          />
        </div>
      </div>
    );
  },
);

SelectHeader.displayName = 'SelectHeader';
