import 'swiper/css';

import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';

import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

import { Container } from '../Container';
import { MainTitle } from '../MainTitle';
import { Icon } from '../Sprite';
import { DepositCard } from './parts';

export interface ILandingDepositInfo {
  bankName: string;
  country: string;
  interestRate: string;
  term: string;
  image: string;
  productIndex: number;
  bankIndex: string;
}
interface PickDepositsProps {
  deposits: IBankDepositCard[];
}

export const PickDeposits: FC<PickDepositsProps> = ({ deposits }) => {
  const { t } = useTranslation();

  const handleShowAllDeposits = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickShowAllDeposits,
      category: AnalyticsCategory.deposit,
      label: 'clickShowAllDeposits',
    });
  };

  return (
    <Container>
      <MainTitle title="ourPick.title" className="md:text-start" />

      <p className="body1 mt-2 text-center tracking-[0.1px] text-grey-800 md:text-left">
        {t('ourPick.description.text_1')}
        <span className="body1-bold text-blue-900">
          {` ${t('ourPick.description.text_2_bold')} `}
        </span>
        {t('ourPick.description.text_3')}
      </p>

      <div className="no-scrollbar -mx-5 mt-9 flex justify-between overflow-x-auto px-5 child:gap-6 sm:mx-0 sm:px-0 md:mt-12 child:md:justify-between child:md:gap-0">
        {deposits.map((deposit) => (
          <div
            key={deposit.productIndex}
            className={cn(
              'bg-white rounded-2xl mr-6 last:mr-0 px-4 py-5 md:px-0 md:py-0 md:bg-transparent w-fit min-w-[204px] max-w-[204px] pr-6 xl:min-w-[266px] xl:max-w-[266px] cursor-pointer',
            )}
          >
            <DepositCard depositInfo={deposit} />
          </div>
        ))}
      </div>

      <div className="mt-9 flex content-center items-center justify-center pb-12 md:mt-12 md:pb-24">
        <Link
          href="/fixed-deposits"
          type="button"
          onClick={handleShowAllDeposits}
          className="body2-bold flex content-center items-center gap-1 rounded-full bg-blue-500 px-8 py-[21px] hover:bg-blue-550 md:px-6 md:py-5 "
        >
          <span className="body2-bold tracking-[0.2px] text-white md:mr-1">
            {t('global.show_all_deposits')}
          </span>

          <Icon name="arrowRightWhite" className="size-4 text-transparent" />
        </Link>
      </div>
    </Container>
  );
};
