import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import type { IBankDepositCard } from '@/types';
import { cn } from '@/utils';

interface IDepositTermsProps {
  className?: string;
  bank: IBankDepositCard;
  isBlogBankCard?: boolean;
}

export const SavingsAccountsTerms: FC<IDepositTermsProps> = ({
  className,
  bank,
  isBlogBankCard = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'content-start items-start justify-start gap-6 text-center text-grayColor md:flex',
        className,
        isBlogBankCard && 'gap-4',
      )}
    >
      <p
        className={cn(
          'text-right h4 tracking-[0.2px] text-blue-900 md:text-left md:w-[96.6px] xl:w-[130px]',
          isBlogBankCard &&
            'xl:text-[24px] xl:font-semibold xl:leading-7 xl:tracking-[0.2px]',
        )}
      >
        {Number(bank.grossInterestRate).toFixed(2)}%
      </p>
      <div
        className={cn(
          'hidden md:block xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
          isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
        )}
      >
        <p className="caption text-grey-500">{t('global.annual_fee')}</p>
        <p className="body2-bold mt-0.5 text-blue-900">
          {bank.annualFee
            ? t(`savings-accounts.annual-fee.${bank.annualFee}`)
            : '-'}
        </p>
      </div>
      <div
        className={cn(
          'hidden md:block xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
          isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
        )}
      >
        <p className="caption text-grey-500">{t('global.cashback')}</p>
        <p className="body2-bold mt-0.5 text-blue-900">
          {bank.cashback
            ? t(`savings-accounts.cashback.${bank.cashback}`)
            : t(`savings-accounts.cashback.no`)}
        </p>
      </div>
    </div>
  );
};
