import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { cn } from '@/utils';

import { InfiniteLooper } from '../InfiniteLooper';
import { BankLogos } from './parts';

interface LogoCloudsProps {
  label?: string;
}
export const LogoClouds: FC<LogoCloudsProps> = ({ label }) => {
  const { t } = useTranslation();

  return (
    <div className="relative mb-7 w-full md:flex md:flex-wrap md:items-center lg:mb-[26px] lg:flex-nowrap ">
      <p className="body2 w-full text-center tracking-[0.2px] text-grey-800 md:text-start lg:w-[22%] xl:w-[17%]">
        {label || t('hero_section.partnership.text')}
      </p>

      <div className="absolute bottom-0 left-0 z-10 hidden w-8 bg-bank-logo-gradient-right md:block md:h-[38px] lg:left-[18%] xl:left-[14%]" />
      <div className="absolute bottom-0 right-0 z-10 hidden w-8 bg-bank-logo-gradient-left md:block md:h-[38px]" />

      <div className="no-scrollbar relative mt-4 flex w-full flex-wrap justify-center gap-5 overflow-x-auto child:shrink-0 md:hidden">
        <BankLogos />
      </div>

      <InfiniteLooper speed={90} direction="right">
        <div
          className={cn(
            'relative no-scrollbar flex w-full overflow-x-auto child:shrink-0 flex-wrap gap-5 justify-center mt-4',
            'md:overflow-x-scroll md:gap-[54px] child:md:h-[32px] md:flex-nowrap md:justify-between',
            'lg:gap-16 child:lg:h-[38px] xl:justify-between lg:mt-0',
          )}
        >
          <BankLogos />
        </div>
      </InfiniteLooper>
    </div>
  );
};
