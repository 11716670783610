import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@capgo/inappbrowser';
import { useTranslation } from 'next-i18next';

import type { IBankDepositCard } from '@/types';

export const useExternalRedirect = () => {
  const { i18n } = useTranslation();

  const getRedirectUrl = (bank: IBankDepositCard) => {
    const locale = i18n.language || 'en';
    const localePrefix = locale !== 'en' ? `/${locale}` : '';

    return `${localePrefix}/redirect/?bank_index=${
      bank.bankIndex
    }&product_index=${bank.productIndex}&bank_name=${encodeURIComponent(
      bank.bankName,
    )}${bank.partnerType && `&partner_type=${bank.partnerType}`}`;
  };

  const redirectToExternalLink = async ({
    bank,
    onSuccess,
  }: {
    bank: IBankDepositCard;
    onSuccess: () => void;
  }) => {
    if (['android', 'ios'].includes(Capacitor.getPlatform())) {
      await InAppBrowser.open({
        url: `https://pickthebank.eu/${getRedirectUrl(bank)}`,
      });
    } else {
      window.open(`${getRedirectUrl(bank)}`, '_blank');
    }
    onSuccess();
  };
  return {
    redirectToExternalLink,
    getRedirectUrl,
  };
};
