import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalsActions {
  setIsBurgerMenuOpen: (isModalShown: boolean) => void;
}
interface ModalsState {
  isBurgerMenuOpen?: boolean;
}

const initialState: ModalsState = {
  isBurgerMenuOpen: false,
};

export const useModalsStore = create<ModalsActions & ModalsState>()(
  devtools(
    (set) => ({
      ...initialState,
      setIsBurgerMenuOpen: (isModalShown: boolean) =>
        set({ isBurgerMenuOpen: isModalShown }),
    }),
    {
      name: 'modals::storage',
    },
  ),
);
