import Image from 'next/image';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { getBankLogoURL } from '@/services/supabase';
import { cn } from '@/utils';

interface IBankImageProps {
  bankIndex: string;
  className?: string;
}

export const BankImage: FC<IBankImageProps> = ({ bankIndex, className }) => {
  const [src, setSrc] = useState(bankIndex);

  useEffect(() => {
    setSrc(bankIndex);
  }, [bankIndex]);

  return (
    <div
      className={cn(
        'relative flex-shrink-0 overflow-hidden rounded-full border',
        className,
      )}
    >
      <Image
        src={getBankLogoURL(src)}
        alt={bankIndex}
        fill
        unoptimized
        priority
        onError={(e) => {
          if (e.target) {
            setSrc('mockup');
          }
        }}
      />
    </div>
  );
};
