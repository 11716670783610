import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { SEARCH_COUNTRIES } from '@/store/storeSearch.consts';
import { findCountryKey } from '@/utils/countries';
import { LIST_COUNTRIES } from '@/utils/flags';

type GetDestinationPath = (
  locale: string | undefined,
  destination: string,
) => string;

export interface UserCountry {
  currency: string;
  alpha2: string;
  name: string;
}

type ServerPropsActionCreator = (
  ctx: GetServerSidePropsContext,
  getDestinationPath: GetDestinationPath,
  userCountry: UserCountry,
) => Promise<Record<string, any>>;

type ActionResponse = Record<string, any> & {
  props: Record<string, any>;
};

export const getDestinationPath: GetDestinationPath = (
  locale = '',
  destination = '',
) => {
  const prefix = locale === 'en' ? '' : `/${locale}`;

  return `${prefix}${destination}`;
};

export const getUserCountry = (
  ctx: GetServerSidePropsContext | NextPageContext,
) => {
  const defaultCountry = {
    alpha2: 'DE',
    name: 'Germany',
    currency: 'eur',
  };
  if (ctx.query.country) {
    const country = findCountryKey(ctx.query.country as string);
    const lCountry = LIST_COUNTRIES.find((i) => i.name === country);
    if (lCountry) {
      return {
        alpha2: lCountry.iso2,
        name: lCountry.name,
        currency: lCountry.currency === 'EUR' ? 'eur' : 'all',
      };
    }
  }
  const headersCountry = ctx.req?.headers['cf-ipcountry'];
  const country = LIST_COUNTRIES.find((i) => i.iso2 === headersCountry);
  if (country && SEARCH_COUNTRIES.includes(country.name)) {
    return {
      alpha2: country.iso2,
      name: country.name,
      currency: country.currency === 'EUR' ? 'eur' : 'all',
    };
  }
  return defaultCountry;
};

export const getUserExactCountryCode = (
  ctx: GetServerSidePropsContext | NextPageContext,
): string => {
  return ctx.req?.headers['cf-ipcountry'] as string;
};

export const makeServerProps = () => {
  /* eslint-disable @typescript-eslint/no-use-before-define */
  const actionCreators: ServerPropsActionCreator[] = [];

  const withAction = (creator: ServerPropsActionCreator) => {
    actionCreators.push(creator);
    return that;
  };

  const build =
    () =>
    async (ctx: GetServerSidePropsContext): Promise<ActionResponse> => {
      const userCountry = getUserCountry(ctx);
      const actions = actionCreators.map((creator) =>
        creator(ctx, getDestinationPath, userCountry),
      );
      const responses = await Promise.all(actions);
      const result = responses.reduce<ActionResponse>(
        (acc, res = {}) => {
          const { props = {}, ...rest } = res;
          return { ...acc, ...rest, props: { ...acc.props, ...props } };
        },
        { props: {} },
      );

      return result;
    };

  const that = {
    withAction,
    build,
  };

  return that;
};
