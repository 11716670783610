import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAnalyticsStore {
  setFavorites: (favorites: any) => void;
}

interface IAnalyticsStoreState {
  favourites: any[];
}

const initialState: IAnalyticsStoreState = {
  favourites: [],
};

// if (typeof window !== 'undefined') {
//   const storedFavorites = localStorage.getItem('favorites::storage');
//   initialState = {
//     favourites: storedFavorites ? JSON.parse(storedFavorites) : [],
//   };
// }

export const useFavoritesStore = create<
  IAnalyticsStore & IAnalyticsStoreState
>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setFavorites: (favorites: any) => set({ favourites: favorites }),
      }),
      {
        name: 'favorites::storage',
      },
    ),
  ),
);
