import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomOverlay } from '@/components/CustomOverlay';
import { useDepositSelect } from '@/components/SearchBar/parts/hooks';
import { SelectCheckbox } from '@/components/SearchBar/parts/SelectCheckbox/SelectCheckbox';
import { SelectHeader } from '@/components/SearchBar/parts/SelectHeader';
import type { IDepositOperation } from '@/store/storeSearch.types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

import { SearchButton } from '../SearchButton';

interface ISelectDepositProps {
  types: IDepositOperation[];
  is_partner: boolean;
  isScrolling?: boolean;
  className?: string;
  dropdownClassname?: string;
  onChange: (types: IDepositOperation[], isPartner: boolean) => void;
  onSubmit: () => void;
}

export const DepositSelect: FC<ISelectDepositProps> = ({
  types: _types,
  is_partner,
  isScrolling,
  className,
  dropdownClassname,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const {
    types,
    // isPartner,
    overlayVisible,
    setOverlayVisible,
    activeTypes,
    allTypesIsActive,

    // toggleIsPartner,
    deselectAllTypes,
    toggleOperation,
    handleKeyDown,
    handleClick,
  } = useDepositSelect(_types, is_partner, onChange);

  return (
    <div className={cn('relative', className)}>
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => {
          setOverlayVisible(!overlayVisible);
        }}
      >
        {overlayVisible && <CustomOverlay />}
        <div
          className={cn(
            'relative flex grow-[1] min-w-0 gap-1 xl:gap-[10px] w-full items-center justify-between rounded-full hover:bg-gray-100 md:flex md:border-r md:border-transparent md:pr-[9px] xl:pr-2',
            overlayVisible && 'z-20 bg-white',
          )}
        >
          <DropdownMenuPrimitive.Trigger className="w-auto min-w-0 grow-[1] focus-visible:rounded-full focus-visible:outline-gray-200">
            <SelectHeader
              className="md:pl-7 xl:pr-7"
              onClick={() => {
                analyticServices.sendEvent({
                  action: AnalyticsEvent.openType,
                  category: AnalyticsCategory.deposit,
                  label: 'openType',
                });
              }}
              label={t('deposits.deposit_type')}
              inFocus={overlayVisible}
            >
              <p className="body2-bold min-w-0 grow truncate text-left text-[15px] capitalize text-blue-900">
                {activeTypes.map((operation, index) => (
                  <span key={operation.key}>
                    {t(`database.deposit type.${operation.key?.toLowerCase()}`)}
                    {index < activeTypes.length - 1 ? ', ' : ''}
                  </span>
                ))}
                {allTypesIsActive && t(`database.deposit type.all`)}
              </p>
            </SelectHeader>
          </DropdownMenuPrimitive.Trigger>

          <SearchButton
            onClick={onSubmit}
            className="w-auto shrink-0 cursor-pointer self-center focus-visible:rounded-full focus-visible:outline-blue-550"
          />
        </div>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            hideWhenDetached
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white pt-2 text-blue-900 md:left-[-180px] lg:-left-6 xl:left-2',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[10px] h-[350px] overflow-y-auto">
              <div className="px-2">
                <DropdownMenuPrimitive.CheckboxItem asChild>
                  <SelectCheckbox
                    isChecked={allTypesIsActive}
                    onClick={(e) => handleClick(e, deselectAllTypes)}
                    onKeyDown={(e) => handleKeyDown(e, deselectAllTypes)}
                  >
                    {t(`database.deposit type.all`)}
                  </SelectCheckbox>
                </DropdownMenuPrimitive.CheckboxItem>
                {/* <DropdownMenuPrimitive.CheckboxItem asChild> */}
                {/*  <SelectCheckbox */}
                {/*    isChecked={isPartner} */}
                {/*    onClick={(e) => handleClick(e, toggleIsPartner)} */}
                {/*    onKeyDown={(e) => handleKeyDown(e, toggleIsPartner)} */}
                {/*  > */}
                {/*    {t(`database.deposit type.${IS_PARTNER_KEY}`)} */}
                {/*  </SelectCheckbox> */}
                {/* </DropdownMenuPrimitive.CheckboxItem> */}
                <div className="mx-3 my-2 h-[1px] self-stretch border-t border-gray-200" />
                {types.map((operation, index) => (
                  <Fragment key={operation.key}>
                    <DropdownMenuPrimitive.CheckboxItem asChild>
                      <SelectCheckbox
                        isChecked={operation.selected}
                        onClick={(e) =>
                          handleClick(e, () => toggleOperation(index))
                        }
                        onKeyDown={(e) =>
                          handleKeyDown(e, () => toggleOperation(index))
                        }
                      >
                        {t(
                          `database.deposit type.${operation.key.toLowerCase()}`,
                        )}
                      </SelectCheckbox>
                    </DropdownMenuPrimitive.CheckboxItem>
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="px-3 pb-2">
              <DropdownMenuPrimitive.Item asChild>
                <button
                  className="flex h-[44px] w-full cursor-pointer items-center justify-center rounded-[32px] rounded-b-[31px] bg-blue-100 focus-visible:rounded-full focus-visible:outline-1 focus-visible:outline-blue-150"
                  type="button"
                >
                  <p className="body3-bold text-[15px] hover:text-blue-550">
                    {t('global.ok')}
                  </p>
                </button>
              </DropdownMenuPrimitive.Item>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
