import dynamic from 'next/dynamic';
import type { FC } from 'react';
import React from 'react';

const LazyLoaded: FC<{ children: React.ReactNode }> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
);

export default dynamic(() => Promise.resolve(LazyLoaded), {
  ssr: false,
});
