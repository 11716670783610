import * as Switch from '@radix-ui/react-switch';
import type { FC } from 'react';
import React, { useState } from 'react';

import { cn } from '@/utils';

interface IToggleProps {
  label?: string;
  name: string;
  onToggle?: (data: boolean) => void;
  defaultChecked?: boolean;
  isDisabled?: boolean;
  className?: string;
}
// TODO: CHANGE PLACEMENT: Should be migrated to the /src/frontend/src/components/Cookies/parts
export const Toggle: FC<IToggleProps> = ({
  label,
  name,
  onToggle = () => {},
  defaultChecked = false,
  isDisabled = false,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleChange = (data: boolean) => {
    setIsChecked(data);
    onToggle(data);
  };

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex cursor-pointer items-center justify-between">
        {label && (
          <label htmlFor={name} className="cursor-pointer">
            {label}
          </label>
        )}

        <Switch.Root
          disabled={isDisabled}
          defaultChecked={defaultChecked}
          checked={isChecked}
          onCheckedChange={handleChange}
          className={cn(
            'relative h-5 w-[36px] cursor-pointer rounded-full bg-gray-500 outline-none transition data-[state=checked]:bg-blue-500 data-[state=unchecked]:bg-grey-400',
            isDisabled && 'opacity-30',
          )}
          id={name}
        >
          <Switch.Thumb className="block h-[16px] w-[16px] translate-x-[3px] rounded-full bg-white transition-transform duration-300 will-change-transform data-[state=checked]:translate-x-[17px]" />
        </Switch.Root>
      </div>
    </div>
  );
};
