import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC, ReactNode } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomOverlay } from '@/components/CustomOverlay';
import { useTermsSelect } from '@/components/SearchBar/parts/hooks/useTermsSelect';
import { SelectCheckbox } from '@/components/SearchBar/parts/SelectCheckbox/SelectCheckbox';
import { Icon } from '@/components/Sprite';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { getTerm } from '@/store/storeSearch.utils';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

interface ISelectTermsProps {
  terms: IDepositTerm[];
  className?: string;
  dropdownClassname?: string;
  isScrolling?: boolean;
  onChange: (terms: IDepositTerm[]) => void;
  children?: ReactNode;
  inputClassName?: string;
  buttonClassName?: string;
}

export const TermsSelect: FC<ISelectTermsProps> = ({
  terms: _terms,
  className,
  dropdownClassname,
  isScrolling,
  onChange,
  children,
  inputClassName,
  buttonClassName,
}) => {
  const { t } = useTranslation();
  const {
    terms,
    overlayVisible,
    setOverlayVisible,
    activeTerms,
    allTermsIsActive,
    deselectAllTerms,
    toggleTerm,
    handleKeyDown,
    handleClick,
  } = useTermsSelect(onChange, _terms);

  return (
    <div className={cn('relative inline-block text-left', className)}>
      <DropdownMenuPrimitive.Root
        modal={isScrolling}
        onOpenChange={() => {
          setOverlayVisible(!overlayVisible);
        }}
      >
        {overlayVisible && <CustomOverlay />}
        <div
          className={cn(
            'flex justify-between relative grow-[1] min-w-0 gap-1 xl:gap-[10px] w-full items-center rounded-full hover:bg-gray-100 md:flex md:border-r md:border-transparent md:pr-[9px] xl:pr-2',
            overlayVisible && 'z-20 bg-white',
            inputClassName,
          )}
        >
          <DropdownMenuPrimitive.Trigger asChild>
            <button
              onClick={() => {
                analyticServices.sendEvent({
                  action: AnalyticsEvent.openTerms,
                  category: AnalyticsCategory.deposit,
                  label: 'openTerms',
                });
              }}
              type="button"
              className={cn(
                'w-[80%] xl:w-[60%] lg:w-[50%] min-w-[80%] xl:min-w-[60%] lg:min-w-[50%] focus-visible:rounded-full search-input pr-2 focus-visible:outline-gray-200',
                overlayVisible && 'bg-white z-20',
                buttonClassName,
              )}
            >
              <span className="caption whitespace-nowrap text-left">
                {t('deposits.terms')}
              </span>
              <div className="body2-bold flex w-full min-w-0 grow-[1] content-center items-center justify-start gap-2 overflow-hidden rounded-md font-bold text-black">
                <span className="truncate text-[15px]">
                  {activeTerms.map((term, index) => (
                    <span className="capitalize" key={term.key}>
                      {getTerm(term.key, t)}
                      {index < activeTerms.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                  {allTermsIsActive && t(`database.deposit type.all`)}
                </span>
                <Icon
                  className="ml-auto min-w-0 shrink-0 stroke-2 text-transparent"
                  name="arrowDownIcon"
                />
              </div>
            </button>
          </DropdownMenuPrimitive.Trigger>
          {children}
        </div>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            side="bottom"
            align="start"
            sideOffset={9}
            className={cn(
              'shadow-far z-50 rounded-[31px] bg-white pt-2 text-blue-900',
              dropdownClassname,
            )}
          >
            <div className="custom-scroll mr-[10px] h-[350px] overflow-y-auto">
              <div className="px-2">
                <DropdownMenuPrimitive.CheckboxItem asChild>
                  <SelectCheckbox
                    isChecked={allTermsIsActive}
                    onClick={(e) => handleClick(e, deselectAllTerms)}
                    onKeyDown={(e) => handleKeyDown(e, deselectAllTerms)}
                  >
                    {t(`database.deposit type.all`)}
                  </SelectCheckbox>
                </DropdownMenuPrimitive.CheckboxItem>
                {terms.map((term, index) => (
                  <Fragment key={term.key}>
                    {index === 0 && (
                      <div className="mx-3 my-2 h-[1px] self-stretch border-t border-gray-200" />
                    )}
                    <DropdownMenuPrimitive.CheckboxItem asChild>
                      <SelectCheckbox
                        isChecked={term.selected}
                        onClick={(e) => handleClick(e, () => toggleTerm(index))}
                        onKeyDown={(e) =>
                          handleKeyDown(e, () => toggleTerm(index))
                        }
                      >
                        {getTerm(term.key, t)}
                      </SelectCheckbox>
                    </DropdownMenuPrimitive.CheckboxItem>
                    {index === 3 && (
                      <div className="mx-3 my-2 h-[1px] self-stretch border-t border-gray-200" />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="px-3 pb-2">
              <DropdownMenuPrimitive.Item asChild>
                <button
                  className="flex h-[44px] w-full cursor-pointer items-center justify-center rounded-[32px] rounded-b-[31px] bg-blue-100 focus-visible:rounded-full focus-visible:outline-1 focus-visible:outline-blue-150"
                  type="button"
                >
                  <p className="body3-bold text-[15px] hover:text-blue-550">
                    {t('global.ok')}
                  </p>
                </button>
              </DropdownMenuPrimitive.Item>
            </div>
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};
