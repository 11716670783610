import Link from 'next-translate-routes/link';
import type { ReactNode } from 'react';

import { cn } from '@/utils';

interface ButtonProps {
  variant?: 'solid' | 'outline';
  color?: 'slate' | 'blue' | 'white' | 'secondary';
  className?: string;
  href?: string;
  loading?: boolean;
  children?: ReactNode;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const baseStyles: Record<string, string> = {
  solid:
    'group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
};

const variantStyles: Record<string, Record<string, string>> = {
  solid: {
    slate:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    blue: 'btn-blue-states',
    white:
      'bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white',
    secondary: 'bg-blue-100 px-6 text-center text-blue-500 hover:bg-blue-150',
  },
  outline: {
    slate:
      'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
    white:
      'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
    secondary: 'text-blue-500 p-0 ring-0 body2',
  },
};

export function Button({
  variant = 'solid',
  color = 'slate',
  className,
  href,
  children,
  loading = false,
  ...props
}: ButtonProps) {
  const cls = cn(
    baseStyles[variant],
    variantStyles[variant]![color],
    className,
  );

  return href ? (
    <Link href={href} className={cls} {...props}>
      {children}
    </Link>
  ) : (
    <button
      type="button"
      className={cn(cls, loading && 'text-transparent pointer-events-none')}
      {...props.props}
      disabled={props.props?.disabled}
    >
      {loading && (
        <span
          className={cn(
            'absolute',
            color === 'blue' ? 'loading-spin-white' : 'loading-spin',
          )}
        />
      )}
      {children}
    </button>
  );
}
