import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';

import { QuestionMarkTooltip } from '@/components/QuestionMarkTooltip';
import { cn } from '@/utils';

import { MarkIcon } from '../MarkIcon';

interface IListItemProps {
  title: string;
  value: string;
  className?: string;
  condition: boolean;
  toltipContent?: ReactNode;
  icon?: 'WarningGray' | 'Warning' | 'Advantage' | 'Disadvantage';
  tooltipStyles?: string;
}

export const ListItem: FC<IListItemProps> = ({
  icon,
  title,
  value,
  condition,
  className,
  toltipContent,
  tooltipStyles,
}) => {
  const { t } = useTranslation();

  return (
    <li
      className={cn(
        'flex content-center items-center gap-4 py-[15px] first:pt-0',
        className,
      )}
    >
      <div className="flex min-w-[384px] items-center">
        <p className="body2 font-medium leading-[20px] tracking-[0.2px]">
          {t(title)}
        </p>
        <QuestionMarkTooltip
          className={tooltipStyles}
          content={toltipContent || ''}
        />
      </div>
      {condition && (
        <div className="flex">
          {icon && <MarkIcon icon={icon} />}
          <span className="body2 w-full capitalize xl:leading-[20px]">
            {t(value)}
          </span>
        </div>
      )}
    </li>
  );
};
