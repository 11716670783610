import type { FC } from 'react';

import { cn } from '@/utils';

interface PortfolioDepositStatProps {
  title: string;
  description: string;
  descriptionClassName?: string;
}
const PortfolioDepositStat: FC<PortfolioDepositStatProps> = ({
  title,
  description,
  descriptionClassName,
}) => {
  return (
    <div className="lg:w-[128px]">
      <p className="body2-bold text-end text-blue-900">{title}</p>
      <p
        className={cn(
          'body3 text-end text-grey-600 overflow-hidden overflow-ellipsis',
          descriptionClassName,
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default PortfolioDepositStat;
