import Link from 'next-translate-routes/link';
import { useEffect } from 'react';

import { useCookiesStore } from '@/store';

// TODO: DEPRACETED: CookiesBanner can be removed
export const CookiesBanner = () => {
  // const cookies = useCookiesStore((state) => state.cookies);
  const setCookies = useCookiesStore((state) => state.setCookies);
  const dialogCookies = useCookiesStore((state) => state.dialogCookies);
  const setDialogCookies = useCookiesStore((state) => state.setDialogCookies);
  useEffect(() => {
    if (localStorage.getItem('cookies') === 'true') {
      setCookies(true);
      setDialogCookies(false);
    } else if (localStorage.getItem('cookies') === 'false') {
      setCookies(false);
      setDialogCookies(false);
    } else {
      setDialogCookies(true);
    }
  }, [dialogCookies]);

  if (!dialogCookies) {
    return null;
  }
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 z-[200] px-6 pb-6">
      <div className="pointer-events-auto mx-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 text-gray-900">
          This website uses cookies to supplement a balanced diet and provide a
          much deserved reward to the senses after consuming bland but
          nutritious meals. Accepting our cookies is optional but recommended,
          as they are delicious. See our{' '}
          <Link href="/cookie-policy" className="font-semibold text-indigo-600">
            cookie policy
          </Link>
          .
        </p>
        <div className="mt-4 flex items-center gap-x-5">
          <button
            type="button"
            className="rounded-md bg-blueColor px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blueColor/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            onClick={() => {
              localStorage.setItem('cookies', 'true');
              setDialogCookies(false);
            }}
          >
            Accept all
          </button>
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              localStorage.setItem('cookies', 'false');
              setDialogCookies(false);
            }}
          >
            Reject all
          </button>
        </div>
      </div>
    </div>
  );
};
