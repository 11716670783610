export const makeFormTextContent = (
  isLogin: string | null,
  isDefault: boolean,
): {
  title: string;
  description: string;
  button: string;
} => {
  if (isLogin === 'login') {
    return {
      title: 'header.login',
      description: 'login.login.description',
      button: 'login.login.button_text',
    };
  }
  return {
    title: isDefault ? 'signup.from_button.title' : 'login.subscribe.title',
    description: isDefault
      ? 'signup.from_button.text'
      : 'login.subscribe.description.full_text',
    button: 'login.subscribe.sign_up_button',
  };
};

export const getFormContextByType = (
  type:
    | 'register'
    | 'subscribe'
    | 'open_online'
    | 'facebook'
    | 'platform'
    | null,
) => {
  switch (type) {
    case 'register':
      return {
        title: 'auth.register.title',
        description: 'auth.register.description',
        button: 'auth.register.button',
      };
    case 'subscribe':
      return {
        title: 'auth.subscribe.title',
        description: 'auth.subscribe.description',
        button: 'auth.subscribe.button',
      };
    case 'open_online':
      return {
        title: 'auth.open_online.title',
        description: 'auth.open_online.description',
        button: 'auth.open_online.button',
      };
    case 'facebook':
      return {
        title: 'auth.facebook.title',
        description: 'auth.facebook.description',
        button: 'auth.facebook.button',
      };
    case 'platform':
      return {
        title: 'auth.platform.title',
        description: 'auth.platform.description',
        button: 'auth.platform.button',
      };
    default:
      return {
        title: 'Create an account or login',
        description: 'Login below or create a new PickTheBank account to login',
        button: 'Next',
      };
  }
};
