// eslint-disable-next-line import/no-extraneous-dependencies
import * as Dialog from '@radix-ui/react-dialog';
import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';

import { cn } from '@/utils';

interface IPopup {
  isOpen?: boolean;
  setOpen?: (newOpen: boolean) => void;
  children: ReactNode;
  footer: ReactNode;
  className: string;
}

export const Popup: FC<IPopup> = ({
  isOpen,
  setOpen,
  children,
  footer,
  className,
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.body.style.pointerEvents = '';
      }, 10);
    }
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[50] bg-gray-500/50" />
        <Dialog.Content asChild>
          <div className="fixed inset-0 z-[50] overflow-y-auto">
            <div className="grid size-full place-items-center">
              <div
                className={cn(
                  'relative z-30 w-full bg-white md:h-auto md:rounded-3xl md:my-12',
                  className,
                )}
              >
                {children}

                {footer}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
