import type { FC, ReactNode } from 'react';

export interface SmartLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  analyticsFunction?: () => void;
}
// TODO: DEPRACETED: Should be migrated to the /src/components folder
export const SmartLink: FC<SmartLinkProps> = ({
  href,
  children,
  className,
  analyticsFunction,
}) => {
  const clickHandle = () => {
    window.open(href, '_blank');
    if (analyticsFunction) {
      analyticsFunction();
    }
  };

  return (
    <button className={className} onClick={clickHandle} type="button">
      {children}
    </button>
  );
};
