import {
  UserSubscriptionChannelsEnum,
  UserSubscriptionTypesEnum,
} from '@pickthebank/api-sdk';

export interface SubscriptionObject {
  type: UserSubscriptionTypesEnum;
  channel: UserSubscriptionChannelsEnum;
  is_active: boolean;
}

export const createArrayWithActiveSubscriptions = () => {
  const subscriptionArray: SubscriptionObject[] = [];
  Object.values(UserSubscriptionTypesEnum).forEach((type) => {
    const subscriptionObject = {
      type,
      channel: UserSubscriptionChannelsEnum.Email,
      is_active: true,
    };
    subscriptionArray.push(subscriptionObject);
  });

  return subscriptionArray;
};

export const createSubscriptionsArray = () => {
  const subscriptionArray: SubscriptionObject[] = [];
  Object.values(UserSubscriptionTypesEnum).forEach((type) => {
    const subscriptionObject = {
      type,
      channel: UserSubscriptionChannelsEnum.Email,
      is_active: false,
    };
    subscriptionArray.push(subscriptionObject);
  });

  return subscriptionArray;
};
