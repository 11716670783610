import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// store for login dialog open state
interface IAuthModalState {
  type:
    | 'register'
    | 'subscribe'
    | 'open_online'
    | 'facebook'
    | 'platform'
    | null;
  isDefaultText: boolean;
  isOpenModal: boolean;
  setCloseModal: () => void;
  openLoginModal: () => void;
  openSignupModal: () => void;
  openAuthModal: (type: IAuthModalState['type']) => void;
  setIsDefaultText: (isDefaultText: boolean) => void;
  redirectUri: string | null;
  setRedirectUri: (uri: string) => void;
  clearRedirectUri: () => void;
}

export const useAuthModalsControl = create<IAuthModalState>()(
  devtools((set) => ({
    type: null,
    isOpenModal: false,
    isDefaultText: true,
    redirectUri: null,
    setIsDefaultText: (isDefaultText) => set(() => ({ isDefaultText })),
    setCloseModal: () => set(() => ({ isOpenModal: false })),
    openLoginModal: () => set(() => ({ type: 'register', isOpenModal: true })),
    openSignupModal: () => set(() => ({ type: 'register', isOpenModal: true })),
    openAuthModal: (type) => set(() => ({ type, isOpenModal: true })),
    setRedirectUri: (uri) => set(() => ({ redirectUri: uri })),
    clearRedirectUri: () => set(() => ({ redirectUri: null })),
  })),
);

interface ICookiesState {
  cookies: boolean;
  setCookies: (cookies: boolean) => void;
  dialogCookies: boolean;
  setDialogCookies: (dialogCookies: boolean) => void;
  marketingCookies: boolean;
  setMarketingCookies: (marketingCookies: boolean) => void;
}

export const useCookiesStore = create<ICookiesState>((set) => ({
  cookies: false,
  setCookies: (cookies) => set(() => ({ cookies })),

  dialogCookies: false,
  setDialogCookies: (dialog) => set(() => ({ dialogCookies: dialog })),

  marketingCookies: true,
  setMarketingCookies: (mCookies) =>
    set(() => ({ marketingCookies: mCookies })),
}));

// store user id when logged in
interface IUserIdState {
  userID: string;
  setUserID: (user: string) => void;
}

export const useUserIdStore = create<IUserIdState>((set) => ({
  userID: '',
  setUserID: (user) => set(() => ({ userID: user })),
}));

// store for favorites deposits state
interface IFavoritesState {
  favoriteDeposits: string[];
  addFavorite: (deposit: string) => void;
  deleteFavorite: (deposit: string) => void;
}

export const useFavoriteStore = create<IFavoritesState>((set) => ({
  favoriteDeposits: [],
  addFavorite: (deposit) =>
    set((state) => ({
      favoriteDeposits: [...state.favoriteDeposits, deposit],
    })),
  deleteFavorite: (deposit) =>
    set((state) => ({
      favoriteDeposits: state.favoriteDeposits.filter(
        (item) => item !== deposit,
      ),
    })),
}));
