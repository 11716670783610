import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import { useEffect, useState } from 'react';

import confirmEmailPng2x from '@/public/assets/pics/confirmEmail@2x-min.png';
import confirmEmailWebp2x from '@/public/assets/pics/confirmEmail@2x-min.webp';
import confirmEmailPng1x from '@/public/assets/pics/confirmEmail-min.png';
import confirmEmailWebp1x from '@/public/assets/pics/confirmEmail-min.webp';
import { verifyCode } from '@/services/supabase';
import { useAuthModalsControl, useAuthStore } from '@/store';

import ApiSingleton from '../../../../services/ApiSingleton';
import { VerifyInput } from './VerifyInput';

export const ConfirmMailDialog = ({
  executeCaptcha,
}: {
  executeCaptcha: () => Promise<string>;
}) => {
  const { t } = useTranslation();
  const {
    email,
    setToken,
    verificationCode,
    setVerificationCode,
    reset,
    setError,
    errors,
  } = useAuthStore((state) => state);
  const { setCloseModal, redirectUri, clearRedirectUri } = useAuthModalsControl(
    (state) => state,
  );

  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const handleSetCode = (codeVerify: string) => {
    setVerificationCode(codeVerify);
  };

  const handleSubmit = async () => {
    try {
      const token = await executeCaptcha();
      setIsLoading(true);
      const response = (await verifyCode(
        email,
        token,
        verificationCode,
      )) as any;

      localStorage.removeItem('timer');

      reset();
      await ApiSingleton.getInstance().setToken(response!.access_token as any);
      setToken(response!.access_token as any);
      setIsLoading(false);
      setCloseModal();
      if (redirectUri) {
        router.push(redirectUri);
        clearRedirectUri();
      }
    } catch (error: any) {
      setVerificationCode('');
      setIsLoading(false);
      setError([...error.response.data.message]);
    }
  };

  useEffect(() => {
    setError([]);
  }, []);

  return (
    <div className="flex flex-col content-center items-center justify-center">
      <picture className="mt-5">
        <source
          srcSet={`${confirmEmailWebp1x.src} 1x, ${confirmEmailWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${confirmEmailPng1x.src} 1x, ${confirmEmailPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={confirmEmailPng1x.src}
          alt="confirmEmail"
          width={confirmEmailPng1x.width}
          height={confirmEmailPng1x.height}
        />
      </picture>
      <div className="flex flex-col items-center gap-4">
        <h3 className="body1-bold text-center text-blue-900">
          {t('login.confirm_code.title')}
        </h3>
        <VerifyInput onComplete={handleSetCode} />
        <p className="body2 text-center text-grey-800 md:px-10">
          {t('login.confirm_code.text', { email_address: email })}
        </p>
        <button
          type="button"
          className="btn-blue-states mt-3 inline-flex w-full justify-center rounded-full px-4 py-[18px] text-base disabled:bg-gray-300 sm:mt-0 sm:text-sm"
          onClick={handleSubmit}
          disabled={
            errors.length > 0 || verificationCode.length < 6 || isLoading
          }
        >
          {t('Continue')}
        </button>
      </div>
    </div>
  );
};
