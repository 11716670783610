import type { IAnalyticEvent, IAnalyticService } from '@/types/analytics';

export class FacebookAnalyticService implements IAnalyticService {
  // eslint-disable-next-line class-methods-use-this
  public init(): void {}

  /* eslint-disable class-methods-use-this */
  public async sendEvent(event: IAnalyticEvent): Promise<void> {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.trackCustom(event.action, {
          category: event.category,
          label: event.label,
          value: event.value,
          payload: event.payload,
        });
      });
  }
  /* eslint-disable class-methods-use-this */
}
