import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { Icon } from '@/components';
import { cn } from '@/utils';

interface ModalProps {
  content: React.ReactNode;
  title?: string;
  onClose: () => void;
  titlePosition?: 'left' | 'center';
  onUserClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  content,
  onClose,
  title,
  titlePosition,
  onUserClose,
}) => {
  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        className="shadow-far relative z-[1999999999] bg-yellow-100"
        onClose={() => {
          if (onUserClose) {
            onUserClose();
          }
          onClose();
        }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-y-auto bg-[#000066]/10 px-5 pb-5"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex min-h-full items-end justify-center p-0 text-center sm:items-center md:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'shadow-far h-max sm:h-max relative flex flex-col sm:justify-evenly overflow-hidden rounded-[24px] bg-white px-4 pt-3 pb-4 sm:pt-4 text-left transition-all',
                  'xl:px-6 w-max md:px-5',
                  title ? 'xl:pb-6 xl:pt-4 xl:px-6' : 'xl:py-12',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                {title ? (
                  <div className="-mx-4 flex items-center justify-between border-b border-b-blue-150 px-4 pb-4 md:-mx-5 xl:-mx-6 xl:px-6">
                    <p
                      className={cn(
                        'body1-bold text-blue-900',
                        titlePosition === 'center' && 'w-full text-center',
                      )}
                    >
                      {title}
                    </p>
                    <button
                      type="button"
                      onClick={() => {
                        if (onUserClose) {
                          onUserClose();
                        }
                        onClose();
                      }}
                    >
                      <Icon className="size-5" name="closeIcon" />
                    </button>
                  </div>
                ) : (
                  <div className="absolute right-6 top-6 z-[51] flex cursor-pointer text-black">
                    <div
                      onClick={() => {
                        if (onUserClose) {
                          onUserClose();
                        }
                        onClose();
                      }}
                    >
                      <Icon className="size-[32px]" name="closeIcon" />
                    </div>
                  </div>
                )}

                <div className="mt-4 flex justify-center">{content}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
