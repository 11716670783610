import countries from '@/constants/countries.json';
import { LIST_COUNTRIES } from '@/utils/flags';

export const COUNTRIES_PATHS = countries as any;

export function findCountryKey(translation: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in COUNTRIES_PATHS) {
    if (Object.prototype.hasOwnProperty.call(COUNTRIES_PATHS, key)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const lang in COUNTRIES_PATHS[key]) {
        if (
          Object.prototype.hasOwnProperty.call(COUNTRIES_PATHS[key], lang) &&
          COUNTRIES_PATHS[key][lang] === translation
        ) {
          return key;
        }
      }
    }
  }
  return '';
}

export const findCountryByAlpha2 = (alpha2: string) => {
  return LIST_COUNTRIES.find((i) => i.iso2 === alpha2)?.name;
};
