import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import { type FC, useState } from 'react';

import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import { useAuthStore } from '@/store';
import { CURRENCY_SYMBOLS } from '@/store/storeSearch.consts';
import type { IBankDepositCard } from '@/types';
import { calcDepositTerms, checkIsNumber, cn, transformNumber } from '@/utils';

import { DeletePopup } from '../BankCard/parts';
import { Icon } from '../Sprite';
import { TableCell, TableRow, ViewButton } from './parts';

export interface IOtherDepositTableProps {
  className?: string;
  bankInfo: IBankDepositCard[];
  // setFavourite: (a: boolean) => void;
}

export const OtherDepositTable: FC<IOtherDepositTableProps> = ({
  className,
  bankInfo,
  // setFavourite,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isTestUser } = useAuthStore((state) => state);

  const handleViewButtonClick = (productId: string) => {
    router.push({
      pathname: `/deposits/${productId}`,
      query: isTestUser ? { test_user: isTestUser } : undefined,
    });
  };

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();

  // TODO: implement sort button click handler
  // const handleSortByInterestRate = () => {
  // };
  const [deletePopup, setDeletePopup] = useState(false);

  // TODO: for future favourite deposit from bank... //

  // const userID = useUserIdStore((state) => state.userID);

  // const handleFavourite = async (deposit_id: IBankDepositCard) => {
  //   // if (!userID) return;
  //   setFavourite(!isFavourite);
  //   await upsertFavoritesDeposits(supabase, {
  //     favorite_deposit: [+deposit_id.productIndex],
  //     user_id: userID,
  //   })
  //     .then(() => setDeletePopup(false))
  //     .catch(() => {
  //       setDeletePopup(false);
  //     });
  //   // .then(() => useDeleteFavourite(deposit_id.productIndex));
  // };

  const getDepositAmount = (bank: IBankDepositCard) => {
    const { displayAmount, prefix, isAnyAmount } = calcDepositTerms(
      bank.minimumAmount,
      bank.maximumAmount,
    );
    return isAnyAmount
      ? t(displayAmount)
      : checkIsNumber(displayAmount)
        ? `${t(prefix)} ${
            CURRENCY_SYMBOLS[bank.currency.toLowerCase()]
          }${displayAmount}`
        : `${t(prefix)} ${
            CURRENCY_SYMBOLS[bank.currency.toLowerCase()]
          }${t(displayAmount)}`;
  };

  return (
    <div className={className}>
      {/* TODO: backend not ready for sorting */}
      {/* <button
        type="button"
        onClick={handleSortByInterestRate}
        className="mb-4 mt-6 flex items-center gap-1 border-none leading-[18px]"
      >
        <span className="font-inter text-[13px] font-medium text-gray-800">
          {t('deposit_card.by_interest_rate')}
        </span>
        <Icon className="h-[10px] w-[10px]" name="arrowDownIcon" />
      </button> */}
      <table className="mx-auto mt-[7px] w-full">
        <thead className="hidden md:table-header-group">
          <tr
            className={cn(
              'border-b-[1px] border-gray-200 child:py-4 child:text-left child:font-inter child:text-[13px] child:font-medium child:leading-[18px] child:tracking-[0.1px] child:text-gray-600 md:child:px-2 lg:child:px-3',
              'child:px-3',
            )}
          >
            <th className="first:!pl-0 last:!pr-0 md:w-[103px] lg:w-[131px] xl:w-[186px]">
              <div className="flex items-center gap-1">
                {t('deposit_card.interest_rate')}
                <Icon
                  className="size-[10px] text-transparent"
                  name="arrowDown"
                />
              </div>
            </th>
            <th className="w-[197px] md:w-[77px] lg:w-[197px]">
              {t('deposit_card.term')}
            </th>
            <th className="w-[197px] md:w-[77px] lg:w-[197px]">
              {t('deposit_card.min_amount')}
            </th>
            <th className="w-[198px] md:w-[77px] lg:w-[197px]">
              {t('deposit_card.max_amount')}
            </th>
            <th
              className="w-[198px] md:w-[183px] lg:w-[198px] lg:!pr-0"
              colSpan={1}
            />
            <th className="hidden md:table-cell" />
          </tr>
        </thead>
        <tbody>
          {bankInfo.map((bank, index) => (
            <TableRow
              key={index}
              onClick={() => handleViewButtonClick(bank.productIndex)}
            >
              <TableCell className="min-w-0 shrink-0 font-inter text-[15px] font-bold leading-[20px] tracking-[0.2px] text-blue-900 md:text-2xl md:font-semibold md:leading-[28px]">
                {Number(bank.grossInterestRate).toFixed(2)}%
              </TableCell>

              <TableCell className="truncate text-[15px] font-medium leading-[20px] tracking-[0.2px] text-blue-900">
                {`${bank.termMonths} ${t('deposit_card.months')}`}
              </TableCell>

              <TableCell className="hidden text-[15px] font-medium leading-[20px] tracking-[0.2px] text-blue-900 md:table-cell">
                {Number(bank.minimumAmount) !== 0
                  ? `${CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}${transformNumber(Number(bank.minimumAmount))}`
                  : '-'}
              </TableCell>

              <TableCell className="hidden text-[15px] font-medium leading-[20px] tracking-[0.2px] text-blue-900 md:table-cell">
                {Number(bank.maximumAmount) !== 0
                  ? transformNumber(Number(bank.maximumAmount))
                  : '-'}
              </TableCell>

              <TableCell className="truncate text-[15px] font-medium leading-[20px] tracking-[0.2px] text-gray-800 md:hidden md:text-[13px] md:leading-[18px]">
                {getDepositAmount(bank)}
              </TableCell>

              <TableCell className="absolute right-0 flex h-full items-center justify-center !pr-0 text-gray-800 md:hidden">
                <Link
                  href={{
                    pathname: `/deposits/${bank.productIndex}`,
                    query: isTestUser ? { test_user: isTestUser } : undefined,
                  }}
                >
                  <Icon
                    className="size-[16px] text-transparent"
                    name="dropRightArrow"
                  />
                </Link>
              </TableCell>

              <TableCell className="col-span-3 pl-0 text-[13px] font-medium leading-[18px] tracking-[0.1px] text-gray-600 md:pl-2 md:text-gray-800 lg:pl-3">
                {bank.interestPaymentFrequency}
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <div className="flex items-center md:gap-6">
                  <ViewButton href={`/deposits/${bank.productIndex}`} />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleFavourite(bank);
                    }}
                    className="relative size-8 border-none bg-transparent text-grey-400 transition-all hover:text-grey-800"
                  >
                    {checkIsFavorite(bank) ? (
                      <Icon name="button-fav-is-active" className="size-8" />
                    ) : (
                      <Icon name="button-to-fav" className="size-8" />
                    )}
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
      {deletePopup && (
        <DeletePopup
          onCancel={() => setDeletePopup(false)}
          onDeleteFavorite={() => setDeletePopup(false)}
          className="absolute right-[34px] top-[80px]"
        />
      )}
    </div>
  );
};
