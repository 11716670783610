import { useTranslation } from 'next-i18next';

import { PICKTHEBANK_ORGANIZATION } from '@/hooks/microdata/common';
import { getBankLogoURL } from '@/services/supabase';
import type { IBankDepositCard } from '@/types';
import { transformMonths } from '@/utils/functions';

export const useGetDepositJsonLD = (deposit: IBankDepositCard) => {
  const { t } = useTranslation();
  const { value, unit } = transformMonths(+deposit.termMonths);
  const term = `${value} ${t(`deposit_card.${unit?.toLowerCase()}`)}`;
  const depositType = t(`database.product type.fixed term deposit`);
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FinancialProduct',
    annualPercentageRate: deposit.grossInterestRate,
    interestRate: deposit.grossInterestRate,
    areaServed: deposit.availableCountries,
    availableChannel: {
      '@type': 'ServiceChannel',
      serviceUrl: `${PICKTHEBANK_ORGANIZATION.url}deposits/${deposit.productIndex}`,
    },
    brand: {
      '@type': 'Brand',
      name: deposit.bankName,
      logo: getBankLogoURL(deposit.bankIndex),
    },
    broker: PICKTHEBANK_ORGANIZATION,
    category: deposit.depositType,
    logo: getBankLogoURL(deposit.bankIndex),
    provider: PICKTHEBANK_ORGANIZATION,
    serviceType: deposit.depositType,
    description: `${deposit.grossInterestRate}% ${term} ${depositType} - ${deposit.bankName}`,
    identifier: deposit.productIndex,
    url: `${PICKTHEBANK_ORGANIZATION.url}/deposits/${deposit.productIndex}`,
  };
  return jsonLd;
};
