// TODO: This component needs to be adapted for mobile devices

import type { FC } from 'react';

import { cn } from '@/utils';

interface SkeletCardProps {
  className?: string;
}
export const SkeletCard: FC<SkeletCardProps> = ({ className }) => {
  return (
    <div
      className={cn(
        'mx-[132px] flex h-[142px] flex-col gap-4 rounded-xl bg-white pb-4 pl-6 pr-[72px]',
        className,
      )}
    >
      <div className="flex">
        <div className="mt-7 size-[64px] shrink items-center rounded-full bg-blue-50" />
        <div className="mt-9 flex flex-col gap-[10px] pl-3">
          <div className="h-[20px] w-[260px] rounded-xl bg-blue-50" />
          <div className="h-[14px] w-[96px] rounded-xl bg-blue-50" />
        </div>
        <div className="mt-9 flex justify-between gap-6 pl-[48px]">
          <div className=" h-[20px] w-[142px] rounded-xl bg-blue-50" />
          <div className="h-[20px] w-[142px] rounded-xl bg-blue-50" />
          <div className="h-[20px] w-[142px] rounded-xl bg-blue-50" />
        </div>
        <div className="ml-6 mt-7 h-[56px] w-[160px] rounded-full bg-blue-50" />
      </div>
      <div className="h-[14px] w-[96px] rounded-xl bg-blue-50" />
    </div>
  );
};
