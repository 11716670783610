import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { BankCard } from '@/components/BankCard';
import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import type { IBankDepositCard } from '@/types';

interface FeaturedDepositCardsProps {
  featured: IBankDepositCard[];
  // eslint-disable-next-line react/no-unused-prop-types
  isFavorited?: (bank: IBankDepositCard) => boolean;
  onLoadMore: () => void;
  isLoading: boolean;
  shouldLoadMore?: boolean;
}

export const FeaturedDepositCards: FC<FeaturedDepositCardsProps> = ({
  featured,
  onLoadMore,
  isLoading,
  shouldLoadMore = true,
}) => {
  const { t } = useTranslation();
  // TODO: uncomment when sort will be implemented from backend, aslo change values according to backend sorting values
  // const itemsForDrop = [
  //   {
  //     label: `${t('deposit_card.by_interest_rate')}`,
  //     value: `${t('deposits.sort_by_options.value.interest_rate')}`,
  //   },
  //   {
  //     label: `${t('deposits.sort_by_options.az')}`,
  //     value: 'asc',
  //   },
  //   {
  //     label: `${t('deposits.sort_by_options.za')}`,
  //     value: 'desc',
  //   },
  //   {
  //     label: `${t('deposits.sort_by_options.long')}`,
  //     value: `${t('deposits.sort_by_options.value.longest')}`,
  //   },
  //   {
  //     label: `${t('deposits.sort_by_options.short')}`,
  //     value: `${t('deposits.sort_by_options.value.shortest')}`,
  //   },
  // ];

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();

  return (
    <>
      <div className="my-4 w-full">
        <h5 className="body1-bold pb-1 text-[18px] leading-[24px] text-blue-900">
          {t('deposits.top_deposits_text')}
        </h5>
        <div className="flex justify-between">
          <p className="body2 leading-[20px] tracking-[0.2px] text-grey-800">
            {t('deposits.top_deposits_description')}
          </p>
          {/* TODO: uncomment when sort will be implemented from backend, need improved acording to backand logic */}
          {/* <Sortdropdown items={itemsForDrop} /> */}
        </div>
      </div>
      <div className="mb-4 mt-10 flex w-full flex-col gap-4 space-y-3 md:mt-[18px]">
        {featured.map((bank) => (
          <BankCard
            key={bank.productIndex}
            bank={bank}
            isFavourite={checkIsFavorite(bank)}
            handleFavourite={() => handleFavourite(bank)}
          />
        ))}
      </div>
      {shouldLoadMore && (
        <button
          type="button"
          onClick={onLoadMore}
          disabled={isLoading}
          className="btn-blue-states mt-4 rounded-full px-8 py-2 disabled:bg-grey-300"
        >
          {isLoading ? t('global.loading') : t('global.load_more')}
        </button>
      )}
    </>
  );
};
