import { type FC, type ReactNode, useState } from 'react';

import { cn } from '@/utils';

import { Icon } from '../Sprite';

interface IAccordionProps {
  children: ReactNode;
  title: string;
  decorator?: ReactNode;
}

// TODO: CHANGE PLACEMENT: Should be migrated to the /src/components/Cookies/parts/CookiesSettings/parts
export const Accordion: FC<IAccordionProps> = ({
  title,
  decorator,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="relative border-t-[1px] border-t-blue-150 last-of-type:border-b-[1px] last-of-type:border-b-blue-150">
      <header className="flex w-full flex-col">
        <div className="flex items-center justify-between">
          <div
            className="flex cursor-pointer items-center gap-1 py-[18px]"
            onClick={() => setExpanded(!expanded)}
          >
            <p className="body2 tracking-[0.4px]">{title}</p>

            <Icon
              className={cn(
                'h-4 w-4 text-transparent transition-all',
                expanded && 'rotate-180',
              )}
              name="shewronDown"
            />
          </div>
        </div>
      </header>

      {decorator}

      {expanded && <div className="mb-[18px] overflow-hidden">{children}</div>}
    </div>
  );
};
