import type { IFooterLink, IRawFooterLink } from './footer.types';

export const mapFooterPagesData = (data: IRawFooterLink[]): IFooterLink[] => {
  return data.map((item) => {
    return {
      id: item.id,
      position: item.attributes.positionOnTheSite.position,
      title: item.attributes.title,
      link: item.attributes.link,
      positionWeight: item.attributes.positionOnTheSite.positionWeight,
    };
  });
};

export const getLinksFromData = (
  data: IFooterLink[] | [],
  position: 'start' | 'middle' | 'end',
) => {
  return data
    ? data
        .filter((item) => item.position.split('.')[1] === position)
        .sort((a, b) => a.positionWeight - b.positionWeight)
    : [];
};
