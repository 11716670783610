import type { InputHTMLAttributes } from 'react';
import React, { forwardRef, useMemo, useState } from 'react';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  placeholder: string;
  value: string;
  onChange: (newValue: string) => void;
  type?: string;
  className?: string;
  prefix?: string;
  disabled?: boolean;
  errorText?: string;
}

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      value,
      onChange,
      type = 'text',
      className,
      prefix = '',
      disabled,
      errorText,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      if (prefix && inputValue.startsWith(prefix)) {
        onChange(inputValue.slice(prefix.length));
        return;
      }
      onChange(inputValue);
    };

    const displayValue = useMemo(() => {
      return prefix ? prefix + value : value;
    }, [prefix, value]);

    const containerStyles = useMemo(() => {
      if (disabled) {
        return 'cursor-not-allowed bg-grey-200 text-grey-500';
      }
      if (errorText) {
        return 'bg-white border-red-500';
      }
      return 'bg-white text-grey-800';
    }, [disabled, errorText]);

    const inputStyles = useMemo(() => {
      if (disabled) {
        return 'cursor-not-allowed text-grey-600';
      }
      return 'cursor-text text-blue-900';
    }, [disabled]);

    return (
      <div className="w-full">
        <div
          className={`relative flex h-[56px] w-full flex-col justify-center gap-0.5 overflow-hidden rounded-xl border-[1.25px] border-grey-200 px-4 text-black placeholder:text-grey-800 focus:border-[#326BFF] focus:ring-0 ${containerStyles} ${className}`}
        >
          {(displayValue || focused) && (
            <span className="text-[11px]">{placeholder}</span>
          )}
          <input
            ref={ref}
            value={displayValue}
            type={type}
            className={`h-[20px] w-full border-transparent bg-transparent text-[15px] font-medium outline-none placeholder:text-grey-800 focus:border-transparent focus:ring-0 ${inputStyles}`}
            placeholder={!focused ? placeholder : ''}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            {...props}
          />
        </div>
        {errorText && (
          <p className="caption ml-[18px] text-red-500">{errorText}</p>
        )}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
