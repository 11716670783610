import { useRouter } from 'next-translate-routes/router';
import { useEffect } from 'react';

export const useGtagRouterEvents = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      (window as any).gtag(
        'config',
        process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || '',
        {
          page_path: url,
        },
      );
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
