import type { StaticImageData } from 'next/image';

import countries from '@/constants/countries.json';
import C_AUD from '@/public/assets/currency/aud.svg';
import C_BGN from '@/public/assets/currency/bgn.svg';
import C_CAD from '@/public/assets/currency/cad.svg';
import C_CHF from '@/public/assets/currency/chf.svg';
import C_CNY from '@/public/assets/currency/cny.svg';
import C_CZK from '@/public/assets/currency/czk.svg';
import C_DKK from '@/public/assets/currency/dkk.svg';
import C_EUR from '@/public/assets/currency/eur.svg';
import C_GBP from '@/public/assets/currency/gbp.svg';
import C_HUF from '@/public/assets/currency/huf.svg';
import C_JPY from '@/public/assets/currency/jpy.svg';
import C_NOK from '@/public/assets/currency/nok.svg';
import C_NZD from '@/public/assets/currency/nzd.svg';
import C_PLN from '@/public/assets/currency/pln.svg';
import radioCheck from '@/public/assets/currency/radioCheck.svg';
import C_RON from '@/public/assets/currency/ron.svg';
import C_SEK from '@/public/assets/currency/sek.svg';
import C_USD from '@/public/assets/currency/usd.svg';
import { getCountryFlagURL } from '@/services/supabase/storage';
import type {
  IDepositOperation,
  IDepositTerm,
} from '@/store/storeSearch.types';

export const CURRENCY_IMAGES: { [key: string]: StaticImageData } = {
  all: radioCheck,
  eur: C_EUR,
  usd: C_USD,
  gbp: C_GBP,
  bgn: C_BGN,
  cad: C_CAD,
  aud: C_AUD,
  chf: C_CHF,
  czk: C_CZK,
  huf: C_HUF,
  pln: C_PLN,
  sek: C_SEK,
  dkk: C_DKK,
  nok: C_NOK,
  jpy: C_JPY,
  nzd: C_NZD,
  ron: C_RON,
  cny: C_CNY,
};

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  eur: '€',
  usd: '$',
  gbp: '£',
  bgn: 'lev',
  cad: '$',
  aud: '$',
  chf: 'CHF',
  czk: 'Kč',
  huf: 'Ft',
  pln: 'zł',
  sek: 'kr',
  dkk: 'kr',
  nok: 'kr',
  jpy: '¥',
  nzd: '$',
  ron: 'leu',
  cny: '¥',
};

export const DEPOSIT_TERMS: IDepositTerm[] = [
  {
    key: '1 month',
    value: 1,
    selected: false,
  },
  {
    key: '3 months',
    value: 3,
    selected: false,
  },
  {
    key: '6 months',
    value: 6,
    selected: false,
  },
  {
    key: '9 months',
    value: 9,
    selected: false,
  },
  {
    key: '1 year',
    value: 12,
    selected: false,
  },
  {
    key: '1.5 years',
    value: 18,
    selected: false,
  },
  {
    key: '2 years',
    value: 24,
    selected: false,
  },
  {
    key: '3 years',
    value: 36,
    selected: false,
  },
  {
    key: '4 years',
    value: 48,
    selected: false,
  },
  {
    key: '5 years',
    value: 60,
    selected: false,
  },
  {
    key: '+6 years and more',
    value: 72,
    selected: false,
  },
];

export const TERMS_OPTIONS: { [key: string]: number } = {
  All: -1,
  '1 month': 1,
  '3 months': 3,
  '6 months': 6,
  '9 months': 9,
  '1 year': 12,
  '1.5 years': 18,
  '2 years': 24,
  '3 years': 36,
  '4 years': 48,
  '5 years': 60,
  '+6 years and more': 72,
};

export const ANNUAL_FEE_OPTIONS = [
  { key: 'all', value: undefined },
  { key: 'free', value: 'free' },
];

export const CASHBACK_OPTIONS = [
  { key: 'all', value: undefined },
  { key: 'yes', value: 'yes' },
];

export const DEPOSIT_TYPES: string[] = [
  'credit note',
  'deposit branch',
  'deposit with additional conditions',
  'investment deposit',
  'ordinary deposit',
  'subordinated deposit',
];

export const IS_PARTNER_KEY = 'available online';

export const DEPOSIT_TYPE_OPTIONS: IDepositOperation[] = DEPOSIT_TYPES.map(
  (key) => ({
    key,
    selected: false,
  }),
);

export const COUNTRY_NAMES = Object.keys(countries);

export const SEARCH_COUNTRIES = COUNTRY_NAMES.filter((country) => {
  return [
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
  ].includes(country);
});

export const COUNTRY_FLAGS: { [key: string]: StaticImageData } =
  COUNTRY_NAMES.reduce(
    (acc, country) => ({
      ...acc,
      [country]: getCountryFlagURL(country),
    }),
    {},
  );
