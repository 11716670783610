import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';

import { DepositProductCardItemV2 } from '@/components/DepositProductCard/DepositProductCardItemV2';
import { CURRENCY_SYMBOLS } from '@/store/storeSearch.consts';
import type { IBankDepositCard } from '@/types';
import { transformMonths, transformNumber } from '@/utils';

import {
  prepareMarkAdvantage,
  prepareMarkDisadvantage,
  translateinterestPaymentFrequency,
} from './DepositProduct.utils';

interface IDepositProductCardProps {
  bank: IBankDepositCard;
  className: string;
  textStyles?: string;
  excludedParams?: ['grossInterestRate', 'term'];
  fetchProductInformationSheet?: () => void;
}

export const DepositProductCardV2: FC<IDepositProductCardProps> = ({
  bank,
  className,
  textStyles,
  excludedParams,
  fetchProductInformationSheet,
}) => {
  const { t } = useTranslation();

  const { value: termValue, unit: termUnit } = transformMonths(
    +bank.termMonths,
  );

  const isShowDGS =
    bank.depositType.toLowerCase() === 'ordinary deposit' ||
    bank.depositType.toLowerCase() === 'subordinated deposit' ||
    bank.depositType.toLowerCase() === 'EMI account';

  const getDGSvalue = () => {
    switch (bank.depositType.toLowerCase()) {
      case 'ordinary deposit':
        return t('deposit_detail_card.deposit_guard');
      case 'subordinated deposit':
        return t('deposit_detail_card.deposit_guard_2');
      case 'EMI account':
        return t('deposit_detail_card.deposit_guard_3');
      default:
        return bank.depositType;
    }
  };

  const getAvailableCountries = () => {
    return (
      bank.availableCountries
        ?.map((item: any) => {
          if (typeof item === 'string') {
            return item;
          }
          return t(`database.countries.${item.name}`);
        })
        .join(', ') || ''
    );
  };

  return (
    <ul className={className}>
      {bank.grossInterestRate &&
        !excludedParams?.includes('grossInterestRate') && (
          <DepositProductCardItemV2
            value={`${bank.grossInterestRate}%`}
            heading="deposit_card.interest_rate"
            isUppercase
            textStyles={textStyles}
          />
        )}
      {bank.termMonths && !excludedParams?.includes('term') && (
        <DepositProductCardItemV2
          value={`${termValue} ${t(`deposit_card.${termUnit.toLowerCase()}`)}`}
          heading="deposit_card.term"
          textStyles={textStyles}
        />
      )}
      {bank.minimumAmount && (
        <DepositProductCardItemV2
          value={`${
            CURRENCY_SYMBOLS[bank.currency.toLowerCase()]
          }${transformNumber(bank.minimumAmount ? +bank.minimumAmount : 0)}`}
          heading="deposit_card.min_amount"
          textStyles={textStyles}
        />
      )}
      {bank.maximumAmount && (
        <DepositProductCardItemV2
          value={`${
            CURRENCY_SYMBOLS[bank.currency.toLowerCase()]
          }${transformNumber(bank.maximumAmount ? +bank.maximumAmount : 0)}`}
          heading="deposit_card.max_amount"
          textStyles={textStyles}
        />
      )}
      {bank.currency && (
        <DepositProductCardItemV2
          value={bank.currency}
          heading="deposits.currency"
          isUppercase
          textStyles={textStyles}
        />
      )}
      {/* {bank.depositType && ( */}
      {/*  <DepositProductCardItemV2 */}
      {/*    isAdvantage={bank.depositType === 'ordinary deposit'} */}
      {/*    isDisadvantage={false} */}
      {/*    value={t(`database.deposit type.${bank.depositType?.toLowerCase()}`)} */}
      {/*    heading="deposits.deposit_type" */}
      {/*    tooltipStyles="max-h-[400px] w-full max-w-[400px] overflow-scroll sm:ml-16 md:ml-12 md:max-w-[700px]" */}
      {/*    tooltip={<DepositTypesInfo />} */}
      {/*    isMarked={bank.depositType === 'ordinary deposit'} */}
      {/*    textStyles={textStyles} */}
      {/*  /> */}
      {/* )} */}
      <DepositProductCardItemV2
        isAdvantage
        isDisadvantage={false}
        value={t('global.no')}
        heading="deposit_detail_card.deposit_opening_fees"
        isMarked
        textStyles={textStyles}
      />
      {bank.additionalDepositsAvailable && (
        <DepositProductCardItemV2
          isAdvantage={prepareMarkAdvantage(
            bank.additionalDepositsAvailable,
            t('global.yes'),
          )}
          isDisadvantage={prepareMarkDisadvantage(
            bank.additionalDepositsAvailable,
            t('global.no'),
          )}
          value={bank.additionalDepositsAvailable}
          heading="deposit_detail_card.additional_deposit"
          tooltip={t('deposit_detail_card.additional_deposit_info')}
          isMarked
          textStyles={textStyles}
        />
      )}
      {bank.earlyTermination && (
        <DepositProductCardItemV2
          isAdvantage={prepareMarkAdvantage(
            bank.earlyTermination,
            t('global.yes'),
          )}
          isDisadvantage={prepareMarkDisadvantage(
            bank.earlyTermination,
            t('global.no'),
          )}
          value={bank.earlyTermination}
          heading="deposit_detail_card.early_termination"
          tooltip={t('deposit_detail_card.early_termination_info')}
          isMarked
          textStyles={textStyles}
        />
      )}
      {bank.earlyPartialWithdrawals && (
        <DepositProductCardItemV2
          isAdvantage={prepareMarkAdvantage(
            bank.earlyPartialWithdrawals,
            t('global.yes'),
          )}
          isDisadvantage={prepareMarkDisadvantage(
            bank.earlyPartialWithdrawals,
            t('global.no'),
          )}
          value={bank.earlyPartialWithdrawals}
          heading="deposit_detail_card.early_partial_withdrawals"
          tooltip={t('deposit_detail_card.early_partial_withdrawals_info')}
          isMarked
          textStyles={textStyles}
        />
      )}
      {bank.interestPaymentFrequency && (
        <DepositProductCardItemV2
          value={t(
            translateinterestPaymentFrequency(bank.interestPaymentFrequency),
          )}
          heading="deposit_detail_card.interest_payment"
          tooltip={t('deposit_detail_card.interest_payment_frequency_info')}
          textStyles={textStyles}
        />
      )}
      {bank.availableCountries && bank.availableCountries.length > 0 && (
        <DepositProductCardItemV2
          value={getAvailableCountries() || ''}
          heading="deposit_detail_card.available.title"
          textStyles={textStyles}
        />
      )}
      {isShowDGS && (
        <DepositProductCardItemV2
          isAdvantage={bank.depositType.toLowerCase() === 'ordinary deposit'}
          value={getDGSvalue()}
          heading="deposit_card.badge_deposit_guarantee_with_short"
          tooltip={t('deposit_detail_card.deposit_guard_scheme_info')}
          isMarked={bank.depositType.toLowerCase() === 'ordinary deposit'}
          textStyles={textStyles}
        />
      )}
      {fetchProductInformationSheet && (
        <DepositProductCardItemV2
          value={t('product.information-sheet')}
          heading={t('product.information-sheet')}
          textStyles={textStyles}
          onDownload={fetchProductInformationSheet}
        />
      )}
    </ul>
  );
};
