import { fileUrlToUrl } from 'next-translate-routes';
import { useRouter } from 'next-translate-routes/router';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import type {
  IFooterLink,
  IRawFooterLink,
} from '@/components/Footer/footer.types';
import { mapFooterPagesData } from '@/components/Footer/footer.utils';
import type { LocalizationsType } from '@/store/storeI18n';
import { annualFeeKeys, findAnnualFeeKey } from '@/utils/annualFees';
import { cashbackKeys, findCashbackKey } from '@/utils/cashback';
import { COUNTRIES_PATHS, findCountryKey } from '@/utils/countries';
import type { UserCountry } from '@/utils/nextjs';
import { parseTermKeysFromUrl, termKeys } from '@/utils/termKeys';

export interface SeoContent {
  title?: string;
  description?: string;
  mainText?: string;
  metaImage?: string;
  keywords?: string;
  canonical?: string;
  localizations?: LocalizationsType;
}

interface IAlternateLink {
  [key: string]: {
    href: string;
    hrefLang: string;
  };
}

interface FooterLinksContextProps {
  footerLinks: IFooterLink[];
  seoContent: SeoContent;
  preparedLocalizations: IAlternateLink;
  exactCountryCode?: string;
  country: UserCountry;
}

const CmsContentContext = createContext<FooterLinksContextProps | undefined>(
  undefined,
);

export const CmsContentProvider: React.FC<{
  children: ReactNode;
  footerLinks: IRawFooterLink[];
  seoContent: SeoContent;
  country: UserCountry;
  exactCountryCode?: string;
}> = ({ children, footerLinks, seoContent, country, exactCountryCode }) => {
  const router = useRouter();

  const getSlugLocalizations = useCallback(() => {
    const alternateLinks: IAlternateLink = {};
    const locs = Object.values(seoContent.localizations || {});
    for (const localization of locs) {
      if (localization.link) {
        alternateLinks[localization.locale] = {
          href:
            localization.locale === router.locale
              ? router.asPath
              : localization.link,
          hrefLang: localization.locale,
        };
      }
    }
    return alternateLinks;
  }, [seoContent.localizations, router]);

  const getBasicLocalizations = useCallback(() => {
    const alternateLinks: IAlternateLink = {};
    for (const locale of router.locales as string[]) {
      const query = { ...router.query };
      if (query.country) {
        const c = findCountryKey(query.country as string);
        if (c) {
          query.country = `${COUNTRIES_PATHS[c][locale]}`;
        }
      }
      if (query.term) {
        if (query.term === termKeys.all[router.locale || 'en']) {
          query.term = termKeys.all[locale];
        } else {
          const lt = parseTermKeysFromUrl(router.query.term as string);
          const termString = lt
            .map((t) => {
              return termKeys[t!][locale];
            })
            .join('+');
          query.term = termString;
        }
      }
      if (query.cashback) {
        const c = findCashbackKey(query.cashback as string);
        if (c) {
          query.cashback = `${cashbackKeys[c][locale]}`;
        }
      }
      if (query.annualFee) {
        const c = findAnnualFeeKey(query.annualFee as string);
        if (c) {
          query.annualFee = `${annualFeeKeys[c][locale]}`;
        }
      }
      const path = fileUrlToUrl(
        {
          pathname: router.pathname,
          query,
        },
        locale,
      );
      const uri = path?.split('?')[0];
      alternateLinks[locale] = {
        href: router.pathname === '/' ? '/' : uri || '/',
        hrefLang: locale,
      };
    }
    return alternateLinks;
  }, [seoContent.localizations, router]);

  const preparedLocalizations = useMemo(() => {
    if (
      router.pathname === '/blog/[slug]' ||
      router.pathname === '/[...slug]'
    ) {
      return getSlugLocalizations();
    }
    return getBasicLocalizations();
  }, [getBasicLocalizations, getSlugLocalizations]);

  const contextValue = useMemo(() => {
    return {
      footerLinks: mapFooterPagesData(footerLinks),
      seoContent,
      preparedLocalizations,
      country,
      exactCountryCode,
    };
  }, [
    footerLinks,
    seoContent,
    router,
    getSlugLocalizations,
    getBasicLocalizations,
  ]);

  return (
    <CmsContentContext.Provider value={contextValue}>
      {children}
    </CmsContentContext.Provider>
  );
};

export const useCmsContent = () => {
  const context = useContext(CmsContentContext);
  if (!context) {
    throw new Error('useFooterLinks must be used within a FooterLinksProvider');
  }
  return context;
};
