import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ISMSConfirmationStore {
  setLastTriggerDate: (date: Date | null) => void;
  setLastSentPhoneNumber: (phoneNumber: string | null) => void;
}
interface ISMSConfirmationState {
  lastTriggerDate: Date | null;
  lastSentPhoneNumber: string | null;
}

const initialState: ISMSConfirmationState = {
  lastTriggerDate: null,
  lastSentPhoneNumber: null,
};

export const SMS_TRIGGERING_INTERVAL = 60 * 1000;

export const useSMSConfirmationStore = create<
  ISMSConfirmationStore & ISMSConfirmationState
>()(
  devtools(
    (set) => ({
      ...initialState,
      setLastTriggerDate: (date: Date | null) => set({ lastTriggerDate: date }),
      setLastSentPhoneNumber: (phoneNumber: string | null) =>
        set({ lastSentPhoneNumber: phoneNumber }),
    }),
    {
      name: 'counter::storage',
    },
  ),
);
