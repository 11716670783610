import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface NumberSliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  fromText: string;
  toText: string;
  prefix: string;
  onChange: (value: number) => void;
}

export const NumberSliderInput: React.FC<NumberSliderProps> = ({
  min,
  max,
  step,
  value,
  fromText,
  toText,
  prefix,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState<number | string>(value);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    if (newValue === '' || /^[0-9]+$/.test(newValue)) {
      setLocalValue(newValue);
    }
  };

  const handleInputBlur = useCallback(() => {
    if (localValue === '') {
      setLocalValue(min);
      onChange(min);
      return;
    }
    if (localValue < min || !localValue) {
      setLocalValue(min);
      onChange(min);
      return;
    }
    if (localValue > max) {
      setLocalValue(max);
      onChange(max);
      return;
    }
    onChange(+localValue);
  }, [localValue]);

  const gradientWidth = useMemo(() => {
    const valueRange = max - min;
    const normalizedValue = (+localValue - min) / valueRange;
    return `${normalizedValue * 100}%`;
  }, [min, max, localValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className="w-full">
      <style>
        {`
          #sliderInput::-webkit-slider-runnable-track {
            height: 2px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            background: linear-gradient(to right, #326BFF 0%, #326BFF ${gradientWidth}, #E4E7F0 ${gradientWidth}, #E4E7F0 100%);
          }

          #sliderInput::-moz-range-track {
            height: 2px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            background: linear-gradient(to right, #326BFF 0%, #326BFF ${gradientWidth}, #E4E7F0 ${gradientWidth}, #E4E7F0 100%);
          }
          
          #sliderInput::-webkit-slider-thumb, #sliderInput::-webkit-range-thumb {
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 16px;
            background: var(--Blue-500, #326BFF);
            border-radius: 8px;
            padding-bottom: 12px;
            margin-top: -8px;
          }

          #sliderInput::-moz-range-thumb {
            border-radius: 8px;
            background: var(--Blue-500, #326BFF);
            box-shadow: 0px 1px 6px 0px rgba(0, 0, 102, 0.20);
            border: none;
            width: 24px;
            height: 16px; 
          }
        `}
      </style>
      <div className="relative flex h-[44px] w-full items-center justify-center gap-0.5 overflow-y-visible rounded-xl border-[1.25px] border-grey-200 bg-white px-4 text-black placeholder:text-grey-800 focus:border-[#326BFF] focus:ring-0">
        <span className="text-[15px] font-bold text-blue-900">{prefix}</span>
        <input
          id="numberInput"
          step={step}
          value={localValue}
          onInput={handleInputChange}
          onBlur={handleInputBlur}
          className="remove-arrow grow rounded-xl border-gray-300 text-[15px] font-bold text-blue-900 focus:border-blue-500 focus:outline-none"
        />
        <input
          type="range"
          id="sliderInput"
          min={min}
          max={max}
          step={step}
          value={localValue || min}
          onChange={handleSliderChange}
          className="absolute bottom-[-1px] left-0 block h-[2px] w-full appearance-none rounded-full bg-transparent px-[7px]"
        />
      </div>
      <div className="flex w-full justify-between">
        <span className="text-[11px] font-medium text-grey-600">
          {prefix && prefix}
          {fromText}
        </span>
        <span className="text-[11px] font-medium text-grey-600">
          {prefix && prefix}
          {toText}
        </span>
      </div>
    </div>
  );
};
