import type { FC } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useTermsSelect } from '@/components/SearchBar/parts/hooks';
import { SelectCheckbox } from '@/components/SearchBar/parts/SelectCheckbox/SelectCheckbox';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { getTerm } from '@/store/storeSearch.utils';
import {} from '@/utils';

interface ITermsModalInnerProps {
  terms: IDepositTerm[];
  onChange: (terms: IDepositTerm[]) => void;
}

export const TermsModalInner: FC<ITermsModalInnerProps> = ({
  terms: _terms,
  onChange,
}) => {
  const { t } = useTranslation();

  const {
    terms,
    allTermsIsActive,
    deselectAllTerms,
    toggleTerm,
    handleKeyDown,
    handleClick,
  } = useTermsSelect(onChange, _terms);

  return (
    <div className="-mx-3 flex flex-col gap-2">
      <SelectCheckbox
        isChecked={allTermsIsActive}
        onClick={(e) => handleClick(e, deselectAllTerms)}
        onKeyDown={(e) => handleKeyDown(e, deselectAllTerms)}
      >
        {t(`database.deposit type.all`)}
      </SelectCheckbox>
      <div className="mx-3 h-[1px] self-stretch border-t border-gray-200" />
      {terms.map((term, index) => (
        <Fragment key={term.key}>
          {index === 4 && (
            <div className="mx-3 my-2 h-[1px] self-stretch border-t border-gray-200" />
          )}
          <SelectCheckbox
            isChecked={term.selected}
            onClick={(e) => handleClick(e, () => toggleTerm(index))}
            onKeyDown={(e) => handleKeyDown(e, () => toggleTerm(index))}
          >
            {getTerm(term.key, t)}
          </SelectCheckbox>
        </Fragment>
      ))}
    </div>
  );
};
