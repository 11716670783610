import type { SupabaseClient } from '@supabase/supabase-js';

const TABLE_FAVORITES_DEPOSITS =
  process.env.NEXT_PUBLIC_TABLE_FAVORITES_COMPANIES || 'favorites';

export async function upsertFavoritesDeposits(
  supabase: SupabaseClient,
  deposit_card: {
    favorite_deposit: number[];
    user_id: string;
  },
) {
  const { data: existingUser, error: existingUserError } = await supabase
    .from(TABLE_FAVORITES_DEPOSITS)
    .select('favorites_deposits')
    .eq('user_id', deposit_card.user_id)
    .single();

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  if (existingUserError) throw existingUserError;

  let newFavoritesDeposits: number[];

  // If the user already exists in the database
  if (existingUser) {
    const newFavoriteDeposit = deposit_card.favorite_deposit[0];
    // Check if the deposit is already in the user's favorites
    if (existingUser.favorites_deposits.includes(newFavoriteDeposit)) {
      // If it is, filter it out
      newFavoritesDeposits = existingUser.favorites_deposits.filter(
        (deposit: any) => {
          return deposit !== newFavoriteDeposit;
        },
      );
      // eslint-disable-next-line no-console
      console.log('Favorite removed', newFavoritesDeposits);
    } else {
      // If it isn't, add it to the favorites
      newFavoritesDeposits = [
        ...existingUser.favorites_deposits,
        ...deposit_card.favorite_deposit,
      ];
    }

    // Update operation
    const { error: updateError } = await supabase
      .from(TABLE_FAVORITES_DEPOSITS)
      .update({ favorites_deposits: newFavoritesDeposits })
      .eq('user_id', deposit_card.user_id);
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (updateError) throw updateError;
    // eslint-disable-next-line no-console
    console.log('Update success', deposit_card.favorite_deposit);
  } else {
    // If the user does not exist, perform an insert operation
    const { error: insertError } = await supabase
      .from(TABLE_FAVORITES_DEPOSITS)
      .insert(deposit_card);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (insertError) throw insertError;
  }
}
