import { useTranslation } from 'next-i18next';

import letterPng2x from '@/public/assets/pics/letter@2x-min.png';
import letterWebp2x from '@/public/assets/pics/letter@2x-min.webp';
import letterPng1x from '@/public/assets/pics/letter-min.png';
import letterWebp1x from '@/public/assets/pics/letter-min.webp';
import { useAuthModalsControl } from '@/store';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices } from '@/utils';

import { MainTitle } from '../MainTitle';

export function NewsLetter() {
  const { t } = useTranslation();
  const { openAuthModal, setIsDefaultText } = useAuthModalsControl(
    (state) => state,
  );

  const handleSignUp = () => {
    openAuthModal('subscribe');
    setIsDefaultText(false);
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickSingUpForFree,
      category: AnalyticsCategory.main,
      label: 'clickSingUpForFree',
    });
  };

  return (
    <div className="w-full md:mt-1 lg:mt-0 xl:px-4">
      <div className="relative flex w-full flex-col items-center overflow-hidden bg-blue-900 px-[30px] pb-16 pt-[46px] md:gap-0 md:pb-[96px] md:pt-[46px] lg:pb-[128px] lg:pt-[78px] xl:rounded-2xl">
        <div className="px-5 pb-4 pt-[18px] md:pb-[19px]">
          <picture>
            <source
              srcSet={`${letterWebp1x.src} 1x, ${letterWebp2x.src} 2x`}
              type="image/webp"
            />
            <source
              srcSet={`${letterPng1x.src} 1x, ${letterPng2x.src} 2x`}
              type="image/png"
            />
            <img
              src={letterPng1x.src}
              alt="letter"
              className="h-32 w-32"
              width={128}
              height={128}
            />
          </picture>
        </div>
        <MainTitle
          title="login.subscribe.title"
          className="text-grey-50 md:max-w-[604px] md:text-[32px] md:tracking-[0.1px] lg:text-[40px] lg:leading-[48px] lg:tracking-[-0.64px]"
        />

        <div className="body1 mx-auto mt-4 max-w-xl text-center tracking-[0.1px] text-grey-100 md:mt-[14px]">
          ⚡️
          {`${t('login.subscribe.description.text')} ${t(
            'login.subscribe.description.bold_text',
          )}`}
        </div>
        <button
          type="submit"
          className="btn-blue-states body2-bold mt-8 rounded-full bg-blue-500 px-8 py-[21px] text-[15px] tracking-[0.2px] text-white md:w-[240px] lg:mt-12"
          onClick={handleSignUp}
        >
          {t('login.subscribe.button_text')}
        </button>
      </div>
    </div>
  );
}
