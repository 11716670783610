import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { Fragment } from 'react';

import { Button } from '@/components';
// eslint-disable-next-line import/no-cycle
import { UserAttributes } from '@/components/UserAttributes';
import { cn } from '@/utils';

interface DialogMyDataProps {
  handleClose: () => void;
}

export const DialogMyData: FC<DialogMyDataProps> = ({ handleClose }) => {
  const handleClickOutside = () => {
    handleClose();
  };
  const { t } = useTranslation();

  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        className="shadow-far relative z-[1999999999] bg-yellow-100"
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={handleClickOutside}
            className="fixed inset-0 transition-opacity"
          />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 bg-[#000066]/10"
          onClick={(e) => {
            handleClickOutside();
            e.stopPropagation();
          }}
        >
          <div className="flex h-screen justify-center overflow-hidden p-0 text-center sm:items-center md:px-4 md:py-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'shadow-far relative flex flex-col h-[100dvh] safe-area sm:h-full overflow-hidden md:rounded-[24px] bg-white text-left transition-all',
                  'w-full xl:max-w-[704px]',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <UserAttributes className="overflow-y-scroll px-4 py-6 md:px-12" />
                <div className="flex justify-between border-t border-blue-150 p-4 md:px-12">
                  <Button
                    color="secondary"
                    variant="outline"
                    props={{ onClick: handleClose }}
                    className="font-bold"
                  >
                    {t('global.back')}
                  </Button>
                  <Button
                    color="blue"
                    className="px-[2.75rem] py-[18px]"
                    props={{ onClick: handleClose }}
                  >
                    {t('global.save')}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default DialogMyData;
