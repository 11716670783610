interface IUTM {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
}

export const getUTMParams = (): IUTM => {
  const params = new URLSearchParams(window.location.search);
  const UTMParams: IUTM = {
    utm_source: params.get('utm_source') || null,
    utm_medium: params.get('utm_medium') || null,
    utm_campaign: params.get('utm_campaign') || null,
    utm_term: params.get('utm_term') || null,
    utm_content: params.get('utm_content') || null,
  };
  return UTMParams;
};

export const saveUTMParams = () => {
  if (typeof window !== 'undefined') {
    const UTMParams = JSON.parse(localStorage.getItem('UTMParams') || '{}');
    const newUTMParams = getUTMParams();

    Object.keys(newUTMParams).forEach((key) => {
      if (!UTMParams[key] && newUTMParams[key as keyof IUTM]) {
        UTMParams[key] = newUTMParams[key as keyof IUTM];
      }
    });

    localStorage.setItem('UTMParams', JSON.stringify(UTMParams));

    if (!localStorage.getItem('Referer')) {
      localStorage.setItem('Referer', document.referrer);
    }
  }
};
