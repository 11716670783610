import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { FC } from 'react';
import { Fragment, useMemo } from 'react';

import type { SelectInputOption } from '@/components';
import { Flag } from '@/components/Flag';
import { Icon } from '@/components/Sprite';
import { cn, getFlagEmoji } from '@/utils';

interface ISelectInputProps {
  className?: string;
  isScrolling?: boolean;
  placeholder: string;
  value: SelectInputOption | undefined;
  options: SelectInputOption[];
  onChange: (newValue: SelectInputOption) => void;
  disabled?: boolean;
  type?: 'text' | 'country';
}

export const SelectInputV2: FC<ISelectInputProps> = ({
  className,
  isScrolling,
  placeholder,
  value,
  options,
  onChange,
  disabled,
  type = 'text',
}) => {
  const handleCountryItemClick = (newValue: SelectInputOption) => {
    if (value?.value === newValue.value) return;
    onChange(newValue);
  };

  const containerStyles = useMemo(() => {
    if (disabled) {
      return 'cursor-not-allowed bg-grey-200 text-grey-500';
    }
    return 'bg-white text-grey-800';
  }, [disabled]);

  const textStyles = useMemo(() => {
    if (disabled) {
      return 'bg-grey-200 text-grey-500';
    }
    return 'bg-white';
  }, [disabled]);

  const isMobile = window.innerWidth <= 768; // Change the width according to your mobile requirements

  return (
    <div className={cn('relative flex-shrink-0 text-left', className)}>
      {isMobile ? (
        <div className="w-full appearance-none overflow-hidden rounded-xl  bg-white">
          {value?.value !== undefined && (
            <span className="body3 text-grey-600">{placeholder}</span>
          )}
          <div className="flex">
            <select
              value={value?.value.toString() || ''}
              disabled={disabled}
              className={cn(
                'appearance-none w-max h-full overflow-hidden text-[15px] font-medium !text-blue-900',
                containerStyles,
                value?.value.toString() ? '' : 'p-4',
              )}
              onChange={(e) => {
                let localValue: string | boolean | undefined = e.target.value;
                if (localValue === 'true') localValue = true;
                if (localValue === 'false') localValue = false;
                const selectedOption = options.find(
                  (option) => option.value === localValue,
                );
                if (selectedOption) {
                  onChange(selectedOption);
                }
              }}
            >
              <option value="" disabled>
                {value?.value || placeholder}
              </option>
              {options.map((option, index) => (
                <option
                  className="pt-14"
                  key={index}
                  value={option.value as string}
                >
                  {option.prefix && (
                    <span className="mr-2">
                      {type === 'country'
                        ? getFlagEmoji(option.prefix)
                        : option.prefix}
                    </span>
                  )}{' '}
                  {option.title}
                </option>
              ))}
            </select>
            <Icon
              className="ml-1 size-5 shrink-0 stroke-none stroke-2 text-grey-600"
              name="shewronDown"
            />
          </div>
        </div>
      ) : (
        <DropdownMenuPrimitive.Root modal={isScrolling}>
          <DropdownMenuPrimitive.Trigger disabled={disabled} asChild>
            <button
              tabIndex={0}
              type="button"
              className={cn(
                'focus-visible:outline-gray-200 flex h-[56px] flex-row justify-between items-center focus-visible:rounded-xl bg-white relative rounded-xl flex-grow-1 w-full',
                containerStyles,
              )}
              disabled={disabled}
            >
              {value?.value !== undefined ? (
                <div>
                  <p
                    className={`body3 whitespace-nowrap text-left text-grey-600 ${textStyles}`}
                  >
                    {placeholder}
                  </p>
                  <div className="mt-0.5 flex w-full justify-between">
                    <div className="body2 flex w-full min-w-0 grow-[1] content-center items-center justify-start gap-2 rounded-md text-blue-900">
                      {value.prefix && (
                        <span>
                          {type === 'country' ? (
                            <Flag countryCode={value.prefix} />
                          ) : (
                            <span>{value.prefix}</span>
                          )}
                        </span>
                      )}
                      <p className={`truncate text-[15px] ${textStyles}`}>
                        {value.title}
                      </p>
                    </div>
                    <Icon
                      className="ml-1 size-5 shrink-0 stroke-none stroke-2 text-grey-600"
                      name="shewronDown"
                    />
                  </div>
                </div>
              ) : (
                <p className="text-[15px] font-medium text-grey-800">
                  {placeholder}
                </p>
              )}
            </button>
          </DropdownMenuPrimitive.Trigger>
          <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
              side="left"
              align="center"
              className="shadow-far absolute z-20 mt-2 h-[325px] w-full rounded-[31px] bg-white pt-[33px]"
            >
              <div className="shadow-far absolute z-50 max-h-[328px] w-[230px] rounded-[31px] bg-white px-3">
                <div className="custom-scroll my-2 overflow-y-auto">
                  <div className="max-h-[310px]">
                    {options.map((option, index) => (
                      <Fragment key={index}>
                        <DropdownMenuPrimitive.Item
                          textValue={option.title.toString()}
                          asChild
                        >
                          <button
                            onClick={() => {
                              handleCountryItemClick(option);
                            }}
                            className={cn(
                              value?.value === option.value &&
                                'bg-grey-100 rounded-full',
                              'focus-visible:outline-gray-100 focus-visible:rounded-full flex w-full content-center items-center justify-start px-4 pb-3 pt-[10px] gap-2 text-[15px] font-bold',
                            )}
                            type="button"
                          >
                            {option.prefix && (
                              <span>
                                {type === 'country' ? (
                                  <Flag countryCode={option.prefix} />
                                ) : (
                                  <span>{option.prefix}</span>
                                )}
                              </span>
                            )}
                            <p className="truncate">{option.title}</p>
                          </button>
                        </DropdownMenuPrimitive.Item>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </DropdownMenuPrimitive.Content>
          </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
      )}
    </div>
  );
};

export default SelectInputV2;
