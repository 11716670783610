/* eslint-disable class-methods-use-this */

import type { IAnalyticEvent, IAnalyticService } from '@/types/analytics';

export class GoogleAnalyticService implements IAnalyticService {
  public init(): void {}

  public async sendEvent(event: IAnalyticEvent): Promise<void> {
    if (typeof window !== 'undefined') {
      (window as any).gtag('event', event.action, {
        event_category: event.category,
        event_label: event.label,
        value: event.value,
        payload: event.payload,
      });
    }
  }
}
